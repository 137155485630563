import { add } from 'date-fns'
// @ts-expect-error typedefs
import { unpack } from 'jsonh'
import { useMemo } from 'react'
import { Day } from '../../lib/dates'
import {
  projectRecurring,
  selectExpenses,
  selectWeekly,
  summaries,
} from '../../lib/transactions'
import { FIVE_MINUTES } from '../../settings'
import useFetch from '../useFetch'

export function useHistorical() {
  const { isLoading, isError, data, error } = useFetch(
    ['historical'],
    {
      url: '/transactions/historical/',
    },
    {
      staleTime: FIVE_MINUTES,
    }
  )

  const transactions = useMemo(() => {
    return data ? unpack(data) : []
  }, [data])

  const aggregated = useMemo(() => summaries(transactions), [transactions])

  const futures = useMemo(() => {
    const nextMonthDate = add(Day(), { months: 1 })
    return projectRecurring(transactions, nextMonthDate)
  }, [transactions])

  const expenses = selectExpenses(transactions)

  return {
    transactions,
    summaries: aggregated,
    expenses,
    thisWeek: {
      expenses: selectWeekly(expenses),
    },
    futures,
    isLoading: isLoading,
    isErrorHistorical: isError,
    errorHistorical: error,
  }
}
