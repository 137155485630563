import React, { useEffect, useMemo, useState } from 'react'
import { createLinkToken } from '../../lib/api'

export const TOKEN_KEY = 'link_token'

export function cleanup() {
  sessionStorage.removeItem(TOKEN_KEY)
  sessionStorage.removeItem('LOADING_TOKEN')
  sessionStorage.removeItem('LINK_ITEM_ID')
}

interface PlaidTokenProps {
  redirect_uri?: string
  item_id?: string
}

export function usePlaidClient(props: PlaidTokenProps) {
  // Check if a token exists before generating (eg. from Oauth flow)
  const prevToken = sessionStorage.getItem(TOKEN_KEY)
  const [token, setToken] = useState(prevToken)
  const [isLoading, setLoading] = useState(false)

  const isOauth = useMemo(
    () => global.location.pathname === '/plaid/oauth' || token != null,
    []
  )

  useEffect(() => {
    const loadingToken = sessionStorage.getItem('LOADING_TOKEN')
    if (!token && !loadingToken) {
      // initialize the link token for the first time
      generateLinkToken(props)
    }
    return () => sessionStorage.removeItem(TOKEN_KEY)
  }, [])

  async function generateLinkToken(params: PlaidTokenProps) {
    setLoading(true)
    sessionStorage.setItem('LOADING_TOKEN', 'true')
    try {
      const { link_token, expiration } = await createLinkToken(params)
      setToken(link_token)
      if (link_token) {
        sessionStorage.setItem(TOKEN_KEY, link_token)
        // remember if we are in update mode
        if (props.item_id) sessionStorage.setItem('LINK_ITEM_ID', props.item_id)
      } else {
        // there was some error...
        cleanup()
      }
    } catch (error) {
      console.error(error)
      cleanup()
    } finally {
      setLoading(false)
      sessionStorage.removeItem('LOADING_TOKEN')
    }
  }

  return { isLoading, token, isOauth, generateLinkToken }
}
