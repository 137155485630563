import { useIonRouter } from '@ionic/react'

import { TProfile } from '../../lib/types'
import useFetch from '../useFetch'

export function useProfile({} = {}) {
  const history = useIonRouter()
  const { isLoading, isError, data, error } = useFetch<TProfile>(['profile'], {
    url: '/user/profile/',
    params: {},
  })

  const hasPaywall = (showPaywall: boolean, url: string) => (e: any) => {
    // check if user is paid and allow redirect
    if (data?.subscription_id && data?.payment_status === 'paid') {
      history.push(url)
      return
    }

    if (showPaywall) {
      window.location.hash = '#paywall'
    } else {
      history.push(url)
    }
  }

  return {
    isLoadingProfile: isLoading,
    isProfileError: isError,
    profile: data,
    profileError: error,
    hasPaywall: hasPaywall,
  }
}
