import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/react'
import confetti from 'canvas-confetti'
import { alertCircle, checkmarkCircle } from 'ionicons/icons'
import React, { ReactNode, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { urlParams } from '../../lib/api'
import { Page } from '../Page'
import './subscription.scss'

export default function SubscriptionPage(props: RouteComponentProps<any>) {
  const params = urlParams()
  const isSuccess = !!params.success
  const isCanceled = !!params.canceled
  // const user = store.useState((s) => s.user)

  useEffect(() => {
    if (isSuccess) {
      confetti({
        particleCount: 150,
        origin: {
          x: 0.5,
          y: 0.9,
        },
        angle: 45,
      })
      confetti({
        particleCount: 150,
        origin: {
          x: 0.5,
          y: 0.9,
        },
        angle: 135,
      })
      confetti({
        particleCount: 150,
        origin: {
          x: 0.5,
          y: 0.9,
        },
        angle: 90,
      })
    }
  }, [isSuccess])

  return (
    <Page title='Subscription'>
      {isSuccess && (
        <StatusCard icon={checkmarkCircle} color='success' title='Success'>
          <p>
            🎉 Your account has been upgraded to PRO membership.
            <br />
            Enjoy using the app!
          </p>
          <IonButton href='/goals'>Continue</IonButton>
        </StatusCard>
      )}
      {isCanceled && (
        <StatusCard icon={alertCircle} color='danger' title='Error'>
          <p>
            Your subscription was not completed.
            <br />
            Please try again.
          </p>
          <IonButton href='#paywall'>Subscribe</IonButton>
        </StatusCard>
      )}
    </Page>
  )
}

export const StatusCard = ({
  icon,
  color,
  title,
  children,
}: {
  icon: string
  color: string
  title?: string
  children: ReactNode
}) => {
  return (
    <IonCard className='card_status'>
      <IonIcon icon={icon} color={color} />
      {title && (
        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>
        </IonCardHeader>
      )}
      <IonCardContent>{children}</IonCardContent>
    </IonCard>
  )
}
