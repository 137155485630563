import { useIonRouter } from '@ionic/react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { RouteComponentProps } from 'react-router'
import {
  client,
  deleteAccount,
  leaveSharedAccount,
  stopSharingAccount,
} from '../../lib/api'
import { TAccount } from '../../lib/types'

export function useManualAccount() {
  const queryClient = useQueryClient()

  const addAccount = useMutation({
    mutationFn: (data: {}) => client.post(`/accounts/`, { data }),
    onSuccess: (data) => queryClient.invalidateQueries(['accounts']),
  })

  return { addAccount }
}

export function useAccountMutations(
  account: Partial<TAccount> | undefined,
  history?: RouteComponentProps<any>['history']
) {
  const queryClient = useQueryClient()
  const router = useIonRouter()

  const updateAccount = useMutation({
    mutationFn: (data: {}) =>
      client.patch(`/accounts/${account?.account_id}`, { data }),
    onSuccess: (data) => queryClient.invalidateQueries(['accounts']),
  })

  const updateSharedAccount = useMutation({
    mutationFn: (data: {}) =>
      client.patch(`/accounts/share/`, {
        account_id: account?.account_id,
        data,
      }),
    onSuccess: (data) => queryClient.invalidateQueries(['accounts']),
  })

  const joinAccount = useMutation({
    mutationFn: (body: any) => client.post('/accounts/share/', body),
    onSuccess: (data) => {
      // queryClient.setQueryData(['accounts', params], data)
      return queryClient.invalidateQueries(['accounts'])
    },
  })

  const delAccount = useMutation(deleteAccount, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['accounts']),
        queryClient.invalidateQueries(['goals']),
        queryClient.invalidateQueries(['transactions']),
      ])
      history?.replace('/accounts')
    },
  })

  const leaveAccount = useMutation(leaveSharedAccount, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['accounts']),
        queryClient.invalidateQueries(['goals']),
      ])
      history?.replace('/accounts')
    },
  })

  const stopSharing = useMutation(stopSharingAccount, {
    onSuccess: () => {
      return queryClient.invalidateQueries(['accounts', account])
    },
  })

  return {
    delAccount,
    joinAccount,
    leaveAccount,
    stopSharing,
    updateAccount,
    updateSharedAccount,
  }
}
