import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonText,
  IonButton,
  IonFooter,
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'
import React, { ReactNode, useState } from 'react'
import { store } from '../lib/store'
import { TProfile } from '../lib/types'
import { UserAvatar } from './avatars/UserAvatar'
import IconButton from './IconButton'

export function SharedUsersModal({
  users,
  title,
  footer,
  disabled,
  detail,
  onDismiss,
  onStopSharing,
  onRemove,
}: {
  users?: { user: TProfile }[]
  title: ReactNode
  footer?: ReactNode
  disabled?: boolean
  detail?: boolean
  onDismiss?: VoidFunction
  onStopSharing?: VoidFunction
  onRemove: (user: TProfile) => any
}) {
  const rootElement = store.useState((s) => s.presentingElement)
  const [showModal, setShowModal] = useState(false)
  const owner = store.useState((s) => s.user)
  if (!owner) return null

  return (
    <>
      <IonItem
        onClick={() => setShowModal(true)}
        disabled={disabled}
        detail={detail}
      >
        <IonLabel>Manage shared users</IonLabel>
      </IonItem>
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        canDismiss
        // presentingElement={rootElement}
      >
        <IonHeader>
          <IonToolbar>
            {/* <IonTitle>{title}</IonTitle> */}
            <IonButtons slot='start'>
              <IconButton
                color='primary'
                icon={chevronBackOutline}
                onClick={() => {
                  setShowModal(false)
                  onDismiss?.()
                }}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines='none'>
            <IonListHeader className='ion-margin-vertical'>
              <IonLabel>{title}</IonLabel>
            </IonListHeader>

            <IonItem>
              <UserAvatar
                photoURL={owner.photoURL}
                displayName={owner.displayName}
              />
              <IonLabel>{owner.displayName}</IonLabel>
              <IonLabel slot='end'>
                <IonText color='medium'>Owner</IonText>
              </IonLabel>
            </IonItem>

            {(users ?? []).map(({ user }) => {
              return (
                <IonItem key={user.user_id}>
                  <UserAvatar
                    photoURL={user.photo_url}
                    displayName={user.display_name}
                  />
                  <IonLabel>{user.display_name}</IonLabel>
                  <IonButton slot='end' onClick={() => onRemove(user)}>
                    Remove
                  </IonButton>
                </IonItem>
              )
            })}
          </IonList>
        </IonContent>
        <IonFooter>
          {onStopSharing && (
            <IonButton
              className='ion-margin'
              color='danger'
              expand='block'
              onClick={() => onStopSharing()}
            >
              Stop Sharing
            </IonButton>
          )}
        </IonFooter>
      </IonModal>
    </>
  )
}
