import {
  IonButton,
  IonButtons,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar,
} from '@ionic/react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { startOfMonth, sub } from 'date-fns'
import { map, max, orderBy } from 'lodash'
import { abs } from 'mathjs'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { NumberInput } from '../../components/AmountInput'
import { budgetColor } from '../../components/BudgetsList'
import { Card } from '../../components/Card'
import { GoalSummary } from '../../components/GoalsList'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { cash, Currency, percent } from '../../components/Numeral'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useGoalMutation } from '../../hooks/mutations/useGoalMutation'
import { useSummaries } from '../../hooks/loaders/useSummaries'
import { urlParams } from '../../lib/api'
import { Day } from '../../lib/dates'
import { groupByCategory, sumAmounts } from '../../lib/transactions'
import { TransactionGroup } from '../cashflow/CashflowPage'
import { GoalOptions } from '../goals/NewGoalPage'
import { Page } from '../Page'
import PieChart from '../transactions/PieChart'
import {
  alertCircleOutline,
  checkmarkCircle,
  checkmarkCircleOutline,
} from 'ionicons/icons'

export default function BudgetPage({
  history,
  match: { params },
}: RouteComponentProps<any>) {
  // Load budget data
  const { goals } = useGoals({
    id: params.id as string,
  })
  const { mutateGoal } = useGoalMutation()

  const budget = useMemo(() => goals[0], [goals])
  const [values, setValues] = useState<any>({ ...urlParams(), ...budget })
  const setValue = (data: any) => setValues({ ...budget, ...values, ...data })
  const [disabled, setDisabled] = useState(false)

  // Update state when the budget data is fetched
  useEffect(() => {
    if (budget) setValues({ ...budget, ...values })
  }, [goals])

  const { currentMonth, isFetchingSummaries, thisWeek } = useSummaries({
    startDate: startOfMonth(sub(Day(), { months: 3 })),
  })
  console.log('monthly', currentMonth)

  const totalExpenses = currentMonth?.totals.expenses ?? values.current

  const weeklyCategories = useMemo(() => {
    const total = values.discretionary * 4
    const groups = groupByCategory(thisWeek.expenses)
    let datas = map(groups, (list, key) => {
      // const last = list[list.length - 1]
      // let lastDate = toLocaleDate(last.date)
      const value = sumAmounts(list)
      return {
        title: key,
        subtitle: `${percent(value / total)} of total`,
        list: orderBy(list, 'date', 'desc'),
        value,
      }
    })
    return orderBy(datas, 'value', 'desc')
  }, [thisWeek.expenses, values])

  const billsEstimate = max([
    (values.start_amount ?? currentMonth?.totals.avg_income ?? 0) / 2,
    currentMonth?.totals.recurring,
  ])

  const discretionaryEstimate =
    ((values.amount ?? currentMonth?.totals.avg_income ?? 0) * 0.3) / 4

  const remaining = useMemo(() => {
    let start_amount = parseFloat(values.start_amount) || 0
    let amount = parseFloat(values.amount) || 0
    let discretionary = (parseFloat(values.discretionary) || 0) * 4
    let monthly = amount + discretionary
    let leftover = start_amount - monthly
    if (totalExpenses > monthly) {
      leftover -= totalExpenses - monthly
    }
    return leftover
  }, [values, totalExpenses])

  // Save budget data
  const handleSubmit = async () => {
    let success
    try {
      setDisabled(true)
      await mutateGoal.mutateAsync(values)
      success = true
    } finally {
      setDisabled(false)
      if (success) history.goBack()
    }
  }

  const monthlySpendingChart = useMemo(() => {
    if (!currentMonth) return []
    const total = sumAmounts(currentMonth.expenses)
    const byCategory = groupByCategory(currentMonth.expenses)
    const categoryTotals = map(byCategory, (list, key) => {
      const value = abs(sumAmounts(list))
      return {
        value,
        title: key,
        subtitle: `${percent(value / total)} of total`,
        list,
      }
    })
    return categoryTotals
  }, [currentMonth])

  return (
    <Page
      end={
        <IonButtons slot='end'>
          <GoalOptions goal={budget} />
        </IonButtons>
      }
      footer={
        <IonFooter>
          <IonToolbar>
            <IonButton
              disabled={disabled || !values.amount}
              expand='block'
              onClick={handleSubmit}
            >
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <IonItem className='ion-padding-top' lines='none'>
        <IonInput
          required
          type='text'
          value={values.name}
          placeholder='My Budget'
          inputMode='text'
          maxlength={180}
          minlength={1}
          size={20}
          onIonChange={(e) => setValue({ name: e.detail.value })}
          style={{ fontSize: 24, fontWeight: 900 }}
        />
      </IonItem>
      <GoalSummary
        current={totalExpenses}
        goal={values}
        color={budgetColor(totalExpenses, values)}
      />

      <IonList className='sparse ion-margin-vertical' lines='full'>
        <IonItem>
          <LabelWithInfo
            label='Monthly income'
            header='Monthly income'
            message={`We estimated your regular monthly income to be ${cash(
              currentMonth?.totals.avg_income
            )} per month.`}
          />
          <NumberInput
            slot='end'
            value={values.start_amount}
            placeholder={cash(currentMonth?.totals.avg_income)}
            onChange={(start_amount) => {
              setValue({ start_amount })
            }}
            rightAlign
          />
        </IonItem>

        <IonItem>
          <LabelWithInfo
            label='Monthly bills'
            header='Monthly bills'
            message={`We estimated your recurring monthly bills to be ${cash(
              currentMonth?.totals.recurring
            )} per month based on the past 6 months.`}
          />
          <NumberInput
            slot='end'
            value={values.amount}
            placeholder={cash(billsEstimate)}
            onChange={(amount) => setValue({ amount })}
            rightAlign
          />
        </IonItem>

        <IonItem>
          <LabelWithInfo
            label='Weekly allowance'
            header='Weekly allowance'
            message={`This is your flexible spending like eating out, shopping, or paying for other goods & services. You should allocate about 7% of your income for this (per week).`}
          />
          <NumberInput
            slot='end'
            value={values.discretionary}
            placeholder={`$${cash(discretionaryEstimate)}`}
            onChange={(discretionary) => setValue({ discretionary })}
            rightAlign
          />
        </IonItem>

        <IonItem lines='none'>
          <LabelWithInfo
            label='Est savings'
            header='Est savings'
            message={
              remaining > values.start_amount * 0.2
                ? `Great! You're saving ${percent(
                    remaining / values.start_amount
                  )} of your income!`
                : `Aim to save at least 20% of your income every month.`
            }
            icon={
              remaining < values.start_amount * 0.2
                ? alertCircleOutline
                : checkmarkCircleOutline
            }
            iconColor={
              remaining > values.start_amount * 0.2 ? 'success' : 'danger'
            }
          />
          <IonLabel slot='end'>
            <Currency round value={remaining} />
          </IonLabel>
        </IonItem>
      </IonList>

      <TransactionGroup
        groups={weeklyCategories}
        isLoading={isFetchingSummaries}
        header='Recent activity'
        sort='date'
        desc
      />

      {monthlySpendingChart.length > 0 && (
        <Card
          className='card_sm no-padding ion-margin'
          color='tertiary'
          style={{
            margin: `28px 20px`,
          }}
        >
          <div>
            <ParentSize>
              {({ width, height }) => (
                <PieChart
                  key='expenses/weekly'
                  data={monthlySpendingChart}
                  height={300}
                  width={width}
                  title='This Month'
                />
              )}
            </ParentSize>
          </div>
        </Card>
      )}
    </Page>
  )
}
