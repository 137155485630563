import { Group } from '@visx/group'
import { scaleOrdinal } from '@visx/scale'
import Pie, { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie'
import { Text } from '@visx/text'
import { map } from 'lodash'
import numeral from 'numeral'
import React, { useState } from 'react'
import { animated, interpolate, useTransition } from 'react-spring'
// import { ChartBackground } from '../cashflow/BalancesChart'

const defaultMargin = { top: 50, right: 20, bottom: 20, left: 20 }

type DataPoint = {
  title: string
  value: number
  list: any[]
}

export type PieProps = {
  width: number
  height: number
  margin?: typeof defaultMargin
  animate?: boolean
  data: DataPoint[]
  defaultValue?: string
  title?: string
  donutRadius?: number
  onChartClick?: (d: DataPoint | null) => any
}

export default function PieChart({
  width,
  height,
  data,
  defaultValue,
  margin = defaultMargin,
  animate = true,
  title,
  donutRadius = 60,
  onChartClick,
}: PieProps) {
  const [selectedSlice, toggleSelectedSlice] = useState<
    string | null | undefined
  >(defaultValue)

  if (width < 10) return null

  // accessor functions
  const getValue = (d: DataPoint) => d.value
  const titles = map(data, 'title')

  // color scales
  const getBrowserColor = scaleOrdinal({
    domain: titles,
    range: [
      'rgba(0,0,0,0.7)',
      'rgba(0,0,0,0.6)',
      'rgba(0,0,0,0.5)',
      'rgba(0,0,0,0.4)',
      'rgba(0,0,0,0.3)',
      'rgba(0,0,0,0.2)',
      'rgba(0,0,0,0.1)',
    ],
  })

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2.2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2

  let nextData = selectedSlice
    ? data.filter(({ title }) => title === selectedSlice)
    : data

  let details = nextData.find((x) => x.title === selectedSlice)

  return (
    <svg width={width} height={height}>
      {/* <ChartBackground width={width} height={height} /> */}
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={nextData}
          pieValue={getValue}
          outerRadius={radius}
          innerRadius={donutRadius}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie<DataPoint>
              {...pie}
              animate={animate}
              getKey={(arc) => arc.data.title}
              onClickDatum={({ data }) => {
                let selected =
                  selectedSlice && selectedSlice === data.title
                    ? null
                    : data.title
                // if (onChartClick) onChartClick(selected ? data : null)
                if (animate) {
                  toggleSelectedSlice(selected)
                }
              }}
              getColor={(d) => getBrowserColor(d.data.title)}
            />
          )}
        </Pie>
        {details && (
          <text
            x={0}
            y={8}
            width={width}
            textAnchor='middle'
            fill='white'
            style={{ fontWeight: 500, fontSize: '1.2rem' }}
          >
            {numeral(details.value).format('$0,0.00')}
          </text>
        )}
        {/* <Pie
          data={
            selectedSlice
              ? data.filter(({ title }) => title === selectedSlice)
              : data
          }
          pieValue={getValue}
          pieSortValues={() => -1}
          outerRadius={radius - donutThickness * 1.2}
        >
          {(pie) => (
            <AnimatedPie
              {...pie}
              animate={animate}
              getKey={({ data: { title } }) => title}
              onClickDatum={({ data: { title } }) =>
                animate &&
                toggleSelectedSlice(
                  selectedSlice && selectedSlice === title ? null : title
                )
              }
              getColor={({ data: { title } }) => getLetterFrequencyColor(title)}
            />
          )}
        </Pie> */}
      </Group>
      <Text
        fontSize={16}
        fill='white'
        textAnchor='middle'
        verticalAnchor='end'
        width={width}
        x={width / 2}
        y={20 + 16}
      >
        {title}
      </Text>
    </svg>
  )
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number }

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
})
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
})

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean
  getKey: (d: PieArcDatum<Datum>) => string
  getColor: (d: PieArcDatum<Datum>) => string
  onClickDatum: (d: PieArcDatum<Datum>) => void
  delay?: number
}

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  })
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc)
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.3
    const words = getKey(arc).split(/\s+/)
    const label = words.slice(0, 2).join(' ')

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          // onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill='white'
              x={centroidX}
              y={centroidY}
              dy='.33em'
              fontSize={12}
              textAnchor='middle'
              pointerEvents='none'
            >
              {label}
            </text>
          </animated.g>
        )}
      </g>
    )
  })
}
