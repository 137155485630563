import { TCustomer } from '../../lib/types'
import useFetch from '../useFetch'

export function useCustomer<Value = TCustomer>({
  account_id,
}: { account_id?: string } = {}) {
  const queryKey = ['customer', account_id]
  const { isLoading, isError, data, error } = useFetch<Value>(queryKey, {
    url: `/accounts/${account_id}/customer`,
  })
  return {
    isLoadingCustomer: isLoading,
    isCustomerError: isError,
    customer: data,
    customerError: error,
  }
}
