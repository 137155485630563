import { groupBy, forEach } from 'lodash'
import { abs, sum } from 'mathjs'
import { useMemo } from 'react'
import { UseQueryOptions } from '@tanstack/react-query'
import { _amount } from '../../lib/transactions'
import { TTransaction } from '../../lib/types'
import useFetch from '../useFetch'

export interface GoalTransactionsRequest {
  goal_ids?: string[]
  id?: String
}

export function useGoalTransactions(
  { goal_ids, id }: GoalTransactionsRequest,
  opts?: UseQueryOptions
) {
  const { data } = useFetch<TTransaction[]>(
    goal_ids ?? ['transactions', 'goals', id],
    {
      url: '/transactions/goals/',
      params: {},
      method: 'post',
      data: { goal_ids },
    },
    opts
  )
  // Map<goal_id, { totals }>
  const summaries = useMemo(() => {
    let goals = {} as any
    let byGoal = groupBy(data, 'goal_id')
    forEach(byGoal, (list, key) => {
      goals[key] = { totals: sum(abs(_amount(list))) }
    })
    return goals
  }, [data])

  return { data, summaries }
}
