import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  client,
  deleteGoalRequest,
  joinSharedGoal,
  leaveSharedGoal,
  updateGoalRequest,
} from '../../lib/api'
import { TSharedGoalBody, TGoal } from '../../lib/types'

export interface GoalMutation {
  id?: string
}

export function useGoalMutation({ id }: GoalMutation = {}) {
  const queryClient = useQueryClient()

  const mutateGoal = useMutation(updateGoalRequest, {
    onSuccess: (data) => {
      queryClient.setQueryData(['goals', id], data)
    },
    onSettled: () => {
      // Invalidate previous value
      queryClient.invalidateQueries(['goals'])
    },
  })

  const deleteGoal = useMutation(deleteGoalRequest, {
    onSuccess: () => {
      // Invalidate previous value
      queryClient.invalidateQueries(['goals'])
    },
  })

  const joinGoal = useMutation(joinSharedGoal, {
    onSuccess: (data) => {
      return queryClient.invalidateQueries(['goals'])
    },
  })

  const leaveGoal = useMutation(leaveSharedGoal, {
    onSuccess: (data) => {
      return queryClient.invalidateQueries(['goals'])
    },
  })

  const mutateSharedGoal = useMutation(
    (data: TSharedGoalBody) =>
      client.patch('/goals/shared/' + data.goal_id, data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['goals', id], data)
      },
      onSettled: () => {
        // Invalidate previous value
        queryClient.invalidateQueries(['goals'])
      },
    }
  )

  return {
    joinGoal,
    leaveGoal,
    mutateGoal,
    deleteGoal,
    mutateSharedGoal,
  }
}
