import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { chevronBackOutline, people, shareOutline } from 'ionicons/icons'
import { useState } from 'react'
import IconButton from '../../components/IconButton'
import { QRCodeImg } from '../../components/QRCode'
import { useProfile } from '../../hooks/loaders/useProfile'
import { store } from '../../lib/store'
import { TProfile } from '../../lib/types'
import { copyToClipboard, shareDialog } from '../../lib/utils'
import { URL_HOST } from '../../settings'

function ReferralModal({
  isOpen,
  onClose,
  profile,
}: {
  isOpen?: boolean
  onClose: VoidFunction
  profile: TProfile
  rootElement?: React.Ref<any>
}) {
  const rootElement = store.useState((s) => s.presentingElement)
  const userLinkUrl = URL_HOST + `/referrer/${profile?.id}`
  const [showToast] = useIonToast()

  const copyLink = async () => {
    await copyToClipboard(userLinkUrl)
    showToast('Link copied to clipboard', 3000)
  }

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onClose}
        canDismiss
        // presentingElement={rootElement}
      >
        <IonToolbar>
          <IonButtons slot='start'>
            <IconButton
              color='primary'
              icon={chevronBackOutline}
              onClick={onClose}
            />
          </IonButtons>
        </IonToolbar>
        <IonContent fullscreen>
          <IonList lines='none' className='flex column center align-center'>
            <IonItem>
              <h1 className='text-black'>Tell your friends</h1>
            </IonItem>

            <IonItem>
              <p className='ion-margin text-lg center'>
                Receive $25 when you invite a friend and they start saving
                towards a shared goal.
              </p>
            </IonItem>

            <IonItem>
              <p className='ion-margin center'>
                <a href='https://clearmoney.io/referrals-terms'>
                  Read the terms.
                </a>
              </p>
            </IonItem>
          </IonList>

          <IonList lines='none' className='ion-margin-top'>
            <IonItem onClick={copyLink}>
              <div className='w100 flex column align-center'>
                <p>Scan to Share</p>
                <QRCodeImg url={userLinkUrl} />
              </div>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <div className='flex column center ion-padding'>
            <IonButton
              expand='block'
              onClick={(e) => {
                shareDialog(
                  {
                    title:
                      profile.user_name ||
                      profile.display_name ||
                      profile.user_id,
                    text: `See how ClearMoney can help you grow your savings. Create a free account today.`,
                    url: userLinkUrl,
                  },
                  copyLink
                )
              }}
            >
              <IonIcon slot='start' icon={shareOutline} />
              Share
            </IonButton>
            <IonButton
              className='ion-margin-bottom'
              fill='clear'
              onClick={onClose}
            >
              Close
            </IonButton>
          </div>
        </IonFooter>
      </IonModal>
    </>
  )
}

export function ReferralsBanner({}) {
  const [isOpen, setOpen] = useState(false)
  const { profile } = useProfile()
  return (
    <IonList lines='none'>
      <IonItem
        lines='none'
        className='tip-item'
        onClick={() => setOpen(true)}
        detail
      >
        <IonIcon icon={people} color='medium' slot='start' />
        <div style={{ maxWidth: '95%' }}>
          <IonLabel style={{ marginBottom: 5, fontWeight: 500 }}>
            Invite Friends, Get $25
          </IonLabel>
          <IonLabel color='medium' className='ion-text-wrap'>
            Receive $25 when your friends join a shared goal.
          </IonLabel>
        </div>
      </IonItem>

      <ReferralModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        profile={profile!}
      />
    </IonList>
  )
}
