import { AxiosRequestConfig } from 'axios'
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { client, updateTransaction } from '../lib/api'
import { TTransaction } from '../lib/types'

export default function useFetch<T>(
  keys: QueryKey,
  options: AxiosRequestConfig<any> = {},
  cache: UseQueryOptions<any> = {}
) {
  return useQuery<unknown, unknown, T>(keys, () => client(options), {
    networkMode: 'offlineFirst',
    ...cache,
  })
}

export const useMutateTransaction = ({
  onUpdate,
}: {
  onUpdate?: VoidFunction
} = {}) => {
  const queryClient = useQueryClient()
  const patchTransaction = useMutation(updateTransaction, {
    // Notice the second argument is the variables object that the `mutate` function receives
    onSuccess: (data) => {
      if (onUpdate) onUpdate()
      return Promise.all([
        queryClient.invalidateQueries(['transactions']),
        queryClient.invalidateQueries(['historical']),
        queryClient.invalidateQueries(['summaries']),
        queryClient.invalidateQueries(['goals']),
      ])
    },
  })
  const deleteTransaction = useMutation(
    (d: Partial<TTransaction>) => client.delete(`/transactions/${d.id}`),
    {
      // Notice the second argument is the variables object that the `mutate` function receives
      onSuccess: (data) => {
        if (onUpdate) onUpdate()
        return Promise.all([
          queryClient.invalidateQueries(['transactions']),
          queryClient.invalidateQueries(['historical']),
          queryClient.invalidateQueries(['summaries']),
          queryClient.invalidateQueries(['goals']),
        ])
      },
    }
  )

  return { patchTransaction, deleteTransaction }
}
