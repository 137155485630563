import React from 'react'

import { AxisBottom, AxisRight } from '@visx/axis'
import { LinearGradient } from '@visx/gradient'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { BarGroup, BarStack } from '@visx/shape'
import { LineSubject } from '@visx/annotation'
import { GridRows } from '@visx/grid'
import { localPoint } from '@visx/event'
// import { Text } from '@visx/text'
// import { animated, useSpring } from 'react-spring'
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip'
import { format } from 'date-fns'
import numeral from 'numeral'

import { cssVal, isDarkMode } from '../../settings'
import { SeriesPoint } from '@visx/shape/lib/types'
import { max } from 'mathjs'
import { flatMap, map } from 'lodash'
import { animated, useSpring } from 'react-spring'
import { min } from 'd3-array'

enum LightColors {
  BLUE = 'rgb(10,132,255)',
  ORANGE = 'rgb(255,149,0)',
  GRID = 'rgb(146, 148, 156)',
}

enum DarkColors {
  BLUE = 'rgb(10,132,255)',
  ORANGE = '#ffcc00',
  GRID = 'rgba(142,142,147,0.25)',
  RED = 'rgb(255,69,58)',
  PINK = 'rgb(255,55,95)',
  MINT = 'rgb(102,212,207)',
  PURPL = 'rgb(191,90,242)',
}

const defaultMargin = { top: 20, right: 30, bottom: 24, left: 15 }

export type BarGroupProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
  events?: boolean
  data: any[]
  title?: string
  avgExpenses?: number
  onClickBar?: (i: number) => any
}

let tooltipTimeout: number

export default function BalancesChart({
  width,
  height,
  events = true,
  margin = defaultMargin,
  data,
  avgExpenses,
  onClickBar,
}: BarGroupProps) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<{
    bar: SeriesPoint<any>
    key: string
    index: number
    x: number
    y: number
    width: number
    height: number
    color: string
  }>()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  })

  const keys = Object.keys(data[0]).filter((d) => d !== 'date')

  const formatDate = (date: string) => format(new Date(date), 'MMM')

  // accessors
  const _date = (d: any) => d.date

  // scales
  const xScale = scaleBand<string>({
    domain: data.map(_date),
    padding: 0.6,
    paddingOuter: 0,
  })
  const categoryScale = scaleBand<string>({
    domain: keys,
  })
  const valuesScale = scaleLinear<number>({
    domain: [
      0,
      max(
        map(data, (bar) => keys.reduce((total, key) => total + bar[key], 0))
      ) * 1.2,
    ],
  })

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [
      `url('#income')`,
      `url('#expenses')`,
      `url('#red')`,
      DarkColors.GRID,
    ],
  })

  // bounds
  const xMax = width - margin.left - margin.right - 20
  const yMax = height - margin.top - margin.bottom

  // update scale output dimensions
  xScale.rangeRound([0, xMax])
  categoryScale.rangeRound([0, xScale.bandwidth()])
  valuesScale.range([yMax, 0])

  const xMid = Math.ceil(width / 2)
  const textColor = isDarkMode() ? '#989aa2' : 'rgb(146, 148,156)'

  return (
    <div>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <LinearGradient
            id='income'
            from={DarkColors.PURPL}
            to={DarkColors.BLUE}
          />
          <LinearGradient
            id='expenses'
            from={DarkColors.ORANGE}
            to={DarkColors.PURPL}
          />
          <LinearGradient
            id='red'
            from={DarkColors.ORANGE}
            to={DarkColors.PINK}
          />
          <GridRows
            scale={valuesScale}
            width={xMax}
            height={yMax}
            stroke={isDarkMode() ? DarkColors.GRID : LightColors.GRID}
            numTicks={4}
            left={0}
          />
          <BarStack
            data={data}
            keys={keys}
            x={_date}
            xScale={xScale}
            yScale={valuesScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((data) =>
                data.bars.map((item) => {
                  if (item.key === 'overspent' && item.bar.data.overspent === 0)
                    return
                  return (
                    <animated.path
                      // key={bar.key + bar.index + bar.x + bar.y}
                      d={`M${item.x + item.width / 1.5 / 4},${
                        item.y + item.height
                      } v-${item.height} q0,-5 5,-5 h${
                        item.width / 1.5
                      } q5,0 5,5 v${item.height}`}
                      fill={item.color}
                      onClick={() => {
                        if (events && onClickBar) {
                          const { index } = item
                          onClickBar(index)
                        }
                      }}
                      // onMouseLeave={() => {
                      //   tooltipTimeout = window.setTimeout(() => {
                      //     hideTooltip();
                      //   }, 300);
                      // }}
                      // onMouseMove={(event) => {
                      //   if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      //   const top = event.clientY - margin.top;
                      //   const left = bar.x + bar.width / 2;
                      //   showTooltip({
                      //     tooltipData: bar,
                      //     tooltipTop: top,
                      //     tooltipLeft: left
                      //   });
                      // }}
                    />
                  )
                })
              )
            }
          </BarStack>
          {/* {avgExpenses && (
            <LineSubject
              orientation='horizontal'
              min={0}
              max={xMax + 3}
              y={valuesScale(avgExpenses)}
              stroke={DarkColors.MINT}
              strokeWidth={2}
              strokeDasharray={'4 3'}
            />
          )} */}
        </Group>
        <AxisRight
          top={margin.top}
          left={xMax + 20}
          scale={valuesScale}
          numTicks={4}
          hideTicks
          hideAxisLine
          hideZero
          tickLabelProps={() => ({
            fill: textColor,
            fontSize: 12,
            textAnchor: 'start',
            dy: '0.33em',
          })}
          tickFormat={(d) => numeral(d).format('0a')}
        />
        <AxisBottom
          top={yMax + margin.top}
          left={margin.left}
          tickFormat={formatDate}
          scale={xScale}
          stroke={textColor}
          strokeWidth={0}
          tickStroke={textColor}
          hideTicks
          tickLabelProps={() => ({
            fill: textColor,
            fontSize: 12,
            textAnchor: 'middle',
          })}
        />
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={defaultStyles}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]}℉</div>
          <div>
            <small>{formatDate(_date(tooltipData.bar.data))}</small>
          </div>
        </TooltipInPortal>
      )}
    </div>
  )
}
