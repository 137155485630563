import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { UseQueryOptions } from '@tanstack/react-query'
import { TGoal } from '../../lib/types'
import { FIVE_MINUTES } from '../../settings'
import useFetch from '../useFetch'

interface GoalsRequest {
  id?: string
  slug?: string
  include?: string
  hidden?: boolean
}

export function useGoals(
  { id, slug, include, hidden }: GoalsRequest = {},
  opts: UseQueryOptions = {}
) {
  const { isLoading, isError, data, error } = useFetch<TGoal[]>(
    ['goals', { id, slug, hidden, include }],
    {
      url: '/goals/list/',
      params: { id, slug, include, hidden },
    },
    {
      staleTime: FIVE_MINUTES,
      ...opts,
    }
  )

  const groups = useMemo(() => groupBy(data, 'category'), [data])

  return {
    isFetchingGoals: isLoading,
    goals: data ?? [],
    groups: groups,
    budgets: groups['$budget'] ?? [],
    activeGoals: groups['null'] ?? [],
    goalsError: error,
    isGoalsError: isError,
  }
}
