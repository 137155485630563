import {
  add,
  differenceInCalendarDays,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  isToday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'
// @ts-expect-error typedefs
import { unpack } from 'jsonh'
import {
  assign,
  filter,
  groupBy,
  map,
  orderBy,
  reduce,
  sortBy,
  takeWhile,
} from 'lodash'
import { datesDesc, Day } from './dates'
import { merchantName } from './strings'
import { TAccount, TGoal, TSummary, TTransaction } from './types'
import { queryString } from './utils'

export { unpack }

export function _amount(txs: TTransaction[]) {
  return map(txs, (t) => Number(t.amount))
}

export const _recurring = (arr: any[]) => filter(arr, 'isrecurring')

export function sumAmounts(list: TTransaction[]) {
  return list.reduce((prev, t) => prev + Number(t.amount), 0)
}

const isIncome = (tx: TTransaction) => tx && Number(tx.amount) < 0
const isSpending = (tx: TTransaction) => tx && Number(tx.amount) > 0
export const selectIncome = (arr?: TTransaction[]) => filter(arr, isIncome)
export const selectExpenses = (arr?: TTransaction[]) => filter(arr, isSpending)
export const getDirection = (t: TTransaction) =>
  Number(t.amount) < 0 ? 'debit' : 'credit'
export const selectTodays = (arr: TTransaction[]) =>
  arr.filter((t) => isToday(toLocaleDate(t.date)))

export const startWeekDate = (date = Day()) =>
  startOfWeek(date, {
    weekStartsOn: 1,
  })

// Note: week starts on Monday
export const selectWeekly = (arr: TTransaction[]) =>
  arr.filter((t) => !t.isrecurring && toLocaleDate(t.date) >= startWeekDate())

export function groupByCategory(
  txs: TTransaction[],
  fn = (t: TTransaction) => t.category
) {
  return groupBy(txs, fn)
}

export function groupByMerchant(list: TTransaction[]) {
  return groupBy(list, merchantName)
}

export function toLocaleDate(date: string) {
  if (!date) return new Date()
  return new Date(date.replace('Z', ''))
}

export const _summary = {
  expenses: [] as TTransaction[],
  income: [] as TTransaction[],
  categories: {} as Record<string, TTransaction[]>,
  monthly: [],
  totals: {},
}

export function summaries(data?: TTransaction[]) {
  let result = {
    expenses: [] as TTransaction[],
    income: [] as TTransaction[],
    categories: {} as Record<string, TTransaction[]>,
    monthly: [],
  }

  if (!data?.length) return result
  // else data = flattenByMerchants(data)

  // Calculate monthly stats
  const months = groupBy(data, (t: TTransaction) =>
    startOfMonth(toLocaleDate(t.date)).toISOString()
  )
  let avgIncome = 0,
    avgSpending = 0,
    recurringTotal = 0,
    monthsCount = 0,
    dates = Object.entries(months)

  // Sort months chronologically
  dates.sort((a, b) => a[0].localeCompare(b[0]))
  let monthly = map(dates, ([date, list]) => {
    const incomeList = selectIncome(list)
    const expensesList = selectExpenses(list)
    result.income.push(...incomeList)
    result.expenses.push(...expensesList)
    const incomeTotal = Math.abs(sumAmounts(incomeList))
    const expensesTotal = sumAmounts(expensesList)

    avgIncome += incomeTotal
    avgSpending += expensesTotal
    recurringTotal += sumAmounts(_recurring(expensesList))
    monthsCount += 1

    let categories = groupBy(expensesList, (t) => t.category)
    let categoriesTotal = reduce(
      categories,
      (prev, list, key) => assign(prev, { [key]: sumAmounts(list) }),
      {}
    )

    return {
      date,
      income: incomeList,
      expenses: expensesList,
      categories,
      totals: {
        income: incomeTotal,
        avg_income: avgIncome / monthsCount,
        expenses: expensesTotal,
        avg_expenses: avgSpending / monthsCount,
        recurring: recurringTotal / monthsCount,
        balance: incomeTotal - expensesTotal,
        categories: categoriesTotal,
      },
    } as TSummary
  })
  return {
    ...result,
    monthly,
  }
}

function getRecurringPeriod(t: any) {
  if (!t) return undefined
  if (t.isrecurring === 1) {
    return 1
  } else if (t.isrecurring === 10) {
    return 7
  } else if (t.isrecurring === 20) {
    return 14
  } else if (t.isrecurring === 100) {
    return 30
  } else if (t.isrecurring === 1000) {
    return 365
  }
}

export function projectRecurring(transactions: TTransaction[], endDate: Date) {
  const now = startOfDay(Day())
  const yearAgo = sub(now, { years: 1 })
  const weekAgo = sub(now, { days: 7 })
  let futures = []
  const recentTx = takeWhile(
    transactions,
    (t) => toLocaleDate(t.date) >= weekAgo
  )
  for (let t of transactions) {
    // Note this assumes txs is ordered by date descending
    if (!t.isrecurring) continue
    let lastPostedDate = toLocaleDate(t.date)
    if (lastPostedDate < yearAgo) break
    let days = getRecurringPeriod(t)
    if (days) {
      let nextDue = startOfDay(add(lastPostedDate, { days }))
      if (nextDue >= now && nextDue < endDate) {
        let next = {
          ...t,
          id: undefined,
          amount: Number(t.amount),
          date: nextDue.toISOString().replace('Z', ''),
          days,
        }
        // Check if it already posted
        let prevSeen = recentTx.find((x) => isSameMerchant(x, t))
        if (
          !prevSeen ||
          differenceInDays(nextDue, toLocaleDate(prevSeen.date)) > 7
        ) {
          futures.push(next)
        }
      }
    }
  }

  futures = orderBy(futures, 'date') as unknown as TTransaction[]
  return futures
}

export function pullBalanceFwd(future: TTransaction[], startBalance = 0) {
  future = orderBy(future, 'date')
  const size = future.length
  let sumIncomes = new Array(size).fill(0)
  let sumExpenses = new Array(size).fill(0)
  let rollingBalance = new Array(size).fill(0)
  rollingBalance[0] = startBalance

  for (let i = 0; i < size; i++) {
    const tx = future[i]
    rollingBalance[i] -= tx.amount

    if (tx.amount < 0) {
      sumIncomes[i] -= tx.amount
    } else {
      sumExpenses[i] += tx.amount
    }

    if (i > 0) {
      rollingBalance[i] += rollingBalance[i - 1]
      sumIncomes[i] += sumIncomes[i - 1]
      sumExpenses[i] += sumExpenses[i - 1]
    }
  }

  let tx = takeWhile(rollingBalance, (b) => b < 0 || b <= rollingBalance[0])
  let n = tx.length
  return {
    breakEven: n,
    breakEvenDate: future[n]?.date,
    payables: sumExpenses[n],
  }
}

export function balance(account: TAccount) {
  if (account.type === 'depository') {
    let avail = availableBalance(account)
    return avail || currentBalance(account)
  }
  return currentBalance(account)
}

export function availableBalance(account: TAccount) {
  return Number(account.available_balance)
}

export function currentBalance(account: TAccount) {
  return Number(account.current_balance)
}

export function balanceLimit(account: TAccount) {
  return Number(account.balance_limit || 0)
}

const isSameMerchant = (x: TTransaction, y: TTransaction) => {
  if (x.merchant_name) {
    return x.merchant_name === y.merchant_name
  } else if (x.label) {
    return x.label === y.label
  } else if (x.keywords) {
    return x.keywords === y.keywords
  }
  return x.name === y.name
}

const date = (t: TTransaction) => toLocaleDate(t.date)

/**
 * This method assumes the list is given in ascending order of ['keywords', 'label', 'id']
 * @param {Transaction[]} list A list of transactions
 * @returns {Transaction[]} The list of transactions in *reverse* chronological order.
 */
export function flattenByMerchants(list: TTransaction[]) {
  let sorted = sortBy(list, ['keywords', 'label', 'id'])
  sorted.forEach((curr, i) => {
    let prev = sorted[i - 1]
    if (
      prev &&
      (prev.keywords || prev.label) &&
      // same merchant
      (curr.keywords === prev.keywords || curr.label === prev.label) &&
      // same, opposite amounts
      curr.amount == prev.amount * -1 &&
      // same "period"
      differenceInCalendarDays(date(curr), date(prev)) < 90
    ) {
      prev.istransfer = 1
      curr.istransfer = 1
    }
  })
  // reverse chronological order
  sorted.sort((a, b) => datesDesc(date(a), date(b)))
  return sorted
}

export function monthlyBudget(goal: any, currentExpenses: number) {
  let amount = parseFloat(goal.amount) || 0
  // weekly spending target
  let discretionary = parseFloat(goal.discretionary) || 0
  let monthlyTotal = amount + discretionary * 4
  return monthlyTotal
}

export function getPaymentScheduled(
  amount: number,
  dueDate: Date,
  schedule: number
) {
  let periods = 1,
    now = new Date()

  switch (schedule) {
    case 7:
      periods = differenceInWeeks(dueDate, now)
      break

    case 14:
      periods = differenceInWeeks(dueDate, now) / 2
      break

    case 30:
      periods = differenceInMonths(dueDate, now)
      break

    case 90:
      periods = differenceInQuarters(dueDate, now)
      break

    default:
      periods = differenceInWeeks(dueDate, now)
      break
  }

  return amount / (periods || 1)
}

export function goalURL(goal: TGoal, current: number, base = '/goals') {
  return `${base}/edit/${goal.id}${queryString({
    name: goal.name,
    amount: goal.amount,
    current: Math.round(current),
    target_date: goal.target_date,
  })}`
}
