import {
  IonAvatar,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonText,
  useIonAlert,
} from '@ionic/react'
import { helpCircle } from 'ionicons/icons'
import { memo, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { BudgetsList } from '../../components/BudgetsList'
import { GoalList } from '../../components/GoalsList'
import { Currency } from '../../components/Numeral'
import { Paywall } from '../../components/Paywall'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useProfile } from '../../hooks/loaders/useProfile'
import { TAccount, TGoal } from '../../lib/types'
import { queryString } from '../../lib/utils'
import { Page, SkeletonList } from '../Page'
import './goals.scss'
import { ReferralsBanner } from './ReferralLinkModal'

const seed = Math.random()
const showReferralsExp = seed >= 0.5

export default function GoalsPage({}: RouteComponentProps<any>) {
  const { accounts, isFetchingAccounts, totalBalance } = useBankAccounts()
  const { budgets, activeGoals, isFetchingGoals } = useGoals({ hidden: false })
  return (
    <Page className='user_goals' title='Overview'>
      <Paywall />
      <Balance isFetching={isFetchingAccounts} totalBalance={totalBalance} />

      <CurrentGoals
        isFetching={isFetchingGoals}
        goals={activeGoals}
        budgets={budgets}
        accounts={accounts}
      />

      {showReferralsExp && <ReferralsBanner />}

      <FeaturedGoals totalCreated={budgets?.length + activeGoals?.length} />
    </Page>
  )
}

const CurrentGoals = ({
  isFetching,
  goals,
  budgets,
  accounts,
}: {
  isFetching: boolean
  budgets: TGoal[]
  goals: TGoal[]
  accounts?: TAccount[]
}) => {
  if (isFetching) {
    return (
      <>
        <IonList className='header'>
          <IonListHeader>My goals</IonListHeader>
        </IonList>
        <SkeletonList className='sparse' />
      </>
    )
  }

  if (budgets.length || goals.length) {
    return (
      <>
        <IonList className='header'>
          <div className='flex between align-baseline'>
            <IonListHeader>My goals</IonListHeader>
            <Link
              color='primary'
              to='/goals/history'
              style={{
                minWidth: '6rem',
                textAlign: 'right',
                marginRight: 20,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              More <i className='fas fa-chevron-right' />
            </Link>
          </div>
        </IonList>
        <BudgetsList goals={budgets} />
        <GoalList goals={goals} />
      </>
    )
  }

  if (!accounts?.length) {
    return (
      <IonList lines='none'>
        <IonListHeader>My goals</IonListHeader>
        <AccountsTip />
      </IonList>
    )
  }

  return (
    <IonList lines='none'>
      <IonListHeader>My goals</IonListHeader>
      <BudgetTip />
    </IonList>
  )
}

const Balance = ({
  isFetching,
  totalBalance,
}: {
  isFetching: boolean
  totalBalance: number
}) => {
  const [alert] = useIonAlert()
  return (
    <IonList lines='none'>
      <IonListHeader>Cash</IonListHeader>
      <IonItem
        onClick={() =>
          alert({
            header: 'Cash',
            message:
              'The total balance of your checking and savings accounts, minus credit card balances.',
            buttons: ['Okay'],
          })
        }
      >
        <h1 className='big-num'>
          {isFetching ? (
            <IonText color='medium'>$$$</IonText>
          ) : (
            <Currency value={totalBalance} format='$0,0' />
          )}
        </h1>
      </IonItem>
    </IonList>
  )
}

const BudgetTip = memo(() => (
  <IonList lines='none'>
    <IonItem
      className='tip-item'
      routerLink={`/goals/budget?name=My Budget`}
      detail
    >
      <IonIcon icon={helpCircle} color='medium' slot='start' />
      <div style={{ maxWidth: '95%' }}>
        <IonLabel style={{ marginBottom: 5, fontWeight: 500 }}>
          Get started on your budget
        </IonLabel>
        <IonLabel color='medium' className='ion-text-wrap'>
          See your personalized budget and savings target recommendations.
        </IonLabel>
      </div>
    </IonItem>
  </IonList>
))

// const SavingsTip = () => (
//   <IonList lines='none'>
//     <IonItem className='tip-item'>
//       <IonIcon icon={helpCircle} slot='start' />
//       <IonLabel className='ion-text-wrap'>
//         Start saving for an emergency, vacation or down payment for a car.
//       </IonLabel>
//     </IonItem>
//   </IonList>
// )

const AccountsTip = memo(() => (
  <IonList lines='none'>
    <IonItem className='tip-item' lines='none' routerLink='/plaid/start' detail>
      <IonIcon icon={helpCircle} color='medium' slot='start' />
      <div style={{ maxWidth: '95%' }}>
        <IonLabel style={{ marginBottom: 5, fontWeight: 500 }}>
          Add your accounts
        </IonLabel>
        <IonLabel color='medium' className='ion-text-wrap'>
          Connect your accounts to view balances and trends.
        </IonLabel>
      </div>
    </IonItem>
  </IonList>
))

export const Avatar = memo(({ children }: { children: ReactNode }) => (
  <IonAvatar slot='start' className='avatar-icon'>
    {children}
  </IonAvatar>
))

const GoalItem = ({
  label,
  icon,
  title,
  category,
  previous,
  showPaywall,
}: {
  label: string
  icon: string
  title?: string
  category?: string
  previous: number
  showPaywall: (p: boolean, pass: string) => any
}) => (
  <IonCard
    className='flex align-center'
    onClick={showPaywall(
      previous >= 3,
      `/goals/new${queryString({
        category,
        name: title ?? label,
        icon,
      })}`
    )}
  >
    <div className='flex column center align-center'>
      <Avatar>{icon}</Avatar>
      <h6>{label}</h6>
    </div>
  </IonCard>
)

const BudgetItem = ({
  label,
  icon,
  previous,
  showPaywall,
}: {
  label: string
  icon: string
  previous: number
  showPaywall: (p: boolean, pass: string) => any
}) => (
  <IonCard
    className='flex align-center'
    onClick={showPaywall(previous >= 3, `/goals/budget?name=My Budget`)}
  >
    <div className='flex column center align-center'>
      <Avatar>{icon}</Avatar>
      <h6>{label}</h6>
    </div>
  </IonCard>
)

const LoanItem = ({
  label,
  icon,
  type,
  previous,
  showPaywall,
}: {
  label: string
  icon: string
  type: string
  previous: number
  showPaywall: (p: boolean, pass: string) => any
}) => (
  <IonCard
    className='flex align-center'
    onClick={showPaywall(previous >= 3, `/loan/calc/${type}?name=${label}`)}
  >
    <div className='flex column center align-center'>
      <Avatar>{icon}</Avatar>
      <h6>{label}</h6>
    </div>
  </IonCard>
)

export function FeaturedGoals({ totalCreated }: { totalCreated: number }) {
  const { hasPaywall: showPaywall } = useProfile()
  return (
    <div>
      <IonList className='ion-margin-top'>
        <IonListHeader>New goals</IonListHeader>
      </IonList>
      <div className='grid-cards grid-md-3 grid-lg-4'>
        <BudgetItem
          icon='💲'
          label='Start a Budget'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <GoalItem
          icon='☂️'
          label='Emergency Fund'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <GoalItem
          icon='🏖'
          label='Plan a Trip'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <GoalItem
          icon='💳'
          label='Pay off Debt'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <LoanItem
          icon='🚙'
          label='Buy a Car'
          type='car'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <LoanItem
          icon='🏠'
          label='Buy a Home'
          type='house'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <GoalItem
          icon='💰'
          label='Plan for Retirement'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        <LoanItem
          icon='💵'
          label='Loan Calculator'
          type='other'
          previous={totalCreated}
          showPaywall={showPaywall}
        />
        {/* <GoalItem
          icon="🎓"
          label="Save for college"
          previous={totalCreated}
          showPaywall={showPaywall}
        /> */}
        {/* <GoalItem icon="🛠️" label="Home improvements" /> */}
        <GoalItem
          icon='💵'
          label='Something else'
          title=''
          previous={totalCreated}
          showPaywall={showPaywall}
        />
      </div>
    </div>
  )
}
