import { useIonAlert } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { shieldCheckmark } from 'ionicons/icons'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { PlaidLink } from '../../components/PlaidLink'
import { usePlaidClient } from '../../hooks/loaders/usePlaidClient'
import { URL_HOST } from '../../settings'
import { Page } from '../Page'
import { StatusCard } from '../subscription/SubscriptionPage'
import './Plaid.scss'

export default function Plaid({ history, match }: RouteComponentProps<any>) {
  const queryClient = useQueryClient()
  const [showAlert] = useIonAlert()
  const { token, isOauth } = usePlaidClient({
    item_id: match.params.item_id,
    // Note: `redirect_uri` does not allow query params
    redirect_uri: `${URL_HOST}/plaid/oauth`,
  })

  useEffect(() => {
    queryClient.invalidateQueries(['accounts'])
  })

  return (
    <Page>
      <StatusCard icon={shieldCheckmark} color='medium' title='Connecting'>
        <p>Starting a secure connection to your bank...</p>
        {/* <IonButton href='/goals'>Continue</IonButton> */}
      </StatusCard>
      {token && (
        <PlaidLink
          token={token}
          receivedRedirectUri={isOauth ? window.location.href : null}
          onExit={() => {
            history.replace('/accounts')
          }}
          onHandoff={async () => {
            history.replace('/accounts')
          }}
          onError={(err) => {
            if (err.error_message)
              showAlert({
                header: 'Error',
                message: err.error_message,
                buttons: [{ text: 'Okay', role: 'button' }],
              })
            console.error('Error: plaid link', err)
          }}
        />
      )}
    </Page>
  )
}
