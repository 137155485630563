import { IonButtons, RefresherEventDetail } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { chevronBack } from 'ionicons/icons'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { AccountsList } from '../../components/accounts/AccountsList'
import IconButton from '../../components/IconButton'
import { requestNotifications } from '../../lib/notifications'
import { Page } from '../../pages/Page'

export default function AccountsPage(props: RouteComponentProps<any>) {
  const queryClient = useQueryClient()

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    try {
      await queryClient.invalidateQueries(['accounts'])
    } finally {
      event?.detail?.complete()
    }
  }

  // Request notifications permissions
  useEffect(() => {
    requestNotifications()
  }, [])

  return (
    <Page
      title='Accounts'
      start={
        <IonButtons slot='start'>
          <IconButton
            icon={chevronBack}
            routerLink='/'
            routerDirection='back'
          />
        </IonButtons>
      }
      onRefresh={handleRefresh}
    >
      <AccountsList history={props.history} />
    </Page>
  )
}
