import numeral from 'numeral'

export { numeral }

export function fmtNum(value: any, fmt: string) {
  return numeral(value).format(fmt)
}

export const cash = (value: any, fmt = '$0,0') => fmtNum(value, fmt)

export function Numeral({
  value,
  format = '0.00',
}: {
  value: any
  format?: string
}) {
  return <>{numeral(value).format(format)}</>
}

export function Currency({
  value,
  format = '$0,0.00',
  abs = false,
  round = false,
}: {
  value: any
  format?: string
  abs?: boolean
  round?: boolean
}) {
  if (round) {
    value = Math.round(Number(value))
    // dont show decimals
    format = format.split('.')[0]
  }
  let val = numeral(value).format(format)
  if (abs && value < 0) {
    val = '+' + val.substr(1)
  }
  return <>{val}</>
}

export const percent = (value: any, fmt = '0%') => fmtNum(value, fmt)

export function Percent({
  value,
  format = '0%',
  abs = false,
}: {
  value: any
  format?: string
  abs?: boolean
}) {
  return <>{percent(value, format)}</>
}
