import {
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  useIonAlert,
  useIonRouter,
} from '@ionic/react'
import {
  cardOutline,
  cardSharp,
  chevronBack,
  logOutOutline,
  openOutline,
  personOutline,
  trashOutline,
} from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { UserAvatar } from '../../components/avatars/UserAvatar'
import IconButton from '../../components/IconButton'
import { Paywall } from '../../components/Paywall'
import { useProfile } from '../../hooks/loaders/useProfile'
import { deleteUserAccount, getBillingSession } from '../../lib/api'
import { signOut } from '../../lib/firebase'
import { store } from '../../lib/store'
import { TProfile } from '../../lib/types'
import { Page } from '../Page'

function fullName(profile?: TProfile, user: any = undefined) {
  if (!profile) return user?.displayName
  const { first_name, last_name, display_name } = profile
  if (first_name && last_name) {
    return first_name + ' ' + last_name
  }
  return display_name ?? user?.displayName
}

export function ProfileItem({ profile }: { profile?: TProfile }) {
  const user = store.useState((s) => s.user)
  return (
    <>
      <UserAvatar photoURL={user?.photoURL} displayName={user?.displayName} />
      <div>
        <IonLabel
          style={{
            fontWeight: 500,
            fontSize: '1rem',
          }}
        >
          {fullName(profile, user)}
        </IonLabel>
        <IonText color='medium'>
          <small>@{profile?.user_name}</small>
        </IonText>
      </div>
    </>
  )
}

export default function SettingsPage(props: RouteComponentProps<any>) {
  const user = store.useState((s) => s.user)
  const { profile, isLoadingProfile } = useProfile()
  const [values, setValues] = useState<any & TProfile>()
  const [present] = useIonAlert()
  const { push } = useIonRouter()

  useEffect(() => {
    if (profile) setValues(profile)
  }, [profile])

  return (
    <Page
      start={
        <IonButtons slot='start'>
          <IconButton
            icon={chevronBack}
            routerLink='/'
            routerDirection='back'
          />
        </IonButtons>
      }
      title='Profile'
      isLoading={isLoadingProfile}
    >
      <>
        <IonList className='ion-margin-vertical' lines='none'>
          <IonItem routerLink='/settings/personal' detail={false}>
            <ProfileItem profile={profile} />
          </IonItem>
        </IonList>

        <IonList className='sparse ion-margin-top' lines='none'>
          <IonItem routerLink='/settings/personal'>
            <IonLabel>Account</IonLabel>
            <IonIcon icon={personOutline} slot='start' />
          </IonItem>

          {profile?.payment_status === 'paid' && (
            <IonItem
              button
              onClick={async () => {
                let data = await getBillingSession(profile.customer_id!)
                window.location.assign(data.location)
              }}
            >
              <IonLabel>Billing</IonLabel>
              <IonIcon icon={cardOutline} slot='start' />
            </IonItem>
          )}

          {!profile?.subscription_id && (
            <IonItem
              button
              onClick={() => {
                window.location.hash = '#paywall'
              }}
            >
              <IonLabel>Upgrade to pro</IonLabel>
              <IonIcon icon={openOutline} slot='start' />
            </IonItem>
          )}

          <IonItem
            button
            onClick={() => {
              present('This will remove all your account data. Continue?', [
                { text: 'Cancel' },
                {
                  text: 'OK',
                  handler: async () => {
                    deleteUserAccount(user!.uid)
                    await signOut()
                    push('/login')
                  },
                },
              ])
            }}
          >
            <IonLabel color='danger'>Delete Account</IonLabel>
            <IonIcon icon={trashOutline} slot='start' />
          </IonItem>

          <IonItem
            button
            onClick={(e) => {
              present('Do you want to sign out?', [
                { text: 'Cancel' },
                {
                  text: 'Continue',
                  handler: async () => {
                    await signOut()
                    push('/login')
                  },
                },
              ])
            }}
          >
            <IonLabel>Sign out</IonLabel>
            <IonIcon icon={logOutOutline} slot='start' />
          </IonItem>
        </IonList>
      </>
    </Page>
  )
}
