import { formatDistanceToNowStrict, addDays, isAfter } from 'date-fns'
import { get, set, del } from 'idb-keyval'
import { forEach, omit, once } from 'lodash'
import { abs } from 'mathjs'

import { merchantName } from './strings'
import { groupByMerchant, sumAmounts, toLocaleDate } from './transactions'
import { TSummary, TTransaction } from './types'
import { cash, percent } from '../components/Numeral'
import { hashCode } from './utils'
import { DOMAIN } from '../settings'

export function requestNotifications() {
  // @ts-ignore
  if (typeof progressier !== 'undefined') {
    // @ts-expect-error
    progressier.subscribe()
  }
  if ('Notification' in window) {
    return Notification.requestPermission()
  }
  return Promise.resolve('error')
}

const NOTIFICATION_TITLE = 'ClearMoney'

async function showNotification(opts: NotificationOptions) {
  console.log('notification:', opts)
  if ('Notification' in window) {
    try {
      let permission = await requestNotifications()
      if (permission === 'granted') {
        const hashKey = hashCode(opts.body!).toString()
        let timestamp = await get(hashKey)
        if (timestamp) {
          // check if the user saw this notification less than 30 days ago
          let clearDate = addDays(Number(timestamp), 30)
          if (isAfter(new Date(), clearDate)) {
            await del(hashKey)
          } else return
        }
        const registration = await navigator.serviceWorker.ready
        await registration.showNotification?.(NOTIFICATION_TITLE, {
          icon: '/assets/icon/icon.png',
          // badge: '/assets/icon/favicon.png', // use transparent image (alpha channel only)
          tag: `${DOMAIN}/data/${hashKey}`,
          ...opts,
        })
        await set(hashKey, Date.now())
      }
    } catch (error: any) {
      alert(error.message)
    }
  }
}

export const notifySubscriptions = once((now: TSummary, prev: TSummary) => {
  const bills = groupByMerchant(now.expenses.filter((x) => x.isrecurring))
  const prevBills = groupByMerchant(prev.expenses.filter((x) => x.isrecurring))
  const categories = omit(now.categories, 'Payment')
  const prevCategories = omit(prev.categories, 'Payment')

  // Check if previous bills increased
  forEach(bills, (list, key) => {
    let currentTotal = sumAmounts(list)
    let prevs = prevBills[key]
    if (prevs) {
      let prevTotal = sumAmounts(prevs)
      let changeAmount = currentTotal - prevTotal
      let changePercent = changeAmount / prevTotal
      if (changePercent < 0.01) return
      showNotification({
        body: `Your ${key} bill went up by ${cash(changeAmount)}`,
        data: {
          url: `/cashflow?type=expenses`,
        },
      })
    }
  })

  // Check if previous categories increased
  forEach(categories, (list, key) => {
    let currentTotal = sumAmounts(list)
    let prevs = prevCategories[key]
    if (prevs) {
      let prevTotal = sumAmounts(prevs)
      let changeAmount = currentTotal - prevTotal
      let changePercent = changeAmount / prevTotal
      if (changePercent < 0.02) return
      showNotification({
        body: `You spent ${percent(
          changePercent
        )} more in ${key} vs last month`,
        data: {
          url: `/cashflow?type=expenses`,
        },
      })
    }
  })
})

export const notifyComingSoon = once((future: TTransaction[]) => {
  forEach(future, (t) => {
    let amount = Number(t.amount)
    if (amount < 2) return
    let daysUntilDue = formatDistanceToNowStrict(toLocaleDate(t.date), {
      unit: 'day',
      roundingMethod: 'floor',
    })
    if (daysUntilDue !== '0 days') {
      daysUntilDue = 'in ' + daysUntilDue
    } else {
      daysUntilDue = 'today'
    }
    // TODO: replace bill name suffix like bill,charge,subscription,etc
    showNotification({
      body: `Your ${merchantName(t)} bill of ${cash(
        amount
      )} is due ${daysUntilDue}`,
      data: {
        url: '/transactions?type=coming_soon',
      },
    })
  })
})

export const notifyIncome = once((list: TTransaction[]) => {
  forEach(list, (t) => {
    let amount = Number(t.amount)
    if (amount > -2) return
    let merchant = merchantName(t)
    if (merchant) {
      showNotification({
        body: `You just got paid ${cash(abs(amount))} by ${merchant}!`,
        data: {
          url: `/cashflow?type=income`,
        },
      })
    }
  })
})
