import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
} from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { times } from 'lodash'
import { ComponentProps, memo, ReactElement, ReactNode, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import Navbar from '../components/Navbar'

export const SkeletonItem = (props: ComponentProps<typeof IonItem>) => (
  <IonItem lines='none'>
    <IonSkeletonText
      animated
      style={{ width: 36, height: 36, borderRadius: 36 }}
      slot='start'
    />
    <IonLabel>
      <h3>
        <IonSkeletonText animated style={{ width: '75%' }} />
      </h3>
      <p>
        <IonSkeletonText animated style={{ width: '50%' }} />
      </p>
    </IonLabel>
  </IonItem>
)

export const SkeletonList = memo(
  ({
    header,
    ...rest
  }: ComponentProps<typeof IonList> & { header?: ReactNode }) => (
    <IonList {...rest}>
      {header && <IonListHeader>{header}</IonListHeader>}
      {times(3, (i) => (
        <SkeletonItem key={i} />
      ))}
    </IonList>
  )
)

export function Page({
  id,
  color,
  isLoading,
  loadingMessage,
  title,
  start,
  end,
  children,
  footer,
  className,
  presentingElement,
  onRefresh,
}: {
  id?: string
  color?: string
  isLoading?: boolean
  loadingMessage?: string
  title?: string | ReactElement
  start?: ReactElement | null
  end?: ReactElement
  children?: ReactNode
  footer?: ReactNode
  className?: string
  presentingElement?: any
  onRefresh?: (e: CustomEvent) => void
}) {
  const [key, setKey] = useState(0)

  return (
    <IonPage
      className={`ion-page-content ${className}`}
      ref={presentingElement}
    >
      <Navbar
        color={color}
        title={title}
        start={
          start ?? (
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          )
        }
        end={end}
      />
      <IonContent id={id}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          key={key}
          onReset={() => {
            setKey(key + 1)
            window.location.reload()
          }}
        >
          <IonLoading
            isOpen={!!isLoading}
            duration={10000}
            message={loadingMessage ?? 'Loading...'}
          />
          {onRefresh && (
            <IonRefresher slot='fixed' onIonRefresh={onRefresh}>
              <IonRefresherContent />
            </IonRefresher>
          )}
          {children}
        </ErrorBoundary>
      </IonContent>
      {footer}
    </IonPage>
  )
}

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: any
}) {
  return (
    <StatusCard
      icon={alertCircle}
      title='Something went wrong'
      label='Try again'
      onClick={resetErrorBoundary}
    />
  )
}

function StatusCard({
  title,
  icon,
  label,
  onClick,
}: {
  icon: string
  title: ReactNode
  label: ReactNode
  onClick: VoidFunction
}) {
  return (
    <IonCard className='card_error'>
      <div className='icon'>
        <IonIcon icon={icon} color='medium' />
      </div>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonButton onClick={() => onClick()}>{label}</IonButton>
      </IonCardContent>
    </IonCard>
  )
}
