import { TCategories } from '../../lib/types'
import useFetch from '../useFetch'

export function useCategories() {
  const { data, error, isLoading } = useFetch<TCategories>(
    ['categories'],
    { url: '/categories/' },
    { staleTime: 5 * 60 * 1000 }
  )

  return {
    isFetchingCategories: isLoading,
    categories: data ?? {},
    categoriesError: error,
  }
}
