import {
  IonButton,
  IonButtons,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'
import { map } from 'lodash'
import { abs, sum } from 'mathjs'
import { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { AvatarStack } from '../../components/avatars/AvatarStack'
import { GoalSummary } from '../../components/GoalsList'
import IconButton from '../../components/IconButton'
import { TransactionsSection } from '../../components/transactions/TransactionsList'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useGoalMutation } from '../../hooks/mutations/useGoalMutation'
import { store } from '../../lib/store'
import { _amount } from '../../lib/transactions'
import { Page } from '../Page'
import './goals.scss'
import { GoalOptions, SharedGoalOptions } from './NewGoalPage'
import { ShareGoalButton } from './ShareGoalModal'

export function SharedGoalPage({
  history,
  match: { params },
}: RouteComponentProps<{ slug: string }>) {
  const user = store.useState((s) => s.user)
  const [showToast] = useIonToast()

  const { joinGoal } = useGoalMutation()
  const { goals, isFetchingGoals } = useGoals({
    slug: params.slug as string,
    include: 'transactions',
  })

  const goal = useMemo(() => goals[0], [goals])

  const sharedUsers = useMemo(() => {
    if (!goal?.shared_goal?.length) return []
    return map(goal.shared_goal, 'user').concat(goal.user!)
  }, [goal])

  const current = useMemo(() => {
    if (!goal) return 0
    return sum(abs(_amount(goal.transactions)))
  }, [goal])

  const [disabled, setDisabled] = useState(false)

  const progress = useMemo(() => {
    if (!goal) return 0
    let amount = Number(goal.amount)
    return current / amount
  }, [goal, current])

  const isSuccess = progress > 0.999
  const isOwner = user!.uid === goal?.user_id
  const isMember = goal?.shared_goal.some((x) => x.user.user_id === user!.uid)

  return (
    <Page
      isLoading={isFetchingGoals}
      start={
        <IonButtons slot='start'>
          <IconButton
            color='primary'
            icon={chevronBackOutline}
            routerLink='/goals'
            routerDirection='back'
          />
        </IonButtons>
      }
      end={
        <IonButtons slot='end'>
          {isOwner ? (
            <GoalOptions goal={goal} />
          ) : (
            <SharedGoalOptions goal={goal} />
          )}
        </IonButtons>
      }
      footer={
        <IonFooter>
          {!isFetchingGoals && !isOwner && !isMember && (
            <IonToolbar>
              <IonButton
                disabled={disabled}
                expand='block'
                onClick={() => {
                  setDisabled(true)
                  joinGoal.mutate(
                    { goal_id: goal.id, user_id: user?.uid },
                    {
                      onSuccess: () => showToast('You joined the goal!', 4000),
                      onError: () =>
                        showToast(
                          'Something went wrong, please try again',
                          4000
                        ),
                      onSettled: () => setDisabled(false),
                    }
                  )
                }}
              >
                Join goal
              </IonButton>
            </IonToolbar>
          )}
        </IonFooter>
      }
    >
      {isOwner && <ShareGoalButton goal={goal} />}

      <IonItem className='ion-padding-top' lines='none'>
        {/* {isSuccess && (
          <IonIcon
            icon={checkmarkCircleSharp}
            color='success'
            style={{ marginRight: 10 }}
          />
        )} */}
        <IonLabel>
          <h1>
            {goal?.icon} {goal?.name}
          </h1>
        </IonLabel>
      </IonItem>

      {goal && (
        <GoalSummary
          current={current}
          goal={goal}
          color={isSuccess ? 'success' : undefined}
        />
      )}

      {goal?.shared_goal?.length > 0 && (
        <IonList lines='none'>
          <IonItem>
            <IonLabel style={{ fontWeight: 500 }}>Members</IonLabel>
          </IonItem>
          <IonItem>
            <AvatarStack
              profiles={
                goal ? [{ user: goal.user! }].concat(goal.shared_goal) : []
              }
            />
          </IonItem>
        </IonList>
      )}

      <TransactionsSection
        label='Recent activity'
        data={goal?.transactions}
        isLoading={isFetchingGoals}
        users={sharedUsers}
        readOnly
      />
    </Page>
  )
}
