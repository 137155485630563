import { IonAvatar } from '@ionic/react'
import React, { useEffect, useRef } from 'react'
import { createUser } from '../lib/api'
import firebase, { getAuthToken } from '../lib/firebase'
import { store } from '../lib/store'
import logoImg from '../pages/login/app-icon-512.png'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import './FirebaseAuth.scss'
import { URL_HOST } from '../settings'
import { getUrlQuery } from '../lib/utils'
import { useQueryClient } from '@tanstack/react-query'

async function createNewUser(user: firebase.User, profile: any) {
  try {
    let authToken = await getAuthToken()
    await createUser(
      {
        user_id: user.uid,
        email: user.email,
        first_name: profile?.given_name,
        last_name: profile?.family_name,
        locale: profile?.locale,
        display_name: profile.displayName,
        email_verified: Boolean(user.emailVerified),
        phone_number: user.phoneNumber,
        photo_url: user.photoURL,
        referrer_id: profile.referrer_id,
      },
      authToken
    )
  } catch (error) {
    // TODO
  }
}

export default function FirebaseAuth() {
  const loginFormRef = useRef<any>()
  const user = store.useState((s) => s.user)
  const queryClient = useQueryClient()

  function onSuccess(authResult: any) {
    const user = authResult.user as firebase.User
    const { isNewUser, profile } = authResult.additionalUserInfo
    if (isNewUser) {
      createNewUser(user, {
        ...profile,
        referrer_id: sessionStorage.getItem('referrer_id'),
      })
    }
    return false
  }

  function start() {
    let ui = (
      firebaseui.auth.AuthUI.getInstance() ??
      new firebaseui.auth.AuthUI(firebase.auth())
    ).start(loginFormRef.current!, {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      // signInSuccessUrl: '/handleSignedIn',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod:
            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
          requireDisplayName: false,
          forceSameDevice: false,
          emailLinkSignIn: () => {
            return {
              url: URL_HOST,
              handleCodeInApp: true,
              ios: {
                bundleId: 'io.clearmoney.app',
              },
              android: {
                packageName: 'io.clearmoney.app.twa',
                installApp: true,
              },
            }
          },
        },
        // {
        //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        //   defaultCountry: 'US',
        //   // Invisible reCAPTCHA with image challenge and bottom left badge.
        //   recaptchaParameters: {
        //     type: 'image',
        //     size: 'invisible',
        //     badge: 'bottomleft',
        //   },
        // },
        // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      tosUrl: 'https://clearmoney.io/terms',
      // Privacy policy url/callback.
      privacyPolicyUrl: 'https://clearmoney.io/privacy',
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: onSuccess,
      },
    })
  }

  useEffect(() => {
    if (!user) {
      queryClient.removeQueries()
      let queryParams = getUrlQuery()
      let referrer_id = queryParams.get('referrer_id')
      if (referrer_id) {
        sessionStorage.setItem('referrer_id', referrer_id)
      }
      start()
    }
  }, [])

  if (!user) {
    return (
      <div className='signup-form'>
        <div className='flex center'>
          <IonAvatar className='app-icon'>
            <img src={logoImg} alt='' />
          </IonAvatar>
        </div>
        <h1 className='signup-h1'>Start growing your savings easily</h1>
        <p>Get started today for FREE.</p>
        <div id='firebaseui_container' ref={loginFormRef}></div>
      </div>
    )
  }

  return null
}
