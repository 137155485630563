import { IonProgressBar } from '@ionic/react'
import { memo } from 'react'

export const ProgressBar = memo(
  ({
    value,
    color,
    size,
    style = {},
  }: {
    value?: number
    color?: string
    size?: 'large' | undefined
    style?: any
  }) => {
    const isLarge = size === 'large'
    const height = isLarge ? '.9em' : '.4em'
    return (
      <IonProgressBar
        value={value}
        color={color}
        style={{
          height,
          margin: '0.75rem auto',
          borderRadius: 8,
          ...style,
          width: 'auto',
        }}
      />
    )
  }
)
