import { IonButton, IonIcon } from '@ionic/react'
import React, { ComponentProps, MouseEventHandler } from 'react'

import './buttons.scss'

export default function IconButton({
  icon,
  iconProps,
  style,
  size,
  color,
  routerLink,
  routerDirection,
  target,
  disabled,
  slot = '',
  onClick,
}: {
  icon: string
  iconProps?: ComponentProps<typeof IonIcon>
  style?: any
  size?: 'default' | 'small' | 'large'
  color?: string
  routerLink?: string
  routerDirection?: 'none' | 'forward' | 'back' | 'root'
  target?: string
  disabled?: boolean
  slot?: string
  onClick?: MouseEventHandler
}) {
  return (
    <IonButton
      onClick={onClick}
      size={size}
      color={color}
      routerLink={routerLink}
      routerDirection={routerDirection}
      target={target}
      fill="clear"
      // shape="round"
      slot={slot}
      style={style}
      className="icon_button"
      disabled={disabled}
    >
      <IonIcon slot="icon-only" icon={icon} {...iconProps} />
    </IonButton>
  )
}
