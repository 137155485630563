import { RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSkeletonText,
  IonText,
  IonToast,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import {
  addOutline,
  chevronBackOutline,
  settingsOutline,
  shareSocial,
} from 'ionicons/icons'
import { some, startCase, times } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { RouteComponentProps } from 'react-router'
import '../../components/accounts/accounts.scss'
import IconButton from '../../components/IconButton'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { Currency, Percent } from '../../components/Numeral'
import { TransactionsPeriod } from '../../components/transactions/TransactionsList'
import { useAccountMutations } from '../../hooks/mutations/useAccountMutation'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { refreshTransactions, urlParams } from '../../lib/api'
import { store } from '../../lib/store'
import { TAccount } from '../../lib/types'
import { Page, SkeletonItem } from '../Page'
import { ShareAccountButton } from './ShareAccountModal'
import { AccountAvatar } from '../../components/accounts/AccountsList'
import { DateTime } from '../../components/DateTime'
import { ManualTransaction, ManualTransactionModal } from './ManualTransaction'

export type AccountPageProps = RouteComponentProps<{
  account_id?: string
  id?: string
}>

export const AccountDetails = ({ account }: { account: TAccount }) => {
  let current = Number(account.current_balance)
  let available = Number(account.available_balance)
  let limit = Number(account.balance_limit)
  let treshold = limit * 0.0999
  return (
    <IonList lines='none'>
      <IonItem>
        <IonLabel>Balance</IonLabel>
        <IonLabel slot='end'>
          <Currency value={current} />
        </IonLabel>
      </IonItem>
      {account.available_balance && (
        <IonItem>
          <IonLabel>Available</IonLabel>
          <IonLabel slot='end'>
            <Currency value={account.available_balance} />
          </IonLabel>
        </IonItem>
      )}
      {limit > 0 && (
        <IonItem>
          <IonLabel>Credit Limit</IonLabel>
          <IonLabel slot='end'>
            <Currency value={limit} />
          </IonLabel>
        </IonItem>
      )}
      {account.interest_rate && (
        <IonItem>
          <IonLabel>Interest Rate (APR)</IonLabel>
          <IonLabel slot='end'>
            <Percent
              format='0[.]00%'
              value={Number(account.interest_rate) / 100}
            />
          </IonLabel>
        </IonItem>
      )}

      {/* <IonItem>
        <IonLabel>Last updated</IonLabel>
        <IonLabel slot='end'>
          <DateTime value={account.updated_at} />
        </IonLabel>
      </IonItem> */}
      {/* <IonItem>
        <IonLabel>Total Balance</IonLabel>
        <IonLabel slot="end">
          <Currency value={limit - available} />
        </IonLabel>
      </IonItem> */}
      {current > 0 && limit > 0 && (
        <IonItem>
          <LabelWithInfo
            label='Credit Used'
            header='Credit Used'
            message={`Keeping your credit utilization below 30% and paying down your balance will improve your score.`}
          />
          <IonLabel slot='end'>
            <Percent value={Number(current) / Number(limit)} />
          </IonLabel>
        </IonItem>
      )}
      {current > treshold && limit > 0 && (
        <IonItem>
          <LabelWithInfo
            label='Suggested Payment'
            header='Suggested Payment'
            message={`Consider paying this amount to stay below 10% utilization and improve your credit score.`}
          />
          <IonLabel slot='end'>
            <Currency round value={Math.ceil(current - treshold)} />
          </IonLabel>
        </IonItem>
      )}
    </IonList>
  )
}

function isUserJoined(account?: TAccount, user_id?: string) {
  if (!account || !user_id) return false
  if (user_id == account.user_id) return true
  return some(account.shared_users, { user_id })
}

export default function AccountPage({ match, history }: AccountPageProps) {
  const { id, account_id } = match.params
  const user = store.useState((s) => s.user)
  const query = urlParams()
  const queryClient = useQueryClient()

  let accountById = { account_id, include: 'institution', id }
  const { accounts } = useBankAccounts(accountById)

  const account = accounts && accounts[0]
  const isOwner = useMemo(() => account?.user_id === user?.uid, [account, user])
  const [current, setCurrent] = useState({ joining: false })
  const [isExpired, showExpiredToast] = useState(false)

  const { joinAccount } = useAccountMutations(account!, history)

  useEffect(() => {
    if (isOwner && account?.status === 'expired') {
      showExpiredToast(true)
    }
  }, [account, isOwner])

  const accountDetails = useMemo(() => {
    if (!account) {
      return (
        <IonList lines='none'>
          {times(4, (i) => (
            <IonItem key={i}>
              <IonLabel>
                <IonSkeletonText animated style={{ width: '80%' }} />
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      )
    }
    return (
      <>
        <AccountDetails account={account} />
        <IonToast
          isOpen={isExpired}
          onDidDismiss={() => showExpiredToast(false)}
          message='Your account needs attention'
          position='bottom'
          buttons={[
            {
              text: 'Fix',
              handler: () =>
                history.push(
                  `/plaid/start/${account.item_id}/${account.account_id}`
                ),
            },
          ]}
        />
      </>
    )
  }, [account, isExpired, history])

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    try {
      await queryClient.invalidateQueries(['accounts', accountById])
      await refreshTransactions(account!.item_id)
    } finally {
      event?.detail?.complete()
    }
  }

  const [showToast] = useIonToast()

  function handleJoin() {
    setCurrent({ ...current, joining: true })
    joinAccount.mutate(
      {
        account_id: account?.account_id,
        user_id: user?.uid,
      },
      {
        onSuccess: async () => {
          await showToast('Your changes have been saved', 5000)
        },
        onError: async () => {
          await showToast('Something went wrong, please try again', 5000)
          setCurrent({ ...current, joining: false })
        },
      }
    )
  }

  return (
    <Page
      title={account?.name}
      start={
        <IonButtons slot='start'>
          <IconButton
            color='primary'
            icon={chevronBackOutline}
            routerLink='/accounts'
            routerDirection='back'
          />
        </IonButtons>
      }
      end={
        <IonButtons slot='end'>
          <IconButton
            icon={settingsOutline}
            color='dark'
            routerLink={`/accounts/pref/${account?.id}?name=${account?.name}`}
            routerDirection='forward'
          />
        </IonButtons>
      }
      onRefresh={handleRefresh}
      footer={
        <IonFooter>
          {account && !isUserJoined(account, user?.uid) && (
            <IonToolbar>
              <IonButton
                className='ion-margin'
                expand='block'
                onClick={handleJoin}
                size='large'
                disabled={current.joining}
              >
                Join account
              </IonButton>
            </IonToolbar>
          )}
        </IonFooter>
      }
    >
      <AccountHero account={account} />

      {accountDetails}

      {isOwner && <ShareAccountButton account={account!} />}

      <ManualTransactionModal account={account} />

      {/* Here be actions like tinyurl.com/straightapp */}
      <div className='ion-margin-vertical'>
        {account?.account_id && (
          <TransactionsPeriod accountId={account.account_id} />
        )}
      </div>
    </Page>
  )
}

export const AccountHero = ({ account }: { account?: TAccount }) => {
  // Skeleton
  if (!account) {
    return (
      <IonList>
        <SkeletonItem className='account_hero' />
      </IonList>
    )
  }

  return (
    <div className='account_hero'>
      <AccountAvatar account={account} />
      <div className='flex column'>
        <h4 className='account_title'>
          {account?.name ?? account.official_name}
        </h4>
        <IonText color='medium'>
          {startCase(account?.subtype ?? '')}
          {account?.mask ? ` •••• ${account?.mask}` : ' Balance'}
        </IonText>
      </div>
    </div>
  )
}
