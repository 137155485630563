import { IonList, IonListHeader } from '@ionic/react'
import { filter, groupBy, orderBy } from 'lodash'
import { useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GoalList } from '../../components/GoalsList'
import { useGoals } from '../../hooks/loaders/useGoals'
import { Page, SkeletonList } from '../Page'
import './goals.scss'

export default function GoalsHistoryPage({}: RouteComponentProps<any>) {
  const { activeGoals: allGoals, isFetchingGoals } = useGoals()

  const { active, completed } = useMemo(() => {
    let goals = groupBy(allGoals, 'hidden')
    return {
      active: orderBy(goals['false'], 'created_at'),
      completed: orderBy(goals['true'], 'updated_at', 'desc'),
    }
  }, [allGoals])

  return (
    <Page className='goals-history' title='Goals' isLoading={isFetchingGoals}>
      <div className='user_goals'>
        <IonList>
          <IonListHeader>
            <h2>Active</h2>
          </IonListHeader>
        </IonList>
        {isFetchingGoals ? (
          <SkeletonList className='sparse' />
        ) : (
          <GoalList goals={active} />
        )}

        <>
          <IonList>
            <IonListHeader>
              <h2>Archived</h2>
            </IonListHeader>
          </IonList>
          <GoalList goals={completed} />
        </>
      </div>
    </Page>
  )
}
