import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'

import { AUTH_TOKEN_KEY, DOMAIN } from '../settings'

const app = firebase.initializeApp({
  apiKey: 'AIzaSyCO-W2pflWm2drX53ecdb0rWjSJKlade9g',
  authDomain: DOMAIN,
  projectId: 'straight-www',
  storageBucket: 'straight-www.appspot.com',
  messagingSenderId: '983384608547',
  appId: '1:983384608547:web:9eb19a5a8ef73750ad8696',
  measurementId: 'G-2Y8TQ2DJLW',
})

export default firebase
export const analytics = firebase.analytics()

function cleanUpSession() {
  localStorage.removeItem(AUTH_TOKEN_KEY)
  localStorage.removeItem('user')
  analytics.logEvent('logout')
}

export async function signOut() {
  return firebase.auth().signOut().then(cleanUpSession)
}

app.auth().onAuthStateChanged(async (user) => {
  if (user) {
    analytics.setUserId(user.uid)
    const {
      uid,
      email,
      displayName,
      phoneNumber,
      isAnonymous,
      emailVerified,
      photoURL,
    } = user
    await getAuthToken()
    localStorage.setItem(
      'user',
      JSON.stringify({
        uid,
        displayName,
        email,
        phoneNumber,
        isAnonymous,
        photoURL,
        emailVerified,
      })
    )
    analytics.logEvent('login')
  } else {
    cleanUpSession()
  }
})

let pendingRefreshToken: Promise<string> | undefined

export function getAuthToken(forceRefresh?: boolean): Promise<string> {
  if (pendingRefreshToken) return pendingRefreshToken
  let { currentUser } = firebase.auth(app)
  if (currentUser) {
    pendingRefreshToken = currentUser.getIdToken(forceRefresh).then((token) => {
      pendingRefreshToken = undefined
      localStorage.setItem(AUTH_TOKEN_KEY, token)
      return token
    })
    return pendingRefreshToken
  }
  return Promise.resolve('')
}
