/**
 * Creates an iterator to chain the values of multiple arrays together in sequence.
 */
export function chain(...list: any[][]) {
  return {
    listIndex: 0,
    currentIndex: 0,

    [Symbol.iterator]() {
      return this
    },

    get current() {
      return list[this.listIndex]
    },

    nextList() {
      // Advance to the beginning of next list
      this.listIndex++
      this.currentIndex = 0
      return this.current
    },

    next() {
      let arr = this.current
      // Check if we are done with current list
      while (!arr || this.currentIndex >= arr.length) {
        // when current list is last one, we're done.
        if (this.listIndex >= list.length) return { done: true }
        arr = this.nextList()
      }
      // move on to next item of current list
      return {
        done: false,
        value: arr[this.currentIndex++],
      }
    },
  }
}

/**
 * Creates an iterator to apply lazy operations to an array.
 */
export function Sequence(list: any[]) {
  return {
    currentIndex: 0,
    // compose mapping functions into one
    _mapStep: (x: any) => x,
    // adds filters to a list for later checking
    _filters: [] as Function[],
    // indicates if we are processing in order or reverse order
    _step: 1,

    [Symbol.iterator]() {
      return this
    },

    /** Returns the current item and advances to next index */
    seek() {
      let value = list[this.currentIndex]
      this.currentIndex += this._step
      return value
    },

    /** Change the ordering direction */
    reverse() {
      this._step *= -1
    },

    next(): any {
      if (this.currentIndex >= list.length) return { done: true }
      let value = this.seek()
      if (this._filters.some((fn) => !fn(value))) {
        return this.next()
      }
      return {
        done: false,
        value: this._mapStep(value),
      }
    },

    filter(predicate: (x: any) => boolean) {
      this._filters.push(predicate)
      return this
    },

    map(fn: Function) {
      this._mapStep = compose(this._mapStep, fn)
      return this
    },

    first() {
      this.currentIndex = 0
      let result = this.next()
      return result.value
    },

    toArray() {
      return Array.from(this)
    },
  }
}

function compose(a: Function, b: Function) {
  return (...args: any) => b(a(...args))
}
