import React, { ReactNode } from 'react'
import fmtDate from 'date-fns/format'
import { memoize } from 'lodash'

export const memoDateFormats = memoize((value, format) => {
  return fmtDate(new Date(value.toString().replace('Z', '')), format)
})

// See https://date-fns.org/v2.25.0/docs/format
export function DateTime({
  value,
  format = 'PP',
  placeholder,
}: {
  value?: string | number | Date
  format?: string
  placeholder?: ReactNode
}) {
  if (!value) return <>{placeholder!}</>
  return <>{memoDateFormats(value, format)}</>
}
