import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateCustomer } from '../../lib/api'

export interface CustomerRequest {
  account_id: string
}

export function useCustomerMutation({ account_id }: CustomerRequest) {
  const queryClient = useQueryClient()
  const queryKey = ['customer', account_id]

  // Optimistic update example:
  const putCustomer = useMutation(updateCustomer, {
    onMutate: async (nextState) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey)

      // Snapshot the previous value
      const prevState = queryClient.getQueryData(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, nextState)

      // Return a context object with the snapshotted value
      return { prevState, nextState }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, nextState, context: any) => {
      queryClient.setQueryData(queryKey, context.prevState)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })

  return { putCustomer }
}
