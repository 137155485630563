import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
} from '@ionic/react'
import { useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import FirebaseAuth from '../../components/FirebaseAuth'
import { store } from '../../lib/store'
import { getUrlQuery } from '../../lib/utils'
import logoImg from './icon.png'
import './login.css'

type LoginPageProps = {}

export default function LoginPage(props: RouteComponentProps<LoginPageProps>) {
  const user = store.useState((s) => s.user)
  const [path] = useState(getUrlQuery().get('path') ?? '/goals')
  if (user) return <Redirect to={path} />
  return (
    <IonPage>
      {!isInstalled() && <InstallBanner />}
      <IonContent>
        <FirebaseAuth />
      </IonContent>
    </IonPage>
  )
}

function InstallBanner() {
  return (
    <IonHeader>
      <IonToolbar>
        <IonItem slot='start' lines='none'>
          <IonAvatar slot='start'>
            <img src={logoImg} alt='ClearMoney' />
          </IonAvatar>
          <IonLabel>
            <b className='text-small'>ClearMoney</b>
            <br />
            Add to Home screen
          </IonLabel>
        </IonItem>
        <IonButtons slot='end'>
          <IonButton
            className='progressier-install-button'
            data-icons='false'
            color='primary'
            fill='solid'
            style={{ minWidth: 64, marginRight: 10 }}
            size='large'
          >
            Install
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}

enum Platform {
  'android',
  'standalone',
  'browser',
}

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches
  if (document.referrer.startsWith('android-app://')) {
    return Platform.android
    // @ts-ignore
  } else if (navigator.standalone || isStandalone) {
    return Platform.standalone
  }
  return Platform.browser
}

function isInstalled() {
  let currentMode = getPWADisplayMode()
  return [Platform.android, Platform.standalone].includes(currentMode)
}
