import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { add, formatDistanceToNowStrict } from 'date-fns'
import { chevronBackOutline, chevronDown } from 'ionicons/icons'
import { filter } from 'lodash'
import { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import IconButton from '../../components/IconButton'
import {
  TransactionItem,
  TransactionListSkeleton,
  TransactionsList,
  TransactionsSection,
} from '../../components/transactions/TransactionsList'
import { useHistorical } from '../../hooks/loaders/useHistorical'
import { Day } from '../../lib/dates'
// import { store } from '../../lib/store'
import { toLocaleDate } from '../../lib/transactions'
import { Page } from '../Page'

interface TransactionsPageProps extends RouteComponentProps<{}> {
  presentingElement: any
}

export default function TransactionsPage({
  presentingElement,
}: TransactionsPageProps) {
  const [searchText, setSearchText] = useState('')

  return (
    <Page
      id='transactions'
      start={
        <IonSearchbar
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          placeholder='Search transactions'
        />
      }
    >
      {!searchText && (
        <FutureTransactions presentingElement={presentingElement} />
      )}
      <TransactionsList search={searchText?.trim()} />
    </Page>
  )
}

function FutureTransactions({
  presentingElement,
}: {
  presentingElement?: HTMLElement
}) {
  const { isLoading, futures } = useHistorical()
  const [isOpen, setIsOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
    setIsExpanded(false)
  }

  let hideDate = add(Day(), { weeks: 2 })
  let previewList = futures.slice(0, 2)

  const before = useMemo(() => {
    return filter(futures, (t) => toLocaleDate(t.date) <= hideDate)
  }, [futures, hideDate])

  const later = useMemo(() => {
    return filter(futures, (t) => toLocaleDate(t.date) > hideDate)
  }, [futures, hideDate])

  const moreTransactions = useMemo(() => {
    if (futures.length < 2) return null
    return (
      <>
        <ShowMoreItem onClick={() => setIsOpen(true)} />
        <IonModal
          isOpen={isOpen}
          onDidDismiss={() => setIsOpen(false)}
          canDismiss
          // presentingElement={presentingElement}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IconButton
                  color='primary'
                  icon={chevronBackOutline}
                  onClick={closeModal}
                />
              </IonButtons>
              <IonTitle>Coming soon</IonTitle>
              {/* <IonButtons slot="end">
                <IconButton icon={close} onClick={closeModal} />
              </IonButtons> */}
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <TransactionsSection
              data={before}
              label={`Due in ${formatDistanceToNowStrict(
                new Date(before[0]?.date ?? new Date()),
                {
                  unit: 'day',
                  roundingMethod: 'ceil',
                }
              )}`}
            />
            <TransactionsSection data={later} label='Two weeks from now' />
          </IonContent>
        </IonModal>
      </>
    )
  }, [futures, isOpen, isExpanded, before, later])

  if (isLoading) return <TransactionListSkeleton label='Coming soon' size={3} />
  if (!futures.length) return null

  return (
    <IonList lines='none'>
      <IonListHeader lines='none'>Coming soon</IonListHeader>
      {previewList.map((x) => (
        <TransactionItem onClick={() => setIsOpen(true)} tx={x} />
      ))}
      {moreTransactions}
    </IonList>
  )
}

function ShowMoreItem({
  onClick,
  label,
  iconStart,
  iconEnd,
  detail,
}: {
  onClick: VoidFunction
  iconStart?: string
  iconEnd?: string
  label?: string
  detail?: boolean
}) {
  return (
    <IonItem onClick={onClick} detail={detail} lines='none'>
      <IonAvatar
        className='transaction_avatar flex center align-center'
        slot='start'
      >
        <IonIcon color='medium' icon={iconStart ?? chevronDown} />
      </IonAvatar>
      <IonLabel className='label_action primary'>
        <div className='flex align-center'>
          <h3>{label ?? 'Show More'}</h3>
          {iconEnd && <IonIcon icon={iconEnd} />}
        </div>
      </IonLabel>
    </IonItem>
  )
}
