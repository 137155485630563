import {
  IonAvatar,
  IonBadge,
  IonButtons,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonToast,
} from '@ionic/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { add, format, isSameMonth, startOfMonth, sub } from 'date-fns'
import {
  calendarOutline,
  cardOutline,
  cashOutline,
  checkmarkCircle,
  chevronBack,
  chevronBackOutline,
  chevronDownCircle,
  chevronForward,
  chevronUpCircle,
  documentTextOutline,
  ellipsisHorizontalCircleOutline,
  eyeOffOutline,
  flagOutline,
  pieChartOutline,
  repeatSharp,
  shuffleSharp,
} from 'ionicons/icons'
import {
  assign,
  debounce,
  flatMap,
  groupBy,
  map,
  startCase,
  times,
} from 'lodash'
import React, {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { useCategories } from '../../hooks/loaders/useCategories'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useMutateTransaction } from '../../hooks/useFetch'
import { requestNextTransactions, updateAllTransactions } from '../../lib/api'
import { Day } from '../../lib/dates'
import { store } from '../../lib/store'
import { merchantName } from '../../lib/strings'
import { getDirection, toLocaleDate } from '../../lib/transactions'
import { TCategories, TProfile, TTransaction } from '../../lib/types'
import { SkeletonItem } from '../../pages/Page'
import { NumberInput } from '../AmountInput'
import { UserAvatar } from '../avatars/UserAvatar'
import { DatePicker } from '../DatePicker/DatePicker'
import { DateTime } from '../DateTime'
import IconButton from '../IconButton'
import { Currency } from '../Numeral'
import { categoryIcons, getCategoryIcon, getMerchantLogo } from './constants'
import './transactions.scss'

export const TransactionsContext = React.createContext<{
  categories: TCategories
  transactions: TTransaction[]
}>({
  categories: {},
  transactions: [],
})

export function hash(x: TTransaction) {
  return (
    x.id +
    x.label +
    x.amount +
    x.date +
    x.subcategory +
    x.isrecurring +
    x.istransfer +
    x.hidden
  )
}

export const TransactionItem = ({
  tx,
  onClick,
  avatar,
}: {
  tx: TTransaction
  onClick: any
  avatar?: TProfile
}) => {
  if (tx.hidden) return null
  return (
    <IonItem className='transaction-item' onClick={onClick}>
      {avatar ? (
        <UserAvatar
          photoURL={avatar.photo_url}
          displayName={avatar.display_name}
        />
      ) : (
        <IonAvatar
          className='transaction_avatar'
          slot='start'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'gainsboro',
            color: 'white',
            fontWeight: 900,
          }}
        >
          {getMerchantLogo(tx) ?? getCategoryIcon(tx)}
        </IonAvatar>
      )}
      <IonLabel className='tx-name'>
        <h2>{merchantName(tx)}</h2>
        <p>
          <DateTime value={tx.date} format='MMMM d' />
        </p>
      </IonLabel>
      <IonLabel
        className='text-right'
        color={tx.istransfer ? 'medium' : tx.amount < 0 ? 'success' : 'default'}
        slot='end'
        style={{ marginBottom: '0.25em' }}
      >
        <Currency value={tx.amount} abs={true} />
        <div
          style={{
            marginTop: 4,
            visibility: tx.isrecurring ? 'initial' : 'hidden',
          }}
        >
          <IonIcon icon={repeatSharp} color='medium' />
        </div>
      </IonLabel>
    </IonItem>
  )
}

export const TransactionsSection = ({
  data,
  label,
  className,
  isLoading,
  onClick,
  endSlot,
  users,
  readOnly,
}: {
  data?: TTransaction[]
  label?: string
  className?: string
  isLoading?: boolean
  onClick?: (tx: TTransaction) => any
  endSlot?: ReactNode
  readOnly?: boolean
  users?: TProfile[]
}) => {
  const user = store.useState((s) => s.user)
  const [detail, setDetail] = useState<TTransaction | null>(null)

  const detailModal = useMemo(() => {
    return (
      <TransactionDetailsModal
        isOpen={!!detail}
        transaction={detail}
        onDismiss={() => setDetail(null)}
      />
    )
  }, [detail])

  const avatars = useMemo(() => {
    let map = new Map(users?.map((u) => [u.user_id, u]))
    return map
  }, [users])

  if (isLoading) {
    return <TransactionListSkeleton label={label} />
  }

  return (
    <>
      {detailModal}
      <IonList className={`transaction-list ${className}`} lines='none'>
        {label && (
          <IonListHeader lines='none'>
            <IonLabel>{label}</IonLabel>
          </IonListHeader>
        )}

        {data?.map((x, i) => (
          <TransactionItem
            // key={x.transaction_id}
            tx={x}
            avatar={avatars?.get(x.user_id)}
            onClick={() => {
              if (readOnly || x.user_id !== user?.uid) return
              setDetail(assign(x, { direction: getDirection(x) }))
              if (onClick) onClick(x)
            }}
          />
        ))}

        {!data?.length && (
          <IonItem>
            <IonLabel color='medium'>Nothing new.</IonLabel>
          </IonItem>
        )}
        {endSlot}
      </IonList>
    </>
  )
}

export const TransactionDetailsModal = ({
  transaction,
  onDismiss,
  onUpdate,
  isOpen,
  presentingElement,
}: {
  transaction: TTransaction | null
  isOpen?: boolean
  onDismiss: () => any
  onUpdate?: VoidFunction
  presentingElement?: HTMLElement
}) => {
  // const rootElement = store.useState((s) => s.presentingElement)
  const [isLoading, setLoading] = useState(false)
  const { patchTransaction, deleteTransaction } = useMutateTransaction({
    onUpdate,
  })
  const [showActions] = useIonActionSheet()
  const [alert] = useIonAlert()
  const isEmpty = !transaction
  return (
    <IonModal
      isOpen={!isEmpty}
      onDidDismiss={onDismiss}
      canDismiss
      // presentingElement={presentingElement ?? rootElement}
    >
      <IonContent>
        <IonLoading isOpen={isLoading} duration={10000} message='Deleting...' />
        <IonToolbar>
          <IonButtons slot='start'>
            <IconButton
              color='primary'
              icon={chevronBackOutline}
              onClick={() => onDismiss()}
            />
          </IonButtons>
          <IonButtons slot='end'>
            <IconButton
              icon={ellipsisHorizontalCircleOutline}
              color='dark'
              onClick={() =>
                showActions(
                  [
                    { text: 'Cancel' },
                    {
                      text: 'Hide',
                      handler: () =>
                        alert({
                          header: 'Hide Transaction',
                          message:
                            'Are you sure you want to hide this transaction?',
                          buttons: [
                            'No',
                            {
                              text: 'Yes',
                              handler: () =>
                                patchTransaction.mutateAsync({ hidden: 1 }),
                            },
                          ],
                        }),
                    },
                    {
                      text: 'Delete',
                      cssClass: 'text-danger',
                      handler: () =>
                        alert({
                          header: 'Delete Transaction',
                          message:
                            'Are you sure you want to delete this transaction?',
                          buttons: [
                            'No',
                            {
                              text: 'Yes',
                              handler: () => {
                                setLoading(true)
                                deleteTransaction.mutate(transaction!, {
                                  onSuccess: onDismiss,
                                  onSettled: () => setLoading(false),
                                })
                              },
                            },
                          ],
                        }),
                    },
                  ],
                  'Actions'
                )
              }
            />
          </IonButtons>
        </IonToolbar>
        {transaction && (
          <TransactionDetails
            tx={transaction}
            onChange={patchTransaction.mutate}
            presentingElement={presentingElement}
          />
        )}
      </IonContent>
    </IonModal>
  )
}

export const MonthlyTransactions = ({
  showMonths = true,
}: {
  showMonths?: boolean
}) => {
  const { transactions } = useContext(TransactionsContext)

  const months = groupBy(transactions, (t) =>
    format(toLocaleDate(t.date), 'MMMM yyyy')
  )

  return (
    <>
      {map(months, (data, key) => (
        <TransactionsSection
          key={key}
          data={data}
          label={showMonths ? key : undefined}
        />
      ))}
    </>
  )
}

export const CategoryAccordion = ({
  data,
  currentValue,
  onSelect,
}: {
  data: any
  currentValue: any
  onSelect: Function
}) => {
  const { category, items } = data
  const [isExpanded, setExpanded] = useState(items.includes(currentValue))
  const toggle = () => setExpanded(!isExpanded)
  return (
    <IonList key={category} className='sparse'>
      <IonItem className='category_main' lines='full' onClick={toggle}>
        <IonLabel>
          <span className='category_icon'>{categoryIcons[category]}</span>{' '}
          <span className='category_name'>{category}</span>
        </IonLabel>
        <IonIcon
          slot='end'
          color='medium'
          icon={isExpanded ? chevronUpCircle : chevronDownCircle}
        />
      </IonItem>
      <div className='category_list' data-visible={isExpanded}>
        {map(items, (subcategory) => (
          <IonItem
            key={subcategory}
            onClick={() => {
              if (onSelect) onSelect({ category, subcategory })
            }}
          >
            <IonLabel>{subcategory}</IonLabel>
            {subcategory === currentValue && (
              <IonIcon slot='end' icon={checkmarkCircle} />
            )}
          </IonItem>
        ))}
      </div>
    </IonList>
  )
}

export const CategoryPicker = ({
  value,
  categories,
  presentingElement,
  isOpen,
  onDismiss,
  onClick,
}: {
  value?: string
  categories: TCategories
  presentingElement?: HTMLElement
  isOpen: boolean
  onDismiss: () => any
  onClick: ({
    category,
    subcategory,
  }: {
    category: string
    subcategory: string
  }) => any
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  const items = useMemo(
    () =>
      map(categories, (data, key) => (
        <CategoryAccordion
          key={key}
          data={data}
          currentValue={currentValue}
          onSelect={({ category, subcategory }: any) => {
            setCurrentValue(subcategory)
            onClick({ category, subcategory })
          }}
        />
      )),
    [categories, currentValue, onClick]
  )
  const [showActions] = useIonActionSheet()
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} backdropDismiss>
      <IonToolbar>
        <IonTitle>Select Category</IonTitle>
        <IonButtons slot='start'>
          <IconButton
            color='primary'
            icon={chevronBackOutline}
            onClick={onDismiss}
          />
        </IonButtons>
      </IonToolbar>
      <IonContent>{items}</IonContent>
    </IonModal>
  )
}

export const TransactionDetails = ({
  tx,
  onChange,
  presentingElement,
}: {
  tx: TTransaction
  onChange?: (data: any) => any
  presentingElement?: HTMLElement
}) => {
  const { activeGoals } = useGoals()
  const { categories } = useCategories()
  const { accounts } = useBankAccounts({
    account_id: tx.account_id,
    include: 'institution',
  })

  const account = accounts && accounts[0]

  const [present, dismiss] = useIonToast()
  const [showCategories, setShowCategories] = useState(false)

  const setValue = useCallback(
    debounce((update: any) => {
      assign(tx, update)
      if (onChange) {
        onChange({ id: tx.id, ...update, account_id: tx.account_id })
        let isMany = [
          'label',
          'istransfer',
          'isrecurring',
          'hidden',
          'notes',
          'goal_id',
        ].some((key) => key in update)
        if (isMany) {
          updateMany(tx, update)
        }
      }
    }, 1000),
    []
  )

  // Show option to update many
  const updateMany = (tx: TTransaction, data: any) => {
    if (tx.keywords) {
      present({
        buttons: [
          { text: 'No', handler: () => dismiss() },
          {
            text: 'Yes',
            handler: () => {
              updateAllTransactions(
                {
                  account_id: tx.account_id,
                  keywords: tx.keywords,
                  merchant_name: tx.merchant_name,
                  direction: getDirection(tx),
                },
                data
              )
              dismiss()
            },
          },
        ],
        message: 'Apply to similar transactions?',
        duration: 5000,
        position: 'bottom',
      })
    }
  }
  let displayName = merchantName(tx)
  let direction = tx.direction || getDirection(tx)
  return (
    <>
      <CategoryPicker
        value={tx.subcategory}
        isOpen={showCategories}
        categories={categories}
        presentingElement={presentingElement}
        onClick={({ category, subcategory }) => {
          assign(tx, { category, subcategory })
          if (onChange) {
            onChange({ id: tx.id, category, subcategory })
            updateMany(tx, { category, subcategory })
          }
        }}
        onDismiss={() => setShowCategories(false)}
      />

      <div className='detail_header ion-margin-top'>
        <IonAvatar className='detail_avatar'>
          {getMerchantLogo(tx) ?? displayName[0]}
        </IonAvatar>
        <h4>{displayName}</h4>
        <h2>
          <Currency value={tx.amount} abs />
        </h2>
        <div>{tx.pending && <IonBadge color='medium'>Pending</IonBadge>}</div>
      </div>

      <IonToolbar className='toolbar-segment'>
        <IonSegment
          onIonChange={(e) => {
            let direction = e.detail.value
            let update = {
              direction,
              amount: direction === 'debit' ? -tx.amount : tx.amount,
              subcategory: startCase(e.detail.value),
            }
            setValue(update)
            assign(tx, update)
          }}
          value={tx.direction}
          defaultValue={direction}
        >
          <IonSegmentButton value='debit'>
            <IonLabel>Income</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='credit'>
            <IonLabel>Expense</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      <IonList lines='none'>
        <IonItem key='amount'>
          <IonIcon size='small' slot='start' icon={cashOutline} />
          <IonLabel position='fixed'>Amount</IonLabel>
          <NumberInput
            value={Math.abs(tx.amount)}
            placeholder='$20'
            onChange={(amount) => setValue({ amount })}
            style={{ fontSize: 20, marginRight: 10 }}
            rightAlign
          />
        </IonItem>

        <IonItem>
          <IonIcon size='small' slot='start' icon={calendarOutline} />
          <DatePicker
            onChange={(date) => setValue({ date })}
            value={tx.date}
            format='MMMM d'
            placeholder='Date'
          />
        </IonItem>

        <IonItem key='label'>
          <IonIcon size='small' slot='start' icon={cardOutline} />
          <IonLabel position='stacked'>Description</IonLabel>
          <IonInput
            value={tx.label ?? merchantName(tx)}
            placeholder={tx.name}
            onIonChange={(e) => {
              setValue({ label: e.detail.value })
            }}
          />
        </IonItem>

        {account && (
          <IonItem key='account'>
            <IonIcon size='small' slot='start' icon={cardOutline} />
            <IonInput readonly value={account.official_name ?? account.name} />
          </IonItem>
        )}

        {activeGoals && (
          <IonItem>
            <IonIcon size='small' slot='start' icon={flagOutline} />
            <IonLabel position='stacked'>This is for</IonLabel>
            <IonSelect
              interface='alert'
              interfaceOptions={{ header: 'Select goal' }}
              placeholder='Not Assigned'
              onIonChange={(e) => {
                setValue({ goal_id: e.detail.value })
              }}
              value={tx.goal_id}
            >
              <IonSelectOption value={null}>Not Assigned</IonSelectOption>
              {map(activeGoals, (a) => {
                return (
                  <IonSelectOption value={a.id} key={a.id}>
                    {a.name}
                  </IonSelectOption>
                )
              })}
            </IonSelect>
          </IonItem>
        )}
        <IonItem onClick={() => setShowCategories(true)}>
          <IonIcon size='small' slot='start' icon={pieChartOutline} />
          <IonLabel>
            <span style={{ marginRight: 8 }}>{getCategoryIcon(tx)}</span>{' '}
            {tx.subcategory ?? tx.category ?? 'Unknown'}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonIcon size='small' slot='start' icon={repeatSharp} />
          <IonLabel>Recurring</IonLabel>
          <IonSelect
            placeholder='Select'
            slot='end'
            interface='alert'
            onIonChange={(e) => {
              let value = e.detail.value
              if (value != null) value = parseInt(value)
              setValue({ isrecurring: value })
            }}
            value={tx.isrecurring}
          >
            <IonSelectOption value={null}>None</IonSelectOption>
            <IonSelectOption value={10}>Weekly</IonSelectOption>
            <IonSelectOption value={20}>Bi Weekly</IonSelectOption>
            <IonSelectOption value={100}>Monthly</IonSelectOption>
            <IonSelectOption value={300}>Quarterly</IonSelectOption>
            <IonSelectOption value={600}>Every 6 months</IonSelectOption>
            <IonSelectOption value={1000}>Yearly</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonIcon size='small' slot='start' icon={shuffleSharp} />
          <IonLabel>Transfer</IonLabel>
          <IonToggle
            slot='end'
            color='primary'
            onIonChange={(e) => {
              let value = e.detail.checked ? 1 : null
              setValue({ istransfer: value })
            }}
            checked={tx.istransfer != null}
          />
        </IonItem>
        <IonItem>
          <IonIcon size='small' slot='start' icon={eyeOffOutline} />
          <IonLabel>Hide</IonLabel>
          <IonToggle
            slot='end'
            color='primary'
            onIonChange={(e) => {
              let value = e.detail.checked ? 1 : null
              setValue({ hidden: value })
            }}
            checked={tx.hidden != null}
          />
        </IonItem>
        <IonItem>
          <IonIcon size='small' slot='start' icon={documentTextOutline} />
          <IonLabel position='stacked'>Notes</IonLabel>
          <IonTextarea
            value={tx.notes}
            placeholder='Notes'
            onIonChange={(e) => {
              let value = e.detail.value
              setValue({ notes: value })
            }}
            maxlength={280}
          />
        </IonItem>
      </IonList>
    </>
  )
}

export function TransactionsList({
  accountId,
  search,
  period,
  showMonths,
  noResults,
}: {
  accountId?: string
  search?: string
  period?: string
  showMonths?: boolean
  noResults?: ReactElement
}) {
  const { categories } = useCategories()
  const {
    data,
    fetchNextPage: getNext,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['transactions', { accountId, search, period }],
    (context) =>
      requestNextTransactions({
        url: context.pageParam || '/transactions/',
        params: { account_id: accountId, search, period },
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next
      },
      staleTime: 30 * 1000, // 30s
    }
  )

  let transactions = flatMap(data?.pages, (x) => x.data)
  let isInfiniteDisabled = !hasNextPage

  // const [isFetching, setIsFetching] = useState(false)
  // const [cursor, setCursor] = useState<string | undefined>()
  // const [data, setData] = useState<TTransaction[]>([])
  // const [isInfiniteDisabled, setInfiniteDisabled] = useState(false)

  const fetchNextPage = async (ev: any) => {
    try {
      if (hasNextPage) await getNext()
    } finally {
      ev?.target?.complete()
    }
  }

  // reload when the query params change
  useEffect(() => {
    fetchNextPage(null)
  }, [accountId, period, search])

  const isEmpty = !transactions.length

  if (isFetching && isEmpty) {
    return <TransactionListSkeleton />
  } else if (isEmpty) {
    return noResults ?? null
  }

  return (
    <TransactionsContext.Provider
      value={{
        transactions,
        categories: categories ?? {},
      }}
    >
      <MonthlyTransactions showMonths={showMonths} />
      <IonInfiniteScroll
        onIonInfinite={fetchNextPage}
        disabled={isFetching || isInfiniteDisabled}
        threshold='50%'
      >
        <IonInfiniteScrollContent loadingSpinner='dots'></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </TransactionsContext.Provider>
  )
}

export function TransactionsPeriod({ accountId }: { accountId?: string }) {
  const currentMonth = startOfMonth(Day())
  const minPeriod = sub(currentMonth, { months: 23 })
  const [date, setDate] = useState(currentMonth)
  const prev = () => setDate(sub(date, { months: 1 }))
  const next = () => {
    let nextMonth = add(date, { months: 1 })
    if (nextMonth < new Date()) {
      setDate(nextMonth)
    }
  }
  return (
    <>
      <IonToolbar>
        <IonButtons slot='start'>
          <IconButton
            icon={chevronBack}
            disabled={date === minPeriod}
            onClick={prev}
          />
        </IonButtons>
        <div className='ion-text-center'>{format(date, 'MMMM yyyy')}</div>
        <IonButtons slot='end'>
          <IconButton
            icon={chevronForward}
            onClick={next}
            disabled={isSameMonth(date, currentMonth)}
          />
        </IonButtons>
      </IonToolbar>
      <TransactionsList
        accountId={accountId}
        period={format(date, 'MMMM yyyy')}
        showMonths={false}
        noResults={
          <IonList lines='none'>
            <IonItem>
              <IonLabel className='ion-text-center' color='medium'>
                No recent transactions.
              </IonLabel>
            </IonItem>
          </IonList>
        }
      />
    </>
  )
}

export const TransactionListSkeleton = memo(
  ({ size = 12, label }: { size?: number; label?: string }) => (
    <IonList>
      {label && (
        <IonListHeader>
          <IonLabel>{label}</IonLabel>
        </IonListHeader>
      )}
      {times(size, (i) => (
        <SkeletonItem key={i} />
      ))}
    </IonList>
  )
)
