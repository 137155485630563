export function Day() {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

export function splitDate(dateStr: string) {
  return dateStr.slice(0, 10)
}

export const compareDates = (a: Date, b: Date) => a.getTime() - b.getTime()

export const datesAsc = (a: Date, b: Date) => compareDates(a, b)

export const datesDesc = (a: Date, b: Date) => -1 * compareDates(a, b)

export function minDate(...rest: Date[]) {
  rest.sort(compareDates)
  return rest[0]
}

export function maxDate(...rest: Date[]) {
  rest.sort(compareDates)
  return rest[rest.length - 1]
}

export function isMorning(date: Date) {
  let hours = date.getHours()
  if (hours >= 6 && hours < 12) return true
}

export function isAfternoon(date: Date) {
  let hours = date.getHours()
  if (hours >= 12 && hours < 19) return true
}

export function isNight(date: Date) {
  let hours = date.getHours()
  if (hours >= 19 || hours < 6) return true
}
