import { entries } from 'lodash'
import qs from 'qs'

export function queryString(obj: any) {
  return qs.stringify(obj, {
    skipNulls: true,
    addQueryPrefix: true,
  })
}

export function getUrlQuery(query?: string): Map<string, any> {
  let obj = qs.parse(query ?? window.location.search, {
    ignoreQueryPrefix: true,
  })
  return new Map(entries(obj))
}

export const copyToClipboard = async (data: string) => {
  try {
    await navigator.clipboard.writeText(data)
  } catch (error) {
    console.error(error)
  }
}

export const shareDialog = function (data: ShareData, cb?: VoidFunction) {
  if (navigator.share) {
    return navigator.share(data)
  } else {
    cb?.() // fallback
  }
}

export function amortizedLoan(
  amount: number,
  rate: number, // interest rate percent (ex 3.25)
  months: number,
  down = 0,
  salesTax = 6, // sales tax percent (ex 7.08)
  fees = 0
) {
  salesTax /= 100
  let principal = amount * (1 + salesTax) + fees - down
  if (!rate)
    return {
      monthly: principal / months,
      principal,
      totalInterest: 0,
      fees: amount * salesTax + fees,
      totalCost: principal + down,
    }

  let APR = rate / 100
  let r = APR / 12
  let n = months
  let e = (1 + r) ** n
  let monthly = principal * ((r * e) / (e - 1))

  // Amortization schedule
  let balance = principal
  let totalInterest = 0
  for (let i = 0; i < n && balance > 0; i++) {
    let interest = balance * r
    totalInterest += interest
    balance -= monthly - interest
  }

  return {
    monthly,
    principal,
    totalInterest,
    fees: amount * salesTax + fees,
    totalCost: principal + down + totalInterest,
  }
}

export function carLoanCalc(
  amount: number,
  rate: number, // interest rate percent (ex 3.25)
  months: number,
  down = 0,
  trade = 0,
  salesTax = 7 // sales tax percent (ex 7.08)
) {
  down = down || 0
  trade = trade || 0

  if (!amount || !months) {
    return null
  }

  return amortizedLoan(amount - trade, rate, months, down, salesTax, 400)
}

// Home buyer transfer taxes = Price/100 * 0.7
export function homeLoanCalc(
  amount: number,
  rate: number, // interest rate percent (ex 3.25)
  months: number,
  down = 0,
  salesTax = 0.7,
  realEstateTaxRate = 1.08,
  hoa = 0
) {
  down = down ?? 0

  if (!amount || !rate || !months) {
    return null
  }

  let is20Down = down / amount >= 0.2
  let principal = amount * (1 + salesTax / 100) - down
  // Note: estimates PMI at 0.85%
  let PMI = is20Down ? ((0.85 / 100) * principal) / months : 0
  let APR = rate / 100
  let r = APR / 12
  let totalInterest = 0
  let n = months
  let e = (1 + r) ** n
  let monthly = principal * ((r * e) / (e - 1))
  let balance = principal
  let totalTaxes = ((amount * (realEstateTaxRate / 100)) / 12) * n
  let monthlyTax = totalTaxes / n

  let totalPMI = 0
  for (let i = 0; i < n && balance > 0; i++) {
    // monthly interest
    let interest = balance * r
    totalInterest += interest
    // monthly principal
    balance -= monthly - interest
    // Add any PMI that needs to be paid while the balance is over 80%
    if (balance / amount > 0.8) {
      totalPMI += PMI
    }
  }

  return {
    monthly: monthly + monthlyTax + (hoa || 0) + PMI,
    principal,
    totalInterest,
    fees: (amount * salesTax) / 100 + totalTaxes + totalPMI,
    totalCost: principal + down + totalInterest,
  }
}

// http://isthe.com/chongo/tech/comp/fnv/
export function hashCode(str: string) {
  var FNV1_32A_INIT = 0x811c9dc5
  var hval = FNV1_32A_INIT
  for (var i = 0; i < str.length; ++i) {
    hval ^= str.charCodeAt(i)
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
  }
  return hval >>> 0
}

export function delay(timeout: number) {
  return new Promise<void>((resolve, reject) => {
    setTimeout(resolve, timeout)
  })
}
