import {
  IonButton,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { AmountInput } from '../../components/AmountInput'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import { useProfile } from '../../hooks/loaders/useProfile'
import { client } from '../../lib/api'
import { TProfile } from '../../lib/types'
import { Page } from '../Page'

export default function PersonalInfo({ history }: RouteComponentProps<any>) {
  const { profile, isLoadingProfile } = useProfile()

  const [values, setValues] = useState<any & TProfile>()
  const [toast] = useIonToast()

  const mutate = useCallback(
    debounce(
      async (
        update: any,
        message = 'Something went wrong, please try again'
      ) => {
        try {
          await client.post('/user/profile/', { update })
        } catch (error) {
          toast({
            message,
            icon: alertCircle,
          })
        }
      },
      1000
    ),
    []
  )

  const update = async (update: any) => {
    let fields = Object.keys(update)
    let isUpdate = fields.some((x) => values && update[x] != values[x])
    if (!isUpdate) return
    setValues({ ...values, ...update })
    mutate(update)
  }

  const update_user_name = useCallback(
    (user_name: string) => {
      setValues({ ...values, user_name })
      mutate({ user_name }, 'Sorry, please try a different username')
    },
    [values, mutate]
  )

  useEffect(() => {
    if (profile) setValues(profile)
  }, [profile])

  return (
    <Page
      title='Personal'
      isLoading={isLoadingProfile}
      footer={
        <IonFooter className='ion-margin-top'>
          <IonToolbar>
            <IonButton expand='block' onClick={() => history.goBack()}>
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <>
        <IonList className='ion-margin-bottom'>
          <IonListHeader>Account</IonListHeader>

          <IonItem>
            <IonLabel position='fixed'>Username</IonLabel>
            <IonInput
              value={values?.user_name}
              placeholder={profile?.user_name}
              minlength={4}
              maxlength={25}
              onIonChange={(e) => {
                let user_name = e.detail.value
                if (user_name && user_name.trim().length > 3) {
                  update_user_name(user_name.trim())
                }
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='fixed'>First name</IonLabel>
            <IonInput
              value={values?.first_name}
              onIonChange={(e) => {
                update({ first_name: e.detail.value })
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='fixed'>Last name</IonLabel>
            <IonInput
              value={values?.last_name}
              onIonChange={(e) => {
                update({ last_name: e.detail.value })
              }}
            />
          </IonItem>

          <IonItem lines='full'>
            <IonLabel position='fixed'>Birthday</IonLabel>
            <DatePicker
              format='MMMM d yyyy'
              onChange={(birth_date) => update({ birth_date })}
              value={values?.birth_date}
            />
          </IonItem>
        </IonList>

        <IonList className='ion-margin-bottom'>
          <IonItem>
            <IonLabel position='stacked'>Home address</IonLabel>
            <div className='ion-margin-top'>
              <IonInput
                value={values?.street_1}
                placeholder='Street address'
                onIonChange={(e) => {
                  update({ street_1: e.detail.value })
                }}
              />
            </div>
            <div className='flex'>
              <IonInput
                value={values?.city}
                placeholder='City'
                onIonChange={(e) => {
                  update({ city: e.detail.value })
                }}
              />
              <IonInput
                minlength={2}
                maxlength={2}
                value={values?.state}
                placeholder='State'
                onIonChange={(e) => {
                  update({ state: e.detail.value })
                }}
                title='Two letter abbreviation of the state'
              />
              <IonInput
                value={values?.zipcode}
                minlength={5}
                placeholder='Zip'
                type='number'
                inputMode='numeric'
                inputmode='numeric'
                onIonChange={(e) => {
                  update({ zipcode: e.detail.value?.toString() })
                }}
                title='A five digit postal code'
              />
            </div>
          </IonItem>
          <IonItem>
            <IonLabel position='fixed'>Phone</IonLabel>
            <IonInput
              type='tel'
              inputMode='tel'
              value={values?.phone_number}
              placeholder='Phone'
              minlength={10}
              onIonChange={(e) => {
                update({ phone_number: e.detail.value })
              }}
            />
          </IonItem>
          <IonItem disabled>
            <IonLabel position='fixed'>Email</IonLabel>
            <IonInput
              type='email'
              inputMode='email'
              inputmode='email'
              pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
              value={values?.email}
              placeholder='Email'
              onIonChange={(e) => {
                update({ email: e.detail.value })
              }}
            />
          </IonItem>
        </IonList>

        <IonList className='ion-margin-bottom'>
          <IonListHeader>Financials</IonListHeader>
          <IonItem>
            <IonLabel position='stacked'>Marital status</IonLabel>
            <IonSelect
              interface='alert'
              placeholder='Unknown'
              value={values?.marital_status}
              onIonChange={(e) => {
                update({ marital_status: e.detail.value })
              }}
            >
              <IonSelectOption value='single'>Single</IonSelectOption>
              <IonSelectOption value='married'>Married</IonSelectOption>
              <IonSelectOption value='divorced'>Divorced</IonSelectOption>
              <IonSelectOption value='widowed'>Widowed</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Employment</IonLabel>
            <IonSelect
              interface='alert'
              placeholder='Unknown'
              value={values?.employment_status}
              onIonChange={(e) => {
                update({ employment_status: e.detail.value })
              }}
            >
              <IonSelectOption value='employed'>Employed</IonSelectOption>
              <IonSelectOption value='unemployed'>Unemployed</IonSelectOption>
              <IonSelectOption value='retired'>Retired</IonSelectOption>
              <IonSelectOption value='student'>Student</IonSelectOption>
            </IonSelect>
          </IonItem>

          <AmountInput
            label='Annual income'
            position='stacked'
            value={values?.annual_income}
            onChange={(value) => {
              update({ annual_income: value })
            }}
          />
        </IonList>
      </>
    </Page>
  )
}
