import { IonSelect, IonSelectOption } from '@ionic/react'
import { map } from 'lodash'
import React, { ComponentProps, useMemo } from 'react'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { TAccount } from '../../lib/types'

export interface AccountPickerProps {
  defaultValue?: string
  onSetAccount: (account: TAccount | null | undefined) => any
}

export default function AccountPicker(
  props: AccountPickerProps & ComponentProps<typeof IonSelect>
) {
  const { accounts } = useBankAccounts({ include: 'customer,institution' })

  const depositAccounts = useMemo(() => {
    return accounts?.filter((a) => a.type === 'depository' && a.customer)
  }, [accounts])

  if (!accounts)
    return (
      <IonSelect placeholder='Select account' {...props}>
        {/* <IonSelectOption defaultChecked>Use account xxxx</IonSelectOption> */}
      </IonSelect>
    )

  return (
    <IonSelect
      interface='alert'
      interfaceOptions={{ header: 'Select account' }}
      placeholder='Select account'
      value={props.defaultValue}
      onIonChange={(e) => {
        let value = e.detail?.value
        props.onSetAccount(depositAccounts?.find((a) => a.account_id === value))
      }}
      {...props}
    >
      {map(depositAccounts, (a) => {
        return (
          <IonSelectOption value={a.account_id} key={a.account_id}>
            <span>
              <img
                className='account_logo'
                src={`data:image/png;base64, ${a.institution.logo}`}
                alt='bank'
              />{' '}
              {`${a.name} ${a.mask}`}
            </span>
          </IonSelectOption>
        )
      })}
      <IonSelectOption value={null}>None</IonSelectOption>
    </IonSelect>
  )
}
