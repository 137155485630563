import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { chevronBackOutline, shareSocial } from 'ionicons/icons'
import { useState } from 'react'
import IconButton from '../../components/IconButton'
import { QRCodeImg } from '../../components/QRCode'
import { store } from '../../lib/store'
import { TGoal } from '../../lib/types'
import { copyToClipboard, shareDialog } from '../../lib/utils'
import { URL_HOST } from '../../settings'

function ShareGoalModal({
  isOpen,
  onClose,
  goal,
}: {
  isOpen?: boolean
  onClose: VoidFunction
  goal: TGoal
}) {
  const rootElement = store.useState((s) => s.presentingElement)
  const [showToast] = useIonToast()

  const shareGoalUrl = URL_HOST + `/g/${goal?.slug}`

  const copyLink = async () => {
    await copyToClipboard(shareGoalUrl)
    showToast('Link copied to clipboard', 3000)
  }

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onClose}
        canDismiss
        // presentingElement={rootElement}
      >
        <IonToolbar>
          <IonButtons slot='start'>
            <IconButton
              color='primary'
              icon={chevronBackOutline}
              onClick={onClose}
            />
          </IonButtons>
        </IonToolbar>
        <IonContent fullscreen>
          <IonList lines='none' className='flex column center align-center'>
            <IonItem>
              <h1 className='text-black'>Share Goal</h1>
            </IonItem>

            <IonItem>
              <p className='ion-margin text-lg center'>
                Share your goal link with friends and family members to start
                saving faster, together!
              </p>
            </IonItem>
          </IonList>

          <IonList lines='none' className='ion-margin-top'>
            <IonItem onClick={copyLink}>
              <div className='w100 flex column align-center'>
                <p>Scan to Share</p>
                <QRCodeImg url={shareGoalUrl} />
              </div>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <div className='flex column center ion-padding'>
            <IonButton
              expand='block'
              onClick={(e) => {
                shareDialog(
                  {
                    title: goal.name,
                    text: `Join me on ClearMoney to save for a new goal!`,
                    url: shareGoalUrl,
                  },
                  copyLink
                )
              }}
            >
              Share
            </IonButton>
            <IonButton
              className='ion-margin-bottom'
              fill='clear'
              onClick={onClose}
            >
              Close
            </IonButton>
          </div>
        </IonFooter>
      </IonModal>
    </>
  )
}

export function ShareGoalButton({ goal }: { goal: TGoal }) {
  const [isSharing, setSharing] = useState(false)
  return (
    <>
      <IonFab className='ion-margin-top' vertical='top' horizontal='end'>
        <IonFabButton size='small' onClick={() => setSharing(true)}>
          <IonIcon icon={shareSocial} />
        </IonFabButton>
      </IonFab>
      <ShareGoalModal
        isOpen={isSharing}
        onClose={() => setSharing(false)}
        goal={goal}
      />
    </>
  )
}
