export const NODE_ENV = process.env.NODE_ENV
export const IS_DEVELOPMENT = NODE_ENV === 'development'
export const IS_PRODUCTION = NODE_ENV === 'production'
export const IS_BROWSER = typeof window !== 'undefined'
export const AUTH_TOKEN_KEY = 'AUTH_TOKEN'
export const USER_SESSION_KEY = 'user'

export const DOMAIN = 'app.clearmoney.io'
export const URL_HOST = 'https://' + DOMAIN

export const API_HOST = IS_PRODUCTION
  ? `https://api.clearmoney.io/api/v1`
  : '/api/v1'

export const cssVal = (prop: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(prop)

export const isDarkMode = () =>
  window.matchMedia?.('(prefers-color-scheme: dark)')

export const isMobile = () =>
  /iphone|ipad|ipod|android/i.test(navigator.userAgent)

export const ONE_MINUTE = 60 * 1000
export const FIVE_MINUTES = 5 * ONE_MINUTE
export const ONE_HOUR = 60 * ONE_MINUTE
export const ONE_DAY = 24 * ONE_HOUR
