import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { format } from 'date-fns'
import { checkmarkCircleSharp } from 'ionicons/icons'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { AmountInput } from '../../components/AmountInput'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import { GoalSummary } from '../../components/GoalsList'
import { SharedUsersModal } from '../../components/SharedUsersModal'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useGoalMutation } from '../../hooks/mutations/useGoalMutation'
import { urlParams } from '../../lib/api'
import { Page } from '../Page'
import './goals.scss'
import { AutosavingOptions, GoalOptions } from './NewGoalPage'

export default function GoalPage({
  history,
  match: { params },
}: RouteComponentProps<any>) {
  const { goals } = useGoals({
    id: params.id as string,
    include: 'transactions',
  })
  const { leaveGoal, mutateGoal } = useGoalMutation()
  const [toast] = useIonToast()

  const goal = useMemo(() => goals[0], [goals])
  const [values, setValues] = useState<any>({ ...urlParams(), ...goal })
  const setValue = (data: any) => {
    setValues({ ...goal, ...values, ...data })
  }
  const [disabled, setDisabled] = useState(false)

  const progress = useMemo(() => {
    let current = Number(values.current || 0)
    let amount = Number(values.amount)
    return current / amount
  }, [values])

  const isSuccess = progress > 0.999

  // Update state when the goal data is fetched
  useEffect(() => {
    if (goal) setValues({ ...goal, ...values })
  }, [goal])

  // Save goal data
  const handleSubmit = async () => {
    let success
    try {
      setDisabled(true)
      await mutateGoal.mutateAsync(values)
      success = true
    } finally {
      setDisabled(false)
      if (success) history.goBack()
    }
  }

  return (
    <Page
      end={
        <IonButtons slot='end'>
          <GoalOptions isEditing goal={goal} />
        </IonButtons>
      }
      footer={
        <IonFooter>
          <IonToolbar>
            <IonButton
              disabled={
                disabled ||
                !(values.amount || values.name || values.target_date)
              }
              expand='block'
              onClick={handleSubmit}
            >
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <IonItem className='ion-padding-top' lines='none'>
        {isSuccess && (
          <IonIcon
            icon={checkmarkCircleSharp}
            color='success'
            style={{ marginRight: 10 }}
          />
        )}
        <IonInput
          type='text'
          value={values.name}
          placeholder='Goal name'
          autofocus
          inputMode='text'
          maxlength={180}
          minlength={1}
          onIonChange={(e) => setValue({ name: e.detail.value })}
          style={{ fontSize: 22, fontWeight: 500 }}
        />
      </IonItem>
      <GoalSummary
        current={values.current}
        goal={values}
        color={isSuccess ? 'success' : undefined}
      />

      <IonList className='sparse' lines='full'>
        <IonItem>
          <IonLabel slot='start'>Goal icon</IonLabel>
          <IonInput
            slot='end'
            type='text'
            inputMode='search'
            value={values.icon ?? undefined}
            placeholder='Emoji'
            maxlength={300}
            minlength={1}
            size={20}
            onIonChange={(e) => setValue({ icon: e.detail.value })}
            style={{ textAlign: 'right' }}
          />
        </IonItem>

        {values.amount !== undefined && (
          <AmountInput
            label='Amount'
            position='fixed'
            slot='end'
            required
            value={values.amount}
            placeholder='$100'
            onChange={(amount) => setValue({ amount })}
            rightAlign
          />
        )}

        <IonItem>
          <DatePicker
            slot='end'
            label='Target date'
            format='MMMM d, yyyy'
            min={format(new Date(), 'yyyy-MM-dd')}
            max='2100-12-31'
            value={values.target_date ?? null}
            onChange={(target_date) => setValue({ target_date })}
            style={{ textAlign: 'right' }}
          />
        </IonItem>

        <SharedUsersModal
          detail
          disabled={!goal?.shared_goal.length}
          users={goal?.shared_goal}
          title='Shared with'
          onRemove={(user) =>
            leaveGoal.mutate(
              { goal_id: goal.id, user_id: user.user_id },
              {
                onSuccess: () => toast('You left the shared goal.', 5000),
                onError: () =>
                  toast('Something went wrong, please try again.', 5000),
              }
            )
          }
        />

        <AutosavingOptions values={values} setValue={setValue} />
      </IonList>

      {/* <TransactionsSection
        label="Recent activity"
        data={goal?.transactions}
        isLoading={isFetchingGoals}
      /> */}
    </Page>
  )
}
