import {
  IonButtons,
  IonContent,
  IonDatetime,
  IonLabel,
  IonModal,
  IonToolbar,
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'
import { ComponentProps, useState } from 'react'
import { DateTime } from '../DateTime'
import IconButton from '../IconButton'

function setProps(props: any) {
  let newProps = {} as any
  for (const key in Object.getOwnPropertyNames(props)) {
    if (props[key] !== undefined) {
      newProps[key] = props[key]
    }
  }
  return newProps
}

export function DatePicker({
  placeholder = '...',
  label,
  onChange,
  presentation = 'date',
  format = 'MMMM dd, yyyy',
  slot,
  style,
  ...rest
}: {
  placeholder?: string
  label?: string
  onChange: (value: string | null) => void
  format?: string
  slot?: string
} & ComponentProps<typeof IonDatetime>) {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      {label && (
        <IonLabel slot='start' onClick={() => setOpen(true)}>
          {label}
        </IonLabel>
      )}
      <IonLabel onClick={() => setOpen(true)} style={style}>
        <DateTime
          value={rest.value!}
          placeholder={placeholder}
          format={format}
        />
      </IonLabel>
      <IonModal
        className='modal-datepicker'
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        backdropDismiss
      >
        <IonContent>
          <IonToolbar>
            <IonButtons slot='start'>
              <IconButton
                color='primary'
                icon={chevronBackOutline}
                onClick={() => setOpen(false)}
              />
            </IonButtons>
          </IonToolbar>
          <div className='ion-margin'>
            <IonDatetime
              className='datepicker'
              presentation={presentation}
              {...rest}
              onIonChange={(e) => {
                onChange(e.detail.value!)
              }}
            />
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}
