import {
  IonButton,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from '@ionic/react'
import { format } from 'date-fns'
import {
  ellipsisHorizontalCircleOutline,
  settingsOutline,
} from 'ionicons/icons'
import { filter } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import AccountPicker from '../../components/accountPicker/AccountPicker'
import { AmountInput } from '../../components/AmountInput'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import { GoalSummary } from '../../components/GoalsList'
import IconButton from '../../components/IconButton'
import { cash, Currency } from '../../components/Numeral'
import { TransactionsSection } from '../../components/transactions/TransactionsList'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useGoalMutation } from '../../hooks/mutations/useGoalMutation'
import { urlParams } from '../../lib/api'
import { Day } from '../../lib/dates'
import { store } from '../../lib/store'
import { getPaymentScheduled } from '../../lib/transactions'
import { TGoal } from '../../lib/types'
import { Page } from '../Page'
import './goals.scss'

export const GoalOptions = ({
  goal,
  isEditing = false,
}: {
  goal: TGoal
  isEditing?: boolean
}) => {
  const user = store.useState((s) => s.user)
  const history = useIonRouter()
  const [alert] = useIonAlert()
  const [showActions] = useIonActionSheet()
  const { deleteGoal, mutateGoal } = useGoalMutation()
  const isGoalOwner = goal?.user_id === user!.uid
  return (
    <>
      <IconButton
        icon={ellipsisHorizontalCircleOutline}
        color='dark'
        onClick={() => {
          showActions(
            filter<any>(
              [
                isGoalOwner && {
                  text: 'Edit',
                  handler() {
                    history.push(`/goals/edit/${goal.id}`, 'forward')
                  },
                },
                goal.hidden
                  ? {
                      text: 'Activate',
                      handler() {
                        mutateGoal.mutate(
                          {
                            id: goal.id,
                            hidden: false,
                          },
                          {
                            onSuccess: () => history.goBack(),
                          }
                        )
                      },
                    }
                  : {
                      text: 'Archive',
                      handler() {
                        mutateGoal.mutate(
                          {
                            id: goal.id,
                            hidden: true,
                          },
                          {
                            onSuccess: () => history.goBack(),
                          }
                        )
                      },
                    },
                {
                  text: 'Delete',
                  role: 'destructive',
                  handler: async () => {
                    await alert({
                      header: 'Delete goal',
                      message:
                        'All related data and progress will be lost. Do you want to continue?',
                      buttons: [
                        {
                          text: 'Cancel',
                        },
                        {
                          text: 'OK',
                          handler: async () => {
                            deleteGoal.mutate(goal.id, {
                              onSuccess: () => history.goBack(),
                            })
                          },
                        },
                      ],
                    })
                  },
                },
                { text: 'Cancel', role: 'cancel' },
              ],
              (x) => x !== false
            ),
            'Actions'
          )
        }}
      />
    </>
  )
}

export const SharedGoalOptions = ({ goal }: { goal: TGoal }) => {
  const user = store.useState((s) => s.user)
  const [alert] = useIonAlert()
  const [showActions] = useIonActionSheet()
  const [toast] = useIonToast()
  const history = useIonRouter()
  const { leaveGoal, mutateSharedGoal } = useGoalMutation({ id: goal?.id })

  return (
    <IconButton
      icon={ellipsisHorizontalCircleOutline}
      color='dark'
      onClick={() =>
        showActions(
          [
            {
              text: 'Archive',
              handler: () =>
                alert({
                  header: 'Archive goal',
                  message: 'Are you sure you want to archive this goal?',
                  buttons: [
                    'No',
                    {
                      text: 'Yes',
                      handler: () =>
                        mutateSharedGoal.mutate(
                          {
                            goal_id: goal.id,
                            hidden: true,
                          },
                          {
                            onSuccess: () => history.goBack(),
                            onError: () =>
                              toast(
                                'Something went wrong, please try again.',
                                5000
                              ),
                          }
                        ),
                    },
                  ],
                }),
            },
            {
              text: 'Leave Shared Goal',
              cssClass: 'text-danger',
              handler: () =>
                alert({
                  header: 'Leave Shared Goal',
                  message: 'Are you sure you want to leave this shared goal?',
                  buttons: [
                    'No',
                    {
                      text: 'Yes',
                      handler: () =>
                        leaveGoal.mutate(
                          {
                            goal_id: goal.id,
                            user_id: user!.uid,
                          },
                          {
                            onSuccess: () =>
                              toast('You left the shared goal.', 5000),
                            onError: () =>
                              toast(
                                'Something went wrong, please try again.',
                                5000
                              ),
                          }
                        ),
                    },
                  ],
                }),
            },
          ],
          'Actions'
        )
      }
    />
  )
}

export default function NewGoalPage({ history }: RouteComponentProps<any>) {
  const initialValues = urlParams()
  const [values, setState] = useState<any>({
    isrecurring: 7,
    ...initialValues,
  })

  const { goals, isFetchingGoals } = useGoals({
    id: initialValues.id as string,
    include: initialValues.id ? 'transactions' : undefined,
  })
  let prevGoal = goals[0]

  const { mutateGoal } = useGoalMutation({
    id: initialValues.id as string,
  })

  const [disabled, setDisabled] = useState(false)

  const isUpdating = !!initialValues.id
  const setValue = (data: any) => setState({ ...values, ...data })

  useEffect(() => {
    if (prevGoal) setState({ ...values, ...prevGoal })
  }, [prevGoal])

  useEffect(() => {
    // Reset the stored state when going back
    setState(initialValues)
  }, [history])

  const color = useMemo(() => {
    const progress = parseFloat(values.current) / parseFloat(values.amount)
    if (progress > 0.99) return 'success'
    if (Day() > new Date(values.target_date)) {
      return 'danger'
    }
  }, [values])

  const contributions = useMemo(() => {
    if (values.amount && values.target_date) {
      return getPaymentScheduled(
        values.amount,
        new Date(values.target_date),
        values.isrecurring
      )
    }
    return 0
  }, [values])

  const handleSubmit = async () => {
    let success
    try {
      setDisabled(true)
      await mutateGoal.mutateAsync(values)
      success = true
    } finally {
      setDisabled(false)
      if (success) history.goBack()
    }
  }

  return (
    <Page
      isLoading={isFetchingGoals}
      footer={
        <IonFooter>
          <IonToolbar>
            <IonButton
              disabled={
                disabled ||
                !(values.amount || values.name || values.target_date)
              }
              expand='block'
              onClick={handleSubmit}
            >
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      {isUpdating && (
        <GoalSummary current={values.current} goal={values} color={color} />
      )}

      <IonList className='sparse ion-margin-top' lines='full'>
        <IonItem>
          <IonLabel position='stacked'>Goal name</IonLabel>
          <IonInput
            type='text'
            value={values.name}
            placeholder='Goal name'
            autofocus
            inputMode='text'
            maxlength={300}
            minlength={1}
            size={20}
            onIonChange={(e) => setValue({ name: e.detail.value })}
            style={{ textAlign: 'left', fontSize: 20 }}
          />
        </IonItem>

        <IonItem>
          <IonLabel slot='start'>Goal icon</IonLabel>
          <IonInput
            slot='end'
            type='text'
            inputMode='search'
            value={values.icon}
            placeholder='emoji'
            maxlength={300}
            minlength={1}
            size={20}
            onIonChange={(e) => setValue({ icon: e.detail.value })}
            style={{ textAlign: 'right' }}
          />
        </IonItem>

        <AmountInput
          label='Amount'
          position='fixed'
          slot='end'
          required
          value={values.amount}
          placeholder='$100'
          onChange={(amount) => setValue({ amount })}
          rightAlign
        />

        <IonItem>
          <DatePicker
            slot='end'
            label='Target date'
            format='MMMM d, yyyy'
            min={format(new Date(), 'yyyy-MM-dd')}
            max='2100-12-31'
            value={values.target_date ?? null}
            onChange={(target_date) => setValue({ target_date })}
          />
        </IonItem>

        <IonItem>
          <IonLabel>
            Save <Currency round value={contributions} />
          </IonLabel>
          every{' '}
          <IonSelect
            slot='end'
            value={values.isrecurring ?? 7}
            interface='action-sheet'
            onIonChange={(e) => setValue({ isrecurring: e.detail.value })}
          >
            <IonSelectOption defaultChecked value={7}>
              Week
            </IonSelectOption>
            <IonSelectOption defaultChecked value={14}>
              2 Weeks
            </IonSelectOption>
            <IonSelectOption defaultChecked value={30}>
              Month
            </IonSelectOption>
            <IonSelectOption defaultChecked value={90}>
              Quarter
            </IonSelectOption>
          </IonSelect>
        </IonItem>

        <AutosavingOptions values={values} setValue={setValue} />
      </IonList>

      {prevGoal?.transactions?.length > 0 && (
        <TransactionsSection
          label='Recent transactions'
          data={prevGoal?.transactions}
        />
      )}
    </Page>
  )
}

export function AutosavingOptions({
  values,
  setValue,
}: {
  values: TGoal
  setValue: (data: any) => any
}) {
  const [isAtosaving, setAutosaving] = useState(!!values.isrecurring)

  const contributions = useMemo(() => {
    if (values.amount && values.target_date) {
      return getPaymentScheduled(
        Number(values.amount),
        new Date(values.target_date),
        values.isrecurring ?? 1
      )
    }
    return 0
  }, [values])

  return (
    <>
      <IonItem>
        <IonLabel className='ion-margin-vertical' position='stacked'>
          Linked account
        </IonLabel>
        <AccountPicker
          className='ion-padding-bottom'
          interface='action-sheet'
          defaultValue={values.account_id}
          onSetAccount={(a) => {
            if (!a) return setValue({ account_id: undefined })
            setValue({ account_id: a.account_id })
          }}
        />
      </IonItem>

      <IonItem disabled={!values.account_id}>
        <IonLabel>Enable auto saving</IonLabel>
        <IonToggle
          color='primary'
          slot='end'
          checked={isAtosaving || !!values.isrecurring}
          onIonChange={(e) => {
            let isChecked = e.detail.checked
            if (!isChecked) {
              setValue({ isrecurring: null })
            }
            setAutosaving(!isAtosaving)
          }}
        />
      </IonItem>

      <AmountInput
        disabled={!isAtosaving || !values.account_id}
        label='Amount'
        position='fixed'
        slot='end'
        placeholder={`${cash(contributions)}`}
        onChange={(start_amount) => {
          setValue({ start_amount })
        }}
        value={values.start_amount}
        rightAlign
      />

      <IonItem disabled={!isAtosaving || !values.account_id}>
        <IonLabel>Every</IonLabel>
        <IonSelect
          slot='end'
          value={values.isrecurring || null}
          interface='action-sheet'
          onIonChange={(e) => setValue({ isrecurring: e.detail.value })}
        >
          <IonSelectOption value={7}>Week</IonSelectOption>
          <IonSelectOption value={14}>2 Weeks</IonSelectOption>
          <IonSelectOption value={30}>Month</IonSelectOption>
          <IonSelectOption value={90}>Quarter</IonSelectOption>
        </IonSelect>
      </IonItem>
    </>
  )
}
