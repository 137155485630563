import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { assign, forEach, groupBy } from 'lodash'
import { useMemo } from 'react'
import { client } from '../../lib/api'
import { chain } from '../../lib/iters'
import { balance, currentBalance } from '../../lib/transactions'
import { TAccount } from '../../lib/types'
import { FIVE_MINUTES } from '../../settings'

function fetchAccounts(params = {}) {
  return client.get('/accounts/', { params })
}

function totalBalance(accounts?: TAccount[]) {
  if (!accounts?.length) return 0
  let grouped = groupBy(accounts, 'type')
  let debit = grouped['depository']
  let savings = grouped['savings']
  let credit = grouped['credit']
  let loan = grouped['loan']
  let investment = grouped['investment']
  let asset = grouped['asset']

  const assetsTotal = sumAccounts(
    chain(asset, investment, savings, debit),
    balance
  )
  const liabTotal = sumAccounts(chain(credit, loan), currentBalance)
  return assetsTotal - liabTotal
}

export function sumAccounts(list: any, selector = balance) {
  let total = 0
  for (const a of list) {
    if (a && !a.hide_balances) {
      total += selector(a)
    }
  }
  return total
}

export function useBankAccounts(
  params: any = {},
  opts: UseQueryOptions<TAccount[]> = {}
) {
  // const queryClient = useQueryClient()

  const { isLoading, data, isError, error } = useQuery<any, any, TAccount[]>(
    ['accounts', params],
    () => fetchAccounts(params),
    {
      staleTime: FIVE_MINUTES,
      ...opts,
    }
  )

  const accountTypes = useMemo(() => {
    let types = groupBy(data, 'type') as any
    forEach(types, (list, type) => {
      assign(types[type], {
        type,
        amount: sumAccounts(list, balance),
        accounts: list,
        // limit: sumAccounts(list, balanceLimit),
      })
    })
    return types
  }, [data])

  return {
    isFetchingAccounts: isLoading,
    accounts: data,
    totalBalance: totalBalance(data),
    accountTypes,
    accountsError: error,
    isAccountsError: isError,
  }
}
