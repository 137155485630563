import { isAfter, isBefore, startOfMonth } from 'date-fns'
import { useMemo } from 'react'
import { Day } from '../../lib/dates'
import {
  selectExpenses,
  selectWeekly,
  summaries,
  toLocaleDate,
  _summary,
} from '../../lib/transactions'
import { TTransaction } from '../../lib/types'
import { FIVE_MINUTES } from '../../settings'
import useFetch from '../useFetch'

export function useSummaries({ startDate, ...opts }: any = {}) {
  const { isLoading, isError, data, error } = useFetch<TTransaction[]>(
    ['summaries', { startDate }],
    {
      url: '/transactions/summaries/',
      params: { startDate },
    },
    {
      staleTime: FIVE_MINUTES,
      ...opts,
    }
  )

  const { monthly, expenses } = useMemo(() => {
    return summaries(data)
  }, [data])

  const lastMonthSummary = monthly[monthly.length - 1]
  const currentMonthStart = startOfMonth(Day())
  const monthPlaceholders = {
    ..._summary,
    totals: {
      ...lastMonthSummary?.totals,
      income: 0,
      expenses: 0,
      balance: 0,
      categories: {},
    },
  }

  console.log(
    'date',
    isBefore(toLocaleDate(lastMonthSummary?.date), currentMonthStart)
  )

  return {
    summaries: monthly,
    expenses,
    thisWeek: {
      expenses: selectWeekly(expenses),
    },
    isFetchingSummaries: isLoading,
    currentMonth:
      lastMonthSummary?.date &&
      isBefore(toLocaleDate(lastMonthSummary?.date), currentMonthStart)
        ? monthPlaceholders
        : lastMonthSummary,
    summariesError: error,
    isSummariesError: isError,
  }
}
