import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'
import { TTransaction } from '../../lib/types'
import IconButton from '../IconButton'
import { TransactionsSection } from './TransactionsList'

export function TransactionsListModal({
  isOpen,
  transactions,
  title,
  label,
  onDismiss,
}: {
  isOpen?: boolean
  transactions: TTransaction[]
  title?: string
  label?: string
  onDismiss: VoidFunction
}) {
  const showContent = !(!transactions || !transactions[0]?.id)
  return (
    <IonModal isOpen={showContent} onDidDismiss={onDismiss} backdropDismiss>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IconButton icon={chevronBackOutline} onClick={onDismiss} />
          </IonButtons>
          {title && <IonTitle>{title}</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showContent && (
          <TransactionsSection label={label} data={transactions} />
        )}
      </IonContent>
    </IonModal>
  )
}
