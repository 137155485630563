import {
  IonButton,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { useFormik } from 'formik'
import { alertCircle } from 'ionicons/icons'
import { useEffect } from 'react'

import { RouteComponentProps } from 'react-router'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import { useCustomer } from '../../hooks/loaders/useCustomer'
import { useProfile } from '../../hooks/loaders/useProfile'
import { useCustomerMutation } from '../../hooks/mutations/useCustomerMutation'
import { store } from '../../lib/store'
import { TCustomer } from '../../lib/types'
import { Page } from '../Page'

type CustomerState = Partial<TCustomer>

const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

// See https://developers.dwolla.com/guides/business-verified-customer/handle-verification-statuses#verification-statuses-and-corresponding-events
export default function CustomerInfoPage({
  match,
  history,
}: RouteComponentProps<{
  account_id: string
}>) {
  const { account_id } = match.params
  const user = store.useState((s) => s.user)
  const [toast] = useIonToast()
  const { customer } = useCustomer({
    account_id: account_id,
  })
  const { putCustomer } = useCustomerMutation({ account_id })
  const { profile } = useProfile()
  const {
    values,
    setValues,
    isValid,
    isSubmitting,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik<CustomerState>({
    initialValues: {
      account_id: account_id,
      user_id: user!.uid,
    },
    validate: (values) => {
      const errors = {} as CustomerState
      if (!values.first_name) {
        errors.first_name = 'Required'
      }
      if (!values.last_name) {
        errors.last_name = 'Required'
      }
      if (!values.email) {
        errors.email = 'Required'
      } else if (!emailRegex.test(values.email)) {
        errors.email = 'Invalid email address'
      }
      // TODO: validate more fields
      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      putCustomer.mutate(values, {
        onError: () => {
          toast({
            message: 'Something went wrong, please try again',
            icon: alertCircle,
          })
        },
        onSettled: () => {
          setSubmitting(false)
        },
      })
    },
  })

  useEffect(() => {
    setValues({
      ...values,
      first_name: customer?.first_name ?? profile?.first_name,
      last_name: customer?.last_name ?? profile?.last_name,
      dob: customer?.dob ?? profile?.birth_date,
      email: customer?.email ?? profile?.email ?? user?.email ?? undefined,
      address1: customer?.address1 ?? profile?.street_1,
      city: customer?.city ?? profile?.city,
      state: customer?.state ?? profile?.state,
      zip: customer?.zip ?? profile?.zipcode,
    })
  }, [customer, profile, user])

  return (
    <Page
      footer={
        <IonFooter>
          <IonToolbar>
            <div className='flex column center ion-padding background'>
              <IonButton
                disabled={isSubmitting || !isValid}
                expand='block'
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? 'Please wait...' : 'Confirm details'}
              </IonButton>
              {/* <IonButton
              className='ion-margin-bottom'
              fill='clear'
              onClick={() => history.goBack()}
            >
              Close
            </IonButton> */}
            </div>
          </IonToolbar>
        </IonFooter>
      }
    >
      <IonList lines='none' className='flex column center align-center'>
        <IonItem>
          <h1 className='text-black'>Account Details</h1>
        </IonItem>
        <IonItem>
          <p className='center'>
            Please confirm your personal information matches your bank account
            details.
          </p>
        </IonItem>
      </IonList>

      <IonList className='ion-margin-vertical'>
        <IonItem lines='none' className='header-item'>
          <IonLabel position='stacked'>Account details</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel position='fixed'>First name</IonLabel>
          <IonInput
            required
            autocomplete='given-name'
            name='first_name'
            value={values?.first_name}
            onIonChange={(e) => setFieldValue('first_name', e.detail.value)}
            placeholder='Joe'
          />
          {errors.first_name && (
            <IonText slot='error' color='danger'>
              {errors.first_name}
            </IonText>
          )}
        </IonItem>

        <IonItem>
          <IonLabel position='fixed'>Last name</IonLabel>
          <IonInput
            required
            autocomplete='family-name'
            name='last_name'
            value={values?.last_name}
            onIonChange={(e) => setFieldValue('last_name', e.detail.value)}
            placeholder='Smith'
          />
          {errors.last_name && (
            <IonText slot='error' color='danger'>
              {errors.last_name}
            </IonText>
          )}
        </IonItem>

        {/* Customer emails should be unique and immutable */}
        {!customer?.email && !profile?.email && (
          <IonItem>
            <IonLabel position='fixed'>Email</IonLabel>
            <IonInput
              required
              autocomplete='email'
              type='email'
              inputMode='email'
              inputmode='email'
              name='email'
              value={values?.email}
              onIonChange={(e) => setFieldValue('email', e.detail.value)}
              placeholder='Email'
            />
            {errors.email && (
              <IonText slot='error' color='danger'>
                {errors.email}
              </IonText>
            )}
          </IonItem>
        )}
      </IonList>

      <IonList>
        <IonItem>
          <IonLabel position='fixed'>Birthday</IonLabel>
          <DatePicker
            format='MMMM dd, yyyy'
            value={values?.dob}
            onChange={(dob) => setFieldValue('dob', dob)}
          />
        </IonItem>

        <IonItem>
          <IonLabel position='fixed'>SSN</IonLabel>
          <IonInput
            required
            autocomplete='off'
            type='password'
            inputMode='numeric'
            inputmode='numeric'
            name='ssn'
            minlength={4}
            maxlength={9}
            value={values?.ssn}
            onIonChange={(e) => setFieldValue('ssn', e.detail.value)}
            placeholder='000000000'
          />
          <IonText color='medium' slot='helper'>
            For verification only, will not be stored.
          </IonText>
        </IonItem>
      </IonList>

      <IonList className='ion-margin-vertical'>
        <IonItem>
          <IonLabel position='stacked' style={{ marginBottom: '1rem' }}>
            Home address
          </IonLabel>
          <IonInput
            required
            autocomplete='address-line1'
            name='address1'
            value={values?.address1}
            onIonChange={(e) => setFieldValue('address1', e.detail.value)}
            placeholder='Street address'
            title='Note: PO Boxes are not allowed'
          />
        </IonItem>
        <IonItem>
          <div className='flex'>
            <IonInput
              required
              autocomplete='address-level2'
              name='city'
              value={values?.city}
              onIonChange={(e) => setFieldValue('city', e.detail.value)}
              placeholder='City'
            />
            <IonInput
              required
              autocomplete='address-level1'
              name='state'
              value={values?.state}
              onIonChange={(e) => setFieldValue('state', e.detail.value)}
              placeholder='NY'
              minlength={2}
              maxlength={2}
              title='Two letter abbreviation of the state'
            />
            <IonInput
              required
              autocomplete='postal-code'
              name='zip'
              value={values?.zip}
              onIonChange={(e) => setFieldValue('zip', e.detail.value)}
              placeholder='12345'
              type='number'
              inputMode='numeric'
              inputmode='numeric'
              minlength={5}
              title='A five digit postal code'
            />
          </div>
        </IonItem>
      </IonList>

      <IonList lines='none'>
        <IonItem>
          <IonLabel color='medium' className='ion-text-wrap'>
            <p>
              This information is complete and accurate, and I agree to Dwolla's
              Terms and Privacy Policy.
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    </Page>
  )
}
