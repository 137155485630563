import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps'
import cx from 'classnames'
import { ComponentProps } from 'react'
import { cash } from './Numeral'

export function IntInput({
  label,
  value,
  position,
  placeholder = '0',
  format = '0',
  inputMode = 'numeric',
  required = false,
  onChange,
  style,
  min,
  max,
  step = '1',
  slot,
  rightAlign,
  ...rest
}: {
  label: string
  format?: string
  placeholder?: string
  value: any
  min?: string
  max?: string
  position?: 'fixed' | 'floating' | 'stacked'
  step?: string
  slot?: string
  required?: boolean
  style?: IonicReactProps['style']
  rightAlign?: boolean
  onChange: (value: number | null) => any
} & ComponentProps<typeof IonItem>) {
  let inputProps = {
    type: 'number',
    format,
    inputMode,
    min,
    max,
    step,
    placeholder,
    required: !!required,
    style: {
      textAlign: rightAlign ? 'right' : 'left',
      ...style,
    },
    value,
    onChange,
  } as any
  if (slot) inputProps.slot = slot
  return (
    <IonItem {...rest} className={cx('item-input', rest.className)}>
      <IonLabel position={position}>{label}</IonLabel>
      <NumberInput {...inputProps} />
    </IonItem>
  )
}

export function AmountInput({
  label,
  value,
  position,
  placeholder = '$0',
  format = '$0,0[.]00',
  inputMode = 'decimal',
  required = false,
  onChange,
  style,
  min,
  max,
  step,
  slot,
  rightAlign,
  ...rest
}: ComponentProps<typeof IonItem> & {
  label: string
  format?: string
  placeholder?: string
  value: any
  min?: string
  max?: string
  position?: 'fixed' | 'floating' | 'stacked'
  step?: string
  slot?: string
  required?: boolean
  style?: IonicReactProps['style']
  rightAlign?: boolean
  onChange: (value: number | null) => any
}) {
  let inputProps = {
    type: 'number',
    format,
    inputMode,
    min,
    max,
    step,
    placeholder,
    required: !!required,
    style: {
      textAlign: rightAlign ? 'right' : 'left',
      ...style,
    },
    value,
    onChange,
  } as any
  if (slot) inputProps.slot = slot
  return (
    <IonItem {...rest} className={cx('item-input', rest.className)}>
      <IonLabel position={position}>{label}</IonLabel>
      <NumberInput {...inputProps} />
    </IonItem>
  )
}

export function PercentInput({
  label,
  value,
  position,
  placeholder = '%',
  format = '0[.]00%',
  required = false,
  onChange,
  style,
  min,
  max,
  step,
  slot,
  rightAlign,
  ...rest
}: {
  label: string
  format?: string
  placeholder?: string
  value: any
  min?: string
  max?: string
  position?: 'fixed' | 'floating' | 'stacked'
  step?: string
  slot?: string
  required?: boolean
  style?: IonicReactProps['style']
  rightAlign?: boolean
  onChange: (value: number | null) => any
} & ComponentProps<typeof IonItem>) {
  let inputProps = {
    type: 'number',
    format,
    inputMode: 'decimal',
    min,
    max,
    step,
    placeholder,
    required: !!required,
    style: {
      textAlign: rightAlign ? 'right' : 'left',
      ...style,
    },
    value: value != null ? value / 100 : '',
    onChange,
  } as any
  if (slot) inputProps.slot = slot
  return (
    <IonItem {...rest} className={cx('item-input', rest.className)}>
      <IonLabel position={position}>{label}</IonLabel>
      <NumberInput {...inputProps} />
    </IonItem>
  )
}

export function NumberInput({
  debounce = 500,
  format,
  onChange,
  style,
  rightAlign,
  ...rest
}: {
  format?: string
  rightAlign?: boolean
  onChange: (value: number | null) => any
} & ComponentProps<typeof IonInput>) {
  let props = {
    ...rest,
    debounce,
    type: 'text',
    inputMode: 'decimal',
    style: {
      textAlign: rightAlign ? 'right' : 'left',
      ...style,
    },
  } as ComponentProps<typeof IonInput>
  return (
    <IonInput
      {...props}
      value={props.value == null ? props.value : cash(props.value, format)}
      onIonChange={(e) => {
        let input = e.detail.value?.replace(/[^\d.-]/g, '').trim()
        if (!input) return onChange(null)
        let value = parseFloat(input)
        if (!isNaN(value)) return onChange(value)
      }}
    />
  )
}
