import { IonInput, IonItem, IonLabel, IonList } from '@ionic/react'
import { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { AmountInput, PercentInput } from '../../components/AmountInput'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { Currency } from '../../components/Numeral'
import { urlParams } from '../../lib/api'
import { carLoanCalc } from '../../lib/utils'
import { Page } from '../Page'
import './goals.scss'

export default function LoanCalculatorPage({
  history,
}: RouteComponentProps<any>) {
  const initialValues = urlParams()
  const [values, setState] = useState<any>({
    ...initialValues,
  })
  const setValue = (data: any) => setState({ ...values, ...data })

  const payments = useMemo(() => {
    let { amount, down, rate, months } = values
    return carLoanCalc(amount, rate, months, down)
  }, [values])

  return (
    <Page title={values.name ?? 'Loan Calculator'}>
      <IonList className='sparse ion-margin-top'>
        <IonItem>
          <IonLabel position='stacked'>Goal</IonLabel>
          <IonInput
            type='text'
            value={values.name}
            placeholder='Goal Name'
            autofocus
            inputMode='text'
            maxlength={300}
            minlength={1}
            size={20}
            onIonChange={(e) => setValue({ name: e.detail.value })}
            style={{ fontSize: 20 }}
          />
        </IonItem>

        <AmountInput
          label='Total amount'
          slot='end'
          rightAlign
          required
          value={values.amount}
          placeholder='$25,000'
          onChange={(amount) => setValue({ amount })}
          style={{ fontSize: 20 }}
        />

        <AmountInput
          label='Down payment'
          slot='end'
          rightAlign
          required
          value={values.down}
          placeholder='$2,000'
          onChange={(down) => setValue({ down })}
          style={{ fontSize: 20 }}
        />

        <PercentInput
          label='Interest Rate (APR)'
          slot='end'
          rightAlign
          required
          value={values.rate}
          placeholder='%'
          onChange={(rate) => {
            setValue({ rate })
          }}
          style={{ fontSize: 20 }}
        />

        <AmountInput
          label='Months'
          format='0'
          slot='end'
          rightAlign
          required
          value={values.months}
          placeholder='60'
          min='1'
          max='1000'
          step='1'
          inputMode='numeric'
          onChange={(months) => {
            setValue({ months })
          }}
          style={{ fontSize: 20 }}
        />
      </IonList>

      {payments && (
        <IonList lines='none'>
          {/* <IonItem>
            <IonLabel>Taxes, fees</IonLabel>
            <IonLabel slot="end">
              <Currency value={payments.fees} />
            </IonLabel>
          </IonItem> */}

          <IonItem>
            <LabelWithInfo
              label='Total cost'
              header='Total cost'
              message='The total amount paid over the life of the loan including price, down payment, interest, sales tax and fees.'
            />
            <IonLabel slot='end'>
              <Currency value={payments.totalCost} />
            </IonLabel>
          </IonItem>

          <IonItem>
            <LabelWithInfo
              label='Total interest'
              header='Total interest'
              message='The total amount of interest paid over the life of the loan.'
            />
            <IonLabel slot='end'>
              <Currency value={payments.totalInterest} />
            </IonLabel>
          </IonItem>

          <IonItem>
            <LabelWithInfo
              label={<strong>Monthly</strong>}
              header='Monthly'
              message='The amount you will pay every month over the life of the loan.'
            />
            <IonLabel slot='end'>
              <strong>
                <Currency value={payments.monthly} />
              </strong>
            </IonLabel>
          </IonItem>
        </IonList>
      )}
    </Page>
  )
}
