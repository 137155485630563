import { useCallback, useEffect } from 'react'
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link'
import { cleanup } from '../hooks/loaders/usePlaidClient'
import { exchangeToken } from '../lib/api'
import { analytics } from '../lib/firebase'

interface PlaidLinkProps {
  token: string
  receivedRedirectUri?: string | null
  onSuccess?: () => any
  onExit?: () => any
  onHandoff?: () => any
  onError?: (err: any) => any
}

export function PlaidLink({
  token,
  receivedRedirectUri,
  onSuccess: onLink,
  onExit: onDismiss,
  onHandoff,
  onError,
}: PlaidLinkProps) {
  const onExit = useCallback(
    async (error: PlaidLinkError | null, data: PlaidLinkOnExitMetadata) => {
      console.log('plaid exited', error, data)
      // Is called when user exits Link without successful liked Item, or with errors
      // See https://plaid.com/docs/link/web/#onexit
      cleanup()
      // handle invalid link token
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        // TODO: handle invalid token
      } else if (error?.error_code === 'ITEM_LOGIN_REQUIRED') {
        // TODO: reauthenticate using update mode
        // See https://plaid.com/docs/link/update-mode/
      }
      // to handle other error codes, see https://plaid.com/docs/errors/
      analytics.logEvent('link_exit', { error, data })
      if (onDismiss) onDismiss()
    },
    [onDismiss]
  )

  const onSuccess = useCallback(
    async (token: string, data: PlaidLinkOnSuccessMetadata) => {
      // Note this needs to be done before cleaning up!
      const itemId = sessionStorage.getItem('LINK_ITEM_ID')
      cleanup()
      try {
        // Exchange link token
        await exchangeToken(token, data.institution, data.accounts, itemId)
        analytics.logEvent('link_success')
        if (onLink) onLink()
      } catch (error: any) {
        analytics.logEvent('link_error', { data: error.response?.data })
        if (onError) onError(error.response?.data)
      }
    },
    [onError, onLink]
  )

  const config: PlaidLinkOptions = {
    token: token,
    // required for OAuth:
    // if not OAuth, set to null or do not include:
    // @ts-ignore
    receivedRedirectUri,
    onSuccess,
    onExit,
    onEvent: (eventName, data) => {
      console.log('plaid event', eventName, data)
      // See: https://plaid.com/docs/link/best-practices/
      if (eventName === 'ERROR') {
        if (onError) onError(data)
      } else if (eventName === 'HANDOFF') {
        if (onHandoff) onHandoff()
      }
    },
  }

  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const { ready, open } = usePlaidLink(config)

  useEffect(() => {
    if (ready) {
      open()
      analytics.logEvent('link_start')
    }
  }, [ready, open])

  return null
}
