import { capitalize } from 'lodash'
import { TTransaction } from './types'

// Common transaction starts with
export const HEADERS = /(ACH HOLD|CHECKCARD|PURCHASE|Refund|Mobile)\s*/gim

// Can be ID: followed by alphanumeric codes
export const ACH_ID = /(ID:|ACH)\s*([A-Z]+[\d-]+[A-Z]*[\d\-]*|\S{6})\s+.*/gim

export const NACHA_FIELDS =
  /(DES:|ID:|INDN:.*|INFO:|TYPE:|TRN:|REF:|PMNT RCVD\s*)/gim

// Remove numeric transaction codes and dates
export const TX_NUMBERS =
  /(?=[\*A-Z]*[0-9]+)[0-9A-Z;\-]+|(Confirmation\W*[0-9]{4,}\s*|(\bON)?\s*\d+\/\d+|(Conf|Ref|number)\W*[A-z0-9;\-]+)/gim

var punctRE =
  /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g
var spaceRE = /\s+/g

// TODO: tokenize and entities
export function cleanKeywords(name: string) {
  if (!name) return ''
  name = name.replace(HEADERS, '')
  name = name.replace(ACH_ID, '')
  name = name.replace(NACHA_FIELDS, '')
  name = name.replace(TX_NUMBERS, '')
  // replace everything except alphanumeric and spaces
  name = name.replace(/[^\w\s\']|_/g, '')
  // replace multiple spaces with single
  return name.replace(/\s+/g, ' ').trim()
}

export function parseTxName(desc: string) {
  const clean = cleanKeywords(desc)
  return (capitalize(clean) || desc) ?? ''
}

export function merchantName(tx: any) {
  if (tx.label) {
    return tx.label
  }
  if (tx.merchant_name) {
    return tx.merchant_name
  }
  return parseTxName(tx.name)
}
