import { IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react'
import React, { ReactElement } from 'react'

export default function Navbar({
  start,
  end,
  title,
  color,
}: {
  start?: ReactElement | null
  end?: ReactElement | string
  title?: ReactElement | string
  color?: string
}) {
  return (
    <IonHeader>
      <IonToolbar className='navbar' color={color}>
        {start ?? <IonMenuButton slot='start'></IonMenuButton>}
        {title && <IonTitle>{title}</IonTitle>}
        {end}
      </IonToolbar>
    </IonHeader>
  )
}
