import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from '@ionic/react'
import { startOfMonth } from 'date-fns'
import { map } from 'lodash'
import { abs } from 'mathjs'
import React from 'react'
import { useSummaries } from '../hooks/loaders/useSummaries'
import { Day } from '../lib/dates'
import { goalURL } from '../lib/transactions'
import { TGoal } from '../lib/types'
import { Currency } from './Numeral'
import { ProgressBar } from './ProgressBar'

export interface BudgetItemProps {
  goals: TGoal[]
}

export function budgetColor(num: number, goal: TGoal) {
  const total =
    parseFloat(goal.amount) + (parseFloat(goal.discretionary) * 4 || 0)
  const progress = num / total
  if (progress > 0.99) {
    return 'danger'
  }
  return 'success'
}

export const BudgetItem = ({
  goal,
  current,
}: {
  goal: TGoal
  current: number
}) => {
  let weeklyAmount = parseFloat(goal.discretionary ?? 0) * 4
  const expAmount = parseFloat(goal.amount) + weeklyAmount
  const currAmount = current ?? 0
  const progress = current / expAmount
  let remain = expAmount - currAmount
  return (
    <IonList key={goal.id} lines='none'>
      <IonItem
        className='goal-item'
        routerLink={goalURL(goal, current, '/goals/budget')}
        detail={false}
      >
        <IonAvatar slot='start'>💵</IonAvatar>
        <div className='content'>
          <div className='flex between'>
            <IonLabel slot='start' className='text-medium'>
              {goal.name}
            </IonLabel>
            <IonLabel slot='end' color='medium'>
              <Currency round value={abs(remain)} />{' '}
              {remain > 0 ? ' left' : ' over'}
            </IonLabel>
          </div>
          <ProgressBar value={progress} color={budgetColor(currAmount, goal)} />
        </div>
      </IonItem>
    </IonList>
  )
}

export const BudgetsList = ({ goals }: BudgetItemProps) => {
  const { currentMonth } = useSummaries({ startDate: startOfMonth(Day()) })
  const currentExpenses = currentMonth?.totals.expenses ?? 0
  return (
    <>
      {map(goals, (x) => (
        <BudgetItem key={x.id} goal={x} current={currentExpenses} />
      ))}
    </>
  )
}
