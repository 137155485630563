import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
// import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
// import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { getAuthToken } from './lib/firebase'

// Make sure to only include the library in development
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   })
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
      staleTime: 10 * 60 * 1000, // refetch after 10m
      cacheTime: 60 * 60 * 1000, // garbage collect after 1h
      retry: (count, error: any) => {
        if (error.response?.status === 401) {
          getAuthToken(true)
          return true
        }
        return [408, 429, 500, 502, 503, 504].includes(error.response?.status)
      },
    },
  },
})

// const localStoragePersistor = createWebStoragePersistor({
//   storage: window.localStorage,
// })

// persistQueryClient({
//   queryClient,
//   persistor: localStoragePersistor,
// })

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
