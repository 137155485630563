import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonText,
  IonToolbar,
} from '@ionic/react'
import {
  addOutline,
  chevronBackOutline,
  cloud,
  cloudOfflineOutline,
  folderOutline,
  removeCircle,
  trash,
} from 'ionicons/icons'
import { startCase } from 'lodash'
import { memo, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { useProfile } from '../../hooks/loaders/useProfile'
import { useAccountMutations } from '../../hooks/mutations/useAccountMutation'
import { balance } from '../../lib/transactions'
import { TAccount } from '../../lib/types'
import { queryString } from '../../lib/utils'
import { Avatar } from '../../pages/goals/GoalsPage'
import { SkeletonList } from '../../pages/Page'
import { Card } from '../Card'
import IconButton from '../IconButton'
import { Currency } from '../Numeral'
import { getMerchantLogo, merchantLogos } from '../transactions/constants'
import './accounts.scss'

export const AccountAvatar = memo(({ account }: { account: TAccount }) => {
  return account?.institution?.logo ? (
    <img
      className='account_logo'
      src={`data:image/png;base64, ${account.institution.logo}`}
      alt={account.institution.name}
    />
  ) : (
    <div className='account_logo is-txt text-black'>
      {account?.icon ??
        getMerchantLogo({
          merchant_name: account.name,
          label: account.institution?.name || account.official_name,
        }) ??
        account.name[0]}
    </div>
  )
})

export const AccountCard = ({
  account,
  routerLink,
}: {
  account: TAccount
  routerLink?: string
}) => {
  const [isDisabled, setDisabled] = useState(false)
  const { delAccount } = useAccountMutations(account)
  let mask = ' ••• ' + account.mask
  if (!account.mask) {
    mask = account.type === 'credit' ? 'Credit' : `Balance`
  }
  const isExpired = account.status === 'expired'
  return (
    <IonList className='ion-no-padding'>
      <IonItemSliding disabled={isDisabled}>
        <IonItem detail={false} disabled={isDisabled} routerLink={routerLink}>
          <IonAvatar slot='start'>
            <AccountAvatar account={account} />
          </IonAvatar>
          <IonLabel>
            <h2 style={{ marginBottom: 3 }}>
              {account.name ?? account.official_name}
            </h2>
            <p>{`${startCase(account.subtype ?? '')} ${mask}`}</p>
          </IonLabel>
          <IonLabel color={isExpired ? 'medium' : 'default'} slot='end'>
            <div className='flex align-center'>
              {isExpired && (
                <IonIcon
                  icon={cloudOfflineOutline}
                  color='medium'
                  style={{ marginRight: '.5em' }}
                />
              )}
              <Currency value={balance(account)} />
            </div>
          </IonLabel>
        </IonItem>
        <IonItemOptions side='end'>
          <IonItemOption
            color='danger'
            disabled={isDisabled}
            onClick={() => {
              setDisabled(true)
              delAccount.mutate(account.account_id)
            }}
            slot='icon-only'
          >
            <IonIcon icon={trash} size='small' style={{ padding: '0 8px' }} />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </IonList>
  )
}

function getInterval(n: number) {
  for (let x of [34, 21, 13, 8, 5, 3, 2, 1, 1]) if (x < n) return x
  return n
}

export function AccountsList({
  history,
}: {
  history: RouteComponentProps['history']
}) {
  const { hasPaywall } = useProfile()
  let interval = 1
  const isCurrentPage = history.location.pathname === '/accounts'
  const { accounts, accountTypes, isFetchingAccounts } = useBankAccounts(
    {
      include: 'institution',
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: () => getInterval(interval++) * 1000,
      enabled: global.location.pathname === '/accounts',
    }
  )

  const currentAccounts = accounts?.length ?? 0
  console.log('accounts', accountTypes)

  // const balanceAmount = useMemo(() => {
  //   if (!accounts || accounts.length < 1) return 0
  //   let depository = accounts
  //     ?.filter((a) => a.type === 'depository')
  //     .map((a) => Number(a.available_balance))
  //   return sum(depository)
  // }, [accounts])

  const accountItems = useMemo(() => {
    const investment = accountTypes['investment']?.accounts ?? []
    const asset = accountTypes['asset']?.accounts ?? []
    const credit = accountTypes['credit']?.accounts ?? []
    const depository = accountTypes['depository']?.accounts ?? []
    const savings = accountTypes['savings']?.accounts ?? []
    const loan = accountTypes['loan']?.accounts ?? []
    const other = accountTypes['other']?.accounts ?? []
    return (
      <>
        <AccountSection
          isLoading={isFetchingAccounts}
          title='Cash'
          accounts={depository?.concat(savings)}
          total={currentAccounts}
          hasPaywall={hasPaywall}
        />
        {/* <AccountSection isLoading={isFetchingAccounts} title="Savings" accounts={savings} /> */}
        <AccountSection
          isLoading={isFetchingAccounts}
          title='Credit'
          accounts={credit}
          total={currentAccounts}
          hasPaywall={hasPaywall}
        />
        <AccountSection
          isLoading={isFetchingAccounts}
          title='Investments'
          accounts={investment.concat(asset)}
          total={currentAccounts}
          hasPaywall={hasPaywall}
        />
        <AccountSection
          isLoading={isFetchingAccounts}
          title='Loans'
          accounts={loan}
          total={currentAccounts}
          hasPaywall={hasPaywall}
        />
        {other.length > 0 && (
          <AccountSection
            isLoading={isFetchingAccounts}
            title='Others'
            accounts={other}
            total={currentAccounts}
            hasPaywall={hasPaywall}
          />
        )}
      </>
    )

    // return map(groups, (accounts, key) => {
    //   return (
    //     <IonList key={key} lines="none">
    //       <IonListHeader>
    //         <IonLabel>
    //           <h2>{startCase(pluralize(key))}</h2>
    //         </IonLabel>
    //         <IconButton
    //           icon={addCircle}
    //           routerLink="/plaid/start"
    //           color="dark"
    //         />
    //       </IonListHeader>
    //       {accounts.map((account) => (
    //         <AccountCard
    //           key={account.account_id}
    //           account={account}
    //           routerLink={`/accounts/${account.account_id}`}
    //         />
    //       ))}
    //     </IonList>
    //   )
    // })
  }, [isFetchingAccounts, currentAccounts, accountTypes])

  return (
    <>
      {/* <div className="accounts_balance">
        <h3>Balance</h3>
        <h3>
          <Currency value={balanceAmount} />
        </h3>
      </div> */}
      <IonList>{accountItems}</IonList>
    </>
  )
}

function AccountSection({
  title,
  accounts,
  isLoading,
  total,
  hasPaywall,
}: {
  title: string
  accounts: TAccount[]
  isLoading?: boolean
  total: number
  hasPaywall: (p: boolean, url: string) => any
}) {
  const linkedAccounts = useMemo(() => {
    if (isLoading) {
      return <SkeletonList />
    }
    return accounts.map((a) => (
      <AccountCard
        key={a.account_id}
        account={a}
        routerLink={`/accounts/${a.account_id}${queryString({
          title: a.name,
          type: a.subtype,
          mask: a.mask,
        })}`}
      />
    ))
  }, [isLoading, accounts])

  const [isOpenAddDialog, setOpenAddDialog] = useState(false)
  const dismissAddDialog = () => setOpenAddDialog(false)

  const [isOpenAccountDialog, setOpenAccountDialog] = useState(false)

  return (
    <IonList lines='none'>
      <IonListHeader>
        <IonLabel>{title}</IonLabel>
      </IonListHeader>

      {linkedAccounts}

      <IonItem detail={false} onClick={() => setOpenAddDialog(true)}>
        <IonIcon slot='start' icon={addOutline} className='account_icon' />
        <IonLabel>
          <IonText color='medium'>
            <h2>Add account</h2>
          </IonText>
        </IonLabel>
      </IonItem>

      <IonModal
        isOpen={isOpenAddDialog}
        onDidDismiss={dismissAddDialog}
        canDismiss
        // presentingElement={rootElement}
      >
        <IonHeader>
          <IonToolbar>
            {/* <IonTitle>{title}</IonTitle> */}
            <IonButtons slot='start'>
              <IconButton
                color='primary'
                icon={chevronBackOutline}
                onClick={dismissAddDialog}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList className='ion-margin-bottom' lines='none'>
            <IonItem>
              <h1>Add Account</h1>
            </IonItem>
          </IonList>

          <IonList lines='none'>
            <Card>
              <IonItem
                className='transaction-item ion-no-padding'
                detail={false}
                onClick={(event) => {
                  dismissAddDialog()
                  hasPaywall(total >= 3, `/plaid/start`)(event)
                }}
                style={{ marginTop: -15 }}
              >
                <IonIcon
                  className='account_icon'
                  icon={cloud}
                  color='primary'
                  slot='start'
                />
                <h2>Connect to your bank</h2>
              </IonItem>
              <div className='flex align-middle'>
                <IonLabel className='tx-name'>
                  <p>
                    Automatically sync your account details and transactions
                    with Plaid. We never store your banking credentials.
                  </p>
                </IonLabel>
              </div>
            </Card>

            <Card>
              <IonItem
                className='transaction-item ion-no-padding'
                detail={false}
                onClick={(event) => {
                  setOpenAccountDialog(true)
                }}
                style={{ marginTop: -15 }}
              >
                <IonIcon
                  className='account_icon'
                  icon={folderOutline}
                  color='primary'
                  slot='start'
                />
                <h2>Manual account</h2>
              </IonItem>
              <div className='flex align-middle'>
                <IonLabel className='tx-name'>
                  <p>
                    Enter your account balances and transactions manually or
                    from account statements, CSV files, and others.
                  </p>
                </IonLabel>
              </div>
            </Card>
          </IonList>
        </IonContent>
      </IonModal>

      <ManualAccounts
        isOpen={isOpenAccountDialog}
        onDismiss={() => {
          setOpenAccountDialog(false)
          dismissAddDialog()
        }}
      />
    </IonList>
  )
}

const AccountItem = ({
  label,
  icon,
  hasPaywall,
  type,
  currency = 'USD',
}: {
  type?: string
  currency?: string
  label: string
  icon: string
  hasPaywall: (p: boolean, pass: string) => any
}) => (
  <IonCard
    className='flex align-center'
    onClick={hasPaywall(
      false,
      `/add/manual/?type=${type}&name=${label}&currency=${currency}&icon=${icon}`
    )}
  >
    <div className='flex column center align-center'>
      <Avatar>{icon}</Avatar>
      <h6>{label}</h6>
    </div>
  </IonCard>
)

const ManualAccounts = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean
  onDismiss: any
}) => {
  const { hasPaywall } = useProfile()

  const withPaywall = useMemo(() => {
    return (test: boolean, url: string) => (e: any) => {
      onDismiss()
      hasPaywall(test, url)(e)
    }
  }, [hasPaywall, onDismiss, isOpen])

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          {/* <IonTitle>{title}</IonTitle> */}
          <IonButtons slot='start'>
            <IconButton
              color='primary'
              icon={chevronBackOutline}
              onClick={onDismiss}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonListHeader>
            <h1>Manual Account</h1>
          </IonListHeader>
        </IonList>

        <IonList className='ion-margin-vertical'>
          <IonListHeader>Deposits</IonListHeader>
          <div className='grid-cards grid-md-3 grid-lg-4'>
            <AccountItem
              type='depository'
              icon='💵'
              label='Cash'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='depository'
              icon='🏦'
              label='Checking'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='depository'
              icon='💰'
              label='Savings'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='depository'
              icon='💲'
              label='Other Deposit'
              hasPaywall={withPaywall}
            />
          </div>
        </IonList>

        <IonList>
          <IonListHeader>Investments and Assets</IonListHeader>
          <div className='grid-cards grid-md-3 grid-lg-4'>
            <AccountItem
              type='investment'
              icon='🏦'
              label='Brokerage'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='investment'
              icon='₿'
              label='Crypto'
              currency='BTC'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='asset'
              icon='🏠'
              label='Real Estate'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='asset'
              icon='🚘'
              label='Owned Car'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='asset'
              icon='💲'
              label='Other Asset'
              hasPaywall={withPaywall}
            />
          </div>
        </IonList>

        <IonList>
          <IonListHeader>Credit and Loans</IonListHeader>
          <div className='grid-cards grid-md-3 grid-lg-4'>
            <AccountItem
              type='credit'
              icon='💳'
              label='Credit Card'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='loan'
              icon='🚙'
              label='Auto Loan'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='loan'
              icon='🏠'
              label='Mortgage'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='loan'
              icon='🏦'
              label='Student Loan'
              hasPaywall={withPaywall}
            />
            <AccountItem
              type='loan'
              icon='💲'
              label='Other Loan'
              hasPaywall={withPaywall}
            />
          </div>
        </IonList>
      </IonContent>
    </IonModal>
  )
}

AccountsList.Empty = memo(() => {
  return (
    <IonCard
      style={{
        padding: '1.5rem 1.25rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IonCardHeader>
        <IonCardTitle>No Accounts</IonCardTitle>
      </IonCardHeader>

      <IonCardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p>Link your accounts to see balances</p>

        <IonButton className='ion-margin-top' routerLink='/plaid/start'>
          Add Account
        </IonButton>
      </IonCardContent>
    </IonCard>
  )
})
