import {
  IonApp,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
  useIonRouter,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import {
  homeOutline,
  homeSharp,
  person,
  personOutline,
  pieChart,
  pieChartOutline,
  search,
  searchOutline,
  wallet,
  walletOutline,
} from 'ionicons/icons'
import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { Redirect, Route } from 'react-router-dom'
import ManualAccountPage from './components/accounts/ManualAccounts'
import AccountsPage from './containers/AccountsPage/AccountsPage'
import { store } from './lib/store'
import AccountPage from './pages/account/AccountPage'
import AccountPreferences from './pages/account/AccountSettings'
import CustomerInfoPage from './pages/account/CustomerInfoPage'
import BudgetPage from './pages/budget/BudgetPage'
import NewBudgetPage from './pages/budget/NewBudgetPage'
import { CashflowPage } from './pages/cashflow/CashflowPage'
import CarLoanPage from './pages/goals/CarLoanPage'
import GoalPage from './pages/goals/GoalPage'
import GoalsHistoryPage from './pages/goals/GoalsHistoryPage'
import GoalsPage from './pages/goals/GoalsPage'
import HomeLoanPage from './pages/goals/HomeLoanPage'
import LoanCalculatorPage from './pages/goals/LoanCalculatorPage'
import NewGoalPage from './pages/goals/NewGoalPage'
import { SharedGoalPage } from './pages/goals/SharedGoal'
import LoginPage from './pages/login/LoginPage'
import Plaid from './pages/plaid/Plaid'
import PersonalInfo from './pages/settings/PersonalInfo'
import SettingsPage from './pages/settings/SettingsPage'
import SubscriptionPage from './pages/subscription/SubscriptionPage'
import TransactionsPage from './pages/transactions/TransactionsPage'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
/* Theme variables */
import './theme/base.scss'

setupIonicReact()

// const useUpdateToast = () => {
//   const [showToast] = useIonToast()
//   useEffect(() => {
//     // Register on mount
//     serviceWorkerRegistration.register({
//       onUpdate: (registration) => {
//         // Display update notification
//         const sw = registration.waiting
//         showToast({
//           message: 'A new version is available',
//           cssClass: 'sw-update-toast',
//           buttons: [
//             {
//               text: 'Update',
//               side: 'end',
//               handler: () => {
//                 sw!.postMessage({ type: 'SKIP_WAITING' })
//                 window.location.reload()
//               },
//             },
//           ],
//         })
//       },
//     })
//   }, [])
// }

const App = () => {
  const user = store.useState((s) => s.user)
  const routerRef = useRef(null)

  useEffect(
    () =>
      store.update((s) => {
        s.presentingElement = routerRef.current
      }),
    [routerRef.current]
  )

  const routes = useMemo(
    () => (
      <IonRouterOutlet id='main' ref={routerRef}>
        <Route
          exact
          path='/'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <GoalsPage {...props} />
          }}
        />
        <Route
          exact
          path='/settings'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <SettingsPage {...props} />
          }}
        />
        <Route
          exact
          path='/settings/personal'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <PersonalInfo {...props} />
          }}
        />
        <Route exact path='/login' component={LoginPage} />
        <Route
          exact
          path='/plaid/start/:item_id?/:account_id?'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <Plaid {...props} />
          }}
        />
        <Route
          exact
          path='/plaid/oauth'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <Plaid {...props} />
          }}
        />
        <Route
          exact
          path='/add/manual/'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <ManualAccountPage key={props.location.search} {...props} />
          }}
        />
        <Route
          exact
          path='/accounts'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <AccountsPage key={''} {...props} />
          }}
        />
        <Route
          exact
          path='/a/:id'
          render={(props) => {
            if (!user)
              return (
                <Redirect
                  to={`/login?path=${encodeURIComponent(props.match.url)}`}
                />
              )
            return <AccountPage key={props.match.params.id} {...props} />
          }}
        />
        <Route
          exact
          path='/accounts/pref/:id'
          render={(props) => {
            if (!user)
              return (
                <Redirect
                  to={`/login?path=${encodeURIComponent(props.match.url)}`}
                />
              )
            return <AccountPreferences key={props.match.params.id} {...props} />
          }}
        />
        <Route
          exact
          path='/accounts/:account_id'
          render={(props) => {
            if (!user)
              return (
                <Redirect
                  to={`/login?path=${encodeURIComponent(props.match.url)}`}
                />
              )
            return (
              <AccountPage key={props.match.params.account_id} {...props} />
            )
          }}
        />
        <Route
          exact
          path='/customers/:account_id'
          render={(props) => {
            if (!user)
              return (
                <Redirect
                  to={`/login?path=${encodeURIComponent(props.match.url)}`}
                />
              )
            return (
              <CustomerInfoPage
                key={props.match.params.account_id}
                {...props}
              />
            )
          }}
        />
        <Route
          exact
          path='/transactions'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return (
              <TransactionsPage
                presentingElement={routerRef.current}
                {...props}
              />
            )
          }}
        />
        <Route
          exact
          path='/cashflow'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return (
              <CashflowPage presentingElement={routerRef.current} {...props} />
            )
          }}
        />
        <Route
          exact
          path='/goals'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <GoalsPage {...props} />
          }}
        />
        <Route
          exact
          path='/goals/history'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <GoalsHistoryPage {...props} />
          }}
        />
        <Route
          exact
          path='/goals/new'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <NewGoalPage {...props} />
          }}
        />
        <Route
          path='/goals/edit/:id'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <GoalPage key={props.match.params.id} {...props} />
          }}
        />
        <Route
          exact
          path='/goals/budget'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <NewBudgetPage {...props} />
          }}
        />
        <Route
          exact
          path='/g/:slug'
          render={(props) => {
            if (!user) {
              return (
                <Redirect
                  to={`/login?path=${encodeURIComponent(props.match.url)}`}
                />
              )
            }
            return <SharedGoalPage {...props} />
          }}
        />
        <Route
          exact
          path='/goals/budget/edit/:id'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <BudgetPage key={props.match.params.id} {...props} />
          }}
        />
        <Route
          exact
          path='/loan/calc/car'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <CarLoanPage {...props} />
          }}
        />
        <Route
          exact
          path='/loan/calc/other'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <LoanCalculatorPage {...props} />
          }}
        />
        <Route
          exact
          path='/loan/calc/house'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <HomeLoanPage {...props} />
          }}
        />
        <Route
          exact
          path='/subscription'
          render={(props) => {
            if (!user) return <Redirect to='/login' />
            return <SubscriptionPage {...props} />
          }}
        />

        <Route
          exact
          path='/referrer/:code'
          render={(props) => {
            if (!user) {
              return (
                <Redirect to={`/login?referrer=${props.match.params.code}`} />
              )
            }
            return <GoalsPage {...props} />
          }}
        />
      </IonRouterOutlet>
    ),
    [user]
  )

  // useUpdateToast()
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId='main' when='md'>
          <IonMenu contentId='main' side='start'>
            <IonHeader>
              <IonToolbar color='tertiary'>
                <IonTitle>
                  Clear<span style={{ color: '#22e7e8' }}>money</span>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList className='sparse split-menu-nav' lines='none'>
                <IonMenuToggle autoHide={false}>
                  <IonItem routerLink='/' detail={false}>
                    <IonIcon slot='start' icon={homeOutline} />
                    <IonLabel>Home</IonLabel>
                  </IonItem>
                  <IonItem routerLink='/accounts' detail={false}>
                    <IonIcon slot='start' icon={walletOutline} />
                    <IonLabel>Accounts</IonLabel>
                  </IonItem>
                  <IonItem routerLink='/transactions' detail={false}>
                    <IonIcon slot='start' icon={searchOutline} />
                    <IonLabel>Activity</IonLabel>
                  </IonItem>
                  <IonItem routerLink='/cashflow' detail={false}>
                    <IonIcon slot='start' icon={pieChartOutline} />
                    <IonLabel>Cashflow</IonLabel>
                  </IonItem>
                  <IonItem routerLink='/settings' detail={false}>
                    <IonIcon slot='start' icon={personOutline} />
                    <IonLabel>Settings</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              </IonList>
            </IonContent>
          </IonMenu>
          {routes}
        </IonSplitPane>
        {user ? <TabButtons routes={routes} /> : routes}
      </IonReactRouter>
    </IonApp>
  )
}

export default App

const TabButtons = ({ routes }: { routes: ReactNode }) => {
  const {
    routeInfo: { pathname },
  } = useIonRouter()
  const isActive = (tab: string) => !!pathname?.match(new RegExp(tab + '\\/?$'))
  return (
    <IonTabs className='ion-hide-md-up'>
      {routes}
      <IonTabBar slot='bottom'>
        <IonTabButton tab='/goals' href='/goals'>
          <IonIcon
            style={{ fontSize: '17pt' }}
            icon={
              pathname === '/' || isActive('/goals') ? homeSharp : homeOutline
            }
          />
          {/* <IonLabel>Dashboard</IonLabel> */}
          {/* <IonBadge></IonBadge> */}
        </IonTabButton>

        <IonTabButton tab='/transactions' href='/transactions'>
          <IonIcon icon={search} style={{ fontSize: '17pt' }} />
          {/* <IonLabel>Search</IonLabel> */}
          {/* <IonBadge>6</IonBadge> */}
        </IonTabButton>

        <IonTabButton tab='/accounts' href='/accounts'>
          <IonIcon
            style={{ fontSize: '17pt' }}
            icon={isActive('/accounts') ? wallet : walletOutline}
          />
          {/* <IonLabel>Accounts</IonLabel> */}
          {/* <IonBadge>6</IonBadge> */}
        </IonTabButton>

        <IonTabButton tab='/cashflow' href='/cashflow'>
          <IonIcon
            style={{ fontSize: '17pt' }}
            icon={isActive('/cashflow') ? pieChart : pieChartOutline}
          />
          {/* <IonLabel>Trends</IonLabel> */}
          {/* <IonBadge>6</IonBadge> */}
        </IonTabButton>

        <IonTabButton tab='/settings' href='/settings'>
          <IonIcon
            style={{ fontSize: '17pt' }}
            icon={isActive('/settings') ? person : personOutline}
          />
          {/* <IonLabel>Profile</IonLabel> */}
          {/* <IonBadge>6</IonBadge> */}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}
