import React from 'react'

export function QRCodeImg({
  size = 111,
  url,
  style = {},
  onClick,
}: {
  size?: number
  url: string
  style?: any
  onClick?: VoidFunction
}) {
  let minWidth = size,
    minHeight = size
  return (
    <img
      alt='QR Code'
      src={`https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${url}`}
      onClick={onClick}
      style={{
        background: 'white',
        padding: '1rem',
        border: '1px solid #9d9fa6',
        boxSizing: 'border-box',
        borderRadius: 20,
        minWidth,
        minHeight,
        ...style,
      }}
    />
  )
}
