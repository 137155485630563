import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { NumberInput, PercentInput } from '../../components/AmountInput'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { cash } from '../../components/Numeral'
import { useManualAccount } from '../../hooks/mutations/useAccountMutation'
import { urlParams } from '../../lib/api'
import { Page } from '../../pages/Page'

export default function ManualAccountPage({
  history,
}: RouteComponentProps<{}>) {
  const initialValues = urlParams()
  const [values, setState] = useState<any>(initialValues)
  console.log('account state', values)
  const [disabled, setDisabled] = useState(false)
  const setValue = (data: any) => setState({ ...values, ...data })

  const { addAccount } = useManualAccount()

  useEffect(() => {
    // Reset the stored state when going back
    setState(initialValues)
  }, [history])

  const available_balance = useMemo(() => {
    const { current_balance, balance_limit } = values
    if (current_balance && balance_limit) {
      return balance_limit - current_balance
    }
    return null
  }, [values.current_balance, values.balance_limit])

  const [toast] = useIonToast()

  const handleSubmit = async () => {
    let success
    try {
      setDisabled(true)
      await addAccount.mutateAsync(
        { ...values, available_balance },
        {
          onSuccess: () => history.replace('/accounts'),
          onError: async () =>
            await toast('Something went wrong, please try again', 5000),
        }
      )
      success = true
    } finally {
      setDisabled(false)
      if (success) history.goBack()
    }
  }

  return (
    <Page
      start={
        <IonButtons slot='start'>
          <IonBackButton />
        </IonButtons>
      }
      footer={
        <IonFooter>
          <IonToolbar>
            <IonButton
              color='primary'
              expand='block'
              disabled={disabled || !values.current_balance}
              onClick={handleSubmit}
            >
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <IonList className='ion-margin-top sparse'>
        <IonItem>
          <IonLabel position='floating'>Name</IonLabel>
          <IonInput
            required
            type='text'
            value={values.name}
            placeholder='Name'
            inputMode='text'
            maxlength={180}
            minlength={1}
            onIonChange={(e) => {
              setValue({ name: e.detail.value })
            }}
            style={{ textAlign: 'left', fontSize: 20 }}
          />
        </IonItem>

        {/* <IonItem>
          <IonLabel position='fixed'>Icon</IonLabel>
          <IonInput
            required
            type='text'
            value={values.icon}
            placeholder='emoji'
            inputMode='text'
            maxlength={180}
            minlength={1}
            onIonChange={(e) => {
              setValue({ icon: e.detail.value })
            }}
            size={20}
            style={{ textAlign: 'right', fontSize: 20 }}
          />
        </IonItem> */}

        <IonItem>
          <LabelWithInfo label='Balance' icon={null} />
          <NumberInput
            slot='end'
            value={values.current_balance}
            placeholder={cash(0)}
            onChange={(current_balance) => setValue({ current_balance })}
            style={{ fontSize: 20 }}
            rightAlign
          />
        </IonItem>

        {values.type === 'credit' && (
          <IonItem>
            <LabelWithInfo label='Credit Limit' icon={null} />
            <NumberInput
              slot='end'
              value={values.balance_limit}
              placeholder={cash(0)}
              onChange={(balance_limit) => setValue({ balance_limit })}
              style={{ fontSize: 20 }}
              rightAlign
            />
          </IonItem>
        )}

        {values.type === 'credit' && (
          <IonItem>
            <LabelWithInfo label='Available Credit' icon={null} />
            <NumberInput
              slot='end'
              value={available_balance}
              placeholder={cash(0)}
              onChange={(available_balance) => setValue({ available_balance })}
              style={{ fontSize: 20 }}
              rightAlign
            />
          </IonItem>
        )}

        {(values.type === 'credit' || values.type === 'loan') && (
          <PercentInput
            label='Interest Rate (APR)'
            slot='end'
            rightAlign
            required
            value={values.interest_rate}
            placeholder='%'
            onChange={(interest_rate) => {
              setValue({ interest_rate })
            }}
            style={{ fontSize: 20 }}
          />
        )}

        {(values.type === 'depository' || values.type === 'savings') && (
          <PercentInput
            label='Interest Rate (APY)'
            slot='end'
            rightAlign
            value={values.interest_rate}
            placeholder='0'
            onChange={(interest_rate) => {
              setValue({ interest_rate })
            }}
            style={{ fontSize: 20 }}
          />
        )}

        <IonItem>
          <LabelWithInfo label='Currency' icon={null} />
          <IonSelect
            placeholder='Select'
            slot='end'
            interface='popover'
            onIonChange={(e) => {
              let value = e.detail.value
              setValue({ currency: value })
            }}
            value={values.currency ?? 'USD'}
          >
            <IonSelectOption value='USD'>USD</IonSelectOption>
            <IonSelectOption value='CAD'>CAD</IonSelectOption>
            <IonSelectOption value='EUR'>EUR</IonSelectOption>
            <IonSelectOption value='GBP'>GBP</IonSelectOption>
            <IonSelectOption value='BTC'>BTC</IonSelectOption>
            <IonSelectOption value='ETH'>ETH</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem>
          <LabelWithInfo label='Type' icon={null} />
          <IonSelect
            placeholder='Select'
            slot='end'
            interface='popover'
            onIonChange={(e) => {
              let value = e.detail.value
              setValue({ type: value })
            }}
            value={values.type}
          >
            {/* <IonSelectOption value={null}></IonSelectOption> */}
            <IonSelectOption value='depository'>Debit</IonSelectOption>
            <IonSelectOption value='savings'>Savings</IonSelectOption>
            <IonSelectOption value='credit'>Credit</IonSelectOption>
            <IonSelectOption value='loan'>Loan</IonSelectOption>
            <IonSelectOption value='investment'>Investment</IonSelectOption>
            <IonSelectOption value='asset'>Asset</IonSelectOption>
          </IonSelect>
        </IonItem>

        {/* TIPS: <IonItem lines='none'>
          <IonLabel>Est savings</IonLabel>
          <IonLabel slot='end'>
            {values.start_amount && values.amount && (
              <Currency round value={remaining} />
            )}
          </IonLabel>
        </IonItem>

        {isEnoughSaved && (
          <IonItem lines='none'>
            <IonIcon slot='start' color='success' icon={checkmarkCircleSharp} />
            <IonLabel color='medium' className='ion-text-wrap'>
              <p>
                Great! You're saving {percent(remaining / values.start_amount)}{' '}
                of your income.
              </p>
            </IonLabel>
          </IonItem>
        )} */}
      </IonList>

      <IonList className='sparse ion-margin-vertical' lines='none'>
        <IonListHeader lines='none'>
          <IonLabel>
            <h3 style={{ fontWeight: 900 }}>Actions</h3>
          </IonLabel>
        </IonListHeader>

        <IonItem>
          <IonLabel>Include in Balances</IonLabel>
          <IonToggle
            color='primary'
            slot='end'
            checked={!values.hide_balances}
            onIonChange={(e) => {
              setValue({ hide_balances: !e.detail.checked })
            }}
          />
        </IonItem>

        <IonItem>
          <IonLabel>Show in Budgets &amp; Trends</IonLabel>
          <IonToggle
            color='primary'
            slot='end'
            checked={!values.hide_trends}
            onIonChange={(e) => {
              setValue({ hide_trends: !e.detail.checked })
            }}
          />
        </IonItem>
      </IonList>
    </Page>
  )
}
