import { IonAvatar } from '@ionic/react'

export function UserAvatar({
  photoURL,
  displayName,
  end,
}: {
  photoURL?: string | null
  displayName?: string | null
  end?: false
}) {
  return (
    <IonAvatar slot={end ? 'end' : 'start'}>
      <img
        src={photoURL ?? `https://www.gravatar.com/avatar/?d=mp&s=190`}
        alt={displayName ?? ''}
        referrerPolicy='no-referrer'
      />
    </IonAvatar>
  )
}
