import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonToggle,
  useIonAlert,
  useIonToast,
} from '@ionic/react'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { NumberInput, PercentInput } from '../../components/AmountInput'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { cash } from '../../components/Numeral'
import { SharedUsersModal } from '../../components/SharedUsersModal'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { useAccountMutations } from '../../hooks/mutations/useAccountMutation'
import { urlParams } from '../../lib/api'
import { store } from '../../lib/store'
import { TAccount } from '../../lib/types'
import { Page } from '../Page'
import { AccountHero, AccountPageProps } from './AccountPage'

function includes(obj: any, other: any, field?: string) {
  if (field != undefined) return obj[field] == other[field]
  for (const key in obj) {
    if (obj[key] != other[key]) return false
  }
  return true
}

export default function AccountPreferences({
  match,
  history,
}: AccountPageProps) {
  const { id, account_id } = match.params
  let accountById = { account_id, include: 'institution', id }
  const { accounts, isFetchingAccounts } = useBankAccounts(accountById)

  const [state, setState] = useState({
    ...urlParams(),
  } as any)

  const account = useMemo(() => accounts?.[0], [accounts])

  useEffect(() => account && setState(account), [account])

  const { updateAccount } = useAccountMutations(account, history)

  const [toast] = useIonToast()
  const showToast = {
    onSuccess: async () => await toast('Your changes have been saved', 5000),
    onError: async () =>
      await toast('Something went wrong, please try again.', 5000),
  }

  const setValue = (data: any) => {
    console.log('changed value', data, state, account)
    setState({ ...state, ...data })
    // Automatic mutations on updates
    if (account && !includes(data, account)) {
      updateAccount.mutate(data, showToast)
    }
  }

  return (
    <Page isLoading={isFetchingAccounts} title='Account settings'>
      <AccountHero account={state as TAccount} />
      {account && (
        <AccountSettings
          account={account}
          edit={{ ...account, ...state }}
          setValue={setValue}
          history={history}
        />
      )}
    </Page>
  )
}

function AccountSettings({
  account,
  edit,
  setValue,
  history,
  onDeleteStart,
  onDeleteSettled,
}: {
  account: TAccount
  edit: Partial<TAccount>
  history: RouteComponentProps<any>['history']
  setValue: (data: any) => any
  onDeleteStart?: VoidFunction
  onDeleteSettled?: VoidFunction
}) {
  const user = store.useState((s) => s.user)
  const { delAccount, leaveAccount, stopSharing } = useAccountMutations(
    account,
    history
  )

  const [alert] = useIonAlert()
  const [isLoading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const isOwner = account.user_id === user?.uid

  const [toast] = useIonToast()
  const showToast = {
    onSuccess: async () => await toast('Your changes have been saved', 5000),
    onError: async () =>
      await toast('Something went wrong, please try again.', 5000),
  }

  const AccountStatus = () => (
    <IonList className='sparse' lines='full'>
      <IonItem>
        <IonLabel position='fixed'>Name</IonLabel>
        <IonInput
          required
          debounce={1000}
          slot='end'
          type='text'
          value={edit.name}
          placeholder='Name'
          inputMode='text'
          maxlength={180}
          minlength={1}
          onIonChange={(e) => {
            setValue({ name: e.detail.value! })
          }}
          style={{ textAlign: 'right' }}
        />
      </IonItem>

      <IonItem>
        <IonLabel position='fixed'>Icon</IonLabel>
        <IonInput
          debounce={500}
          required
          type='text'
          slot='end'
          value={edit.icon}
          placeholder='emoji'
          inputMode='text'
          maxlength={180}
          minlength={1}
          onIonChange={(e) => {
            setValue({ icon: e.detail.value })
          }}
          style={{ textAlign: 'right' }}
        />
      </IonItem>

      <IonItem>
        <LabelWithInfo label='Balance' icon={null} />
        <NumberInput
          slot='end'
          value={edit.current_balance}
          placeholder={cash(0)}
          onChange={(current_balance) => setValue({ current_balance })}
          style={{}}
          rightAlign
        />
      </IonItem>

      {account.type === 'credit' && (
        <IonItem>
          <LabelWithInfo label='Credit Limit' icon={null} />
          <NumberInput
            slot='end'
            value={edit.balance_limit}
            placeholder={cash(0)}
            onChange={(balance_limit) => setValue({ balance_limit })}
            style={{}}
            rightAlign
          />
        </IonItem>
      )}

      {(account.type === 'depository' || account.type === 'savings') && (
        <PercentInput
          label='Interest Rate (APY)'
          slot='end'
          rightAlign
          value={edit.interest_rate}
          placeholder='0'
          onChange={(interest_rate) => {
            setValue({ interest_rate })
          }}
        />
      )}

      {(account.type === 'credit' || account.type === 'loan') && (
        <PercentInput
          label='Interest Rate (APR)'
          slot='end'
          rightAlign
          required
          value={edit.interest_rate}
          placeholder='%'
          onChange={(interest_rate) => {
            setValue({ interest_rate: interest_rate })
          }}
          style={{}}
        />
      )}

      {/* <IonItem lines='none'>
        <IonLabel>Last Updated</IonLabel>
        <IonLabel slot='end'>
          <DateTime value={account.updated_at} />
        </IonLabel>
      </IonItem> */}

      {account.item_id !== null && (
        <IonItem
          routerLink={`/plaid/start/${account.item_id}/${account.account_id}`}
          detail
        >
          <IonLabel color='primary'>Update Account …</IonLabel>
        </IonItem>
      )}
    </IonList>
  )

  if (isOwner) {
    return (
      <>
        <IonLoading isOpen={isLoading} duration={10000} message='Deleting...' />
        <AccountStatus />

        <IonList className='sparse ion-margin-vertical' lines='none'>
          <IonListHeader lines='none'>
            <IonLabel>
              <h3 style={{ fontWeight: 900 }}>Actions</h3>
            </IonLabel>
          </IonListHeader>

          <IonItem>
            <IonLabel>Include in Balances</IonLabel>
            <IonToggle
              color='primary'
              slot='end'
              checked={edit.hide_balances == false}
              onIonChange={(e) => {
                let hide_balances = !e.detail.checked
                if (hide_balances != edit.hide_balances)
                  setValue({ hide_balances })
              }}
            />
          </IonItem>

          <IonItem>
            <IonLabel>Show in Budgets & Trends</IonLabel>
            <IonToggle
              color='primary'
              slot='end'
              defaultChecked={!account.hide_trends}
              onIonChange={(e) => {
                let hide_trends = !e.detail.checked
                if (hide_trends != edit.hide_trends) setValue({ hide_trends })
              }}
            />
          </IonItem>

          {account.item_id && (
            <IonItem routerLink={`/customers/` + account.account_id} detail>
              <IonLabel color='primary'>Account Verification</IonLabel>
              {/* <IonToggle
              color='primary'
              slot='end'
              defaultChecked={customer?.status === 'verified'}
              onIonChange={(e) => {
                let isChecked = e.detail.checked
                if (customer?.status !== 'verified') {
                  setAutoSaving(isChecked)
                  history.push('/customers/' + account.account_id)
                } else {
                  // TODO: customer turns off auto save
                }
              }}
            /> */}
            </IonItem>
          )}

          {account.shared_users?.length > 0 && (
            <SharedUsersModal
              title='Sharing with'
              users={account.shared_users}
              onRemove={(user) =>
                leaveAccount.mutate(
                  {
                    account_id: account.account_id,
                    user_id: user.user_id,
                  },
                  showToast
                )
              }
              onStopSharing={() =>
                alert({
                  header: 'Stop Sharing',
                  message:
                    'This will revoke access to the account for all other users. Do you wish to continue?',
                  buttons: [
                    'No',
                    {
                      text: 'Yes',
                      handler: () => {
                        setLoading(true)
                        stopSharing.mutate(account, showToast)
                      },
                    },
                  ],
                })
              }
            />
          )}

          <IonItem
            disabled={disabled}
            onClick={() => {
              alert({
                header: 'Delete Account',
                message:
                  'This will remove the account and all related data. Do you wish to continue?',
                buttons: [
                  'No',
                  {
                    text: 'Yes',
                    handler: () => {
                      onDeleteStart?.()
                      setLoading(true)
                      delAccount.mutate(account.account_id!, {
                        ...showToast,
                        onSettled: () => {
                          setLoading(false)
                          onDeleteSettled?.()
                        },
                      })
                    },
                  },
                ],
              })
            }}
          >
            <IonLabel color='danger'>Delete Account</IonLabel>
          </IonItem>
        </IonList>
      </>
    )
  }

  return (
    <>
      <AccountStatus />

      <IonList className='sparse ion-margin-vertical' lines='none'>
        <IonListHeader lines='none'>
          <IonLabel>
            <h3 style={{ fontWeight: 900 }}>Actions</h3>
          </IonLabel>
        </IonListHeader>

        <IonItem>
          <IonLabel>Include in Balances</IonLabel>
          <IonToggle
            color='primary'
            slot='end'
            checked={!edit.hide_balances}
            onIonChange={(e) => {
              let hide_balances = !e.detail.checked
              if (hide_balances !== !edit.hide_balances)
                setValue({ hide_balances })
            }}
          />
        </IonItem>

        <IonItem>
          <IonLabel>Show in Budgets &amp; Trends</IonLabel>
          <IonToggle
            color='primary'
            slot='end'
            checked={!account.hide_trends}
            onIonChange={(e) => {
              let hide_trends = !e.detail.checked
              setValue({ hide_trends })
            }}
          />
        </IonItem>

        <IonItem
          disabled={disabled}
          onClick={() => {
            alert({
              header: 'Leave Shared Account',
              message:
                'Are you sure you want to leave the shared account and remove it from your accounts?',
              buttons: [
                'No',
                {
                  text: 'Yes',
                  handler: () => {
                    setDisabled(true)
                    leaveAccount.mutate(
                      { account_id: account.account_id, user_id: user!.uid },
                      {
                        onSuccess: () =>
                          toast('You left the shared account.', 5000),
                        onError: () =>
                          toast(
                            'Something went wrong, please try again.',
                            5000
                          ),
                        onSettled: () => setDisabled(false),
                      }
                    )
                  },
                },
              ],
            })
          }}
        >
          <IonLabel color='danger'>Leave Shared Account</IonLabel>
        </IonItem>
      </IonList>
    </>
  )
}
