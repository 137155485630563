import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { API_HOST, AUTH_TOKEN_KEY } from '../settings'
import {
  TSharedGoalBody,
  TAccount,
  TCustomer,
  TGoal,
  TTransaction,
  ApiResponse,
  TProfile,
} from './types'

export const client = axios.create({
  baseURL: API_HOST,
  timeout: 30 * 1000, // 30s
  headers: {
    'Content-Type': 'application/json',
    'X-Client-Type': 'www',
  },
})

function getAccessToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY) ?? ''
}

// Instantiate the interceptor
// createAuthRefreshInterceptor(
//   client,
//   async (prevRequest: any) => {
//     let token = await getAuthToken()
//     prevRequest.response.config.headers['Authorization'] = token
//     return Promise.resolve()
//   },
//   {
//     pauseInstanceWhileRefreshing: true,
//   }
// )

// Use interceptor to inject the token to requests
client.interceptors.request.use((req: any) => {
  req.headers['Authorization'] = getAccessToken()
  return req
})

client.interceptors.response.use((res) => {
  let body = res.data
  if (!body) return {}
  if ('next' in body) return body
  return body.data
})

export const createUser = async (user: any, accessToken?: string) => {
  return client.put('/users/new/', user, {
    headers: {
      Authorization: accessToken ?? '',
    },
  })
}

export async function createLinkToken(
  data: any
): Promise<{ link_token: string; expiration: string }> {
  return await client.post('/link/create', data)
}

export const exchangeToken = async (
  publicToken: string,
  institution: any,
  accounts: any[],
  item_id: string | null | undefined = undefined
) => {
  return client.post('/link/items', {
    publicToken,
    accounts,
    institution,
    item_id: item_id || undefined,
  })
}

export async function fetchAccounts(params = {}) {
  return client.get<TAccount[]>(`/accounts/`, params)
}

export const stopSharingAccount = async ({ account_id }: Partial<TAccount>) => {
  return client.delete(`/accounts/share`, { data: { account_id } })
}

export const leaveSharedAccount = async ({
  account_id,
  user_id,
}: Partial<TAccount>) => {
  return client.delete(`/accounts/share/u`, { data: { account_id, user_id } })
}

export const deleteAccount = async (account_id: string) => {
  return client.delete('/accounts/', { data: { account_id } })
}

export const deleteItemAccounts = async (item_id: string) => {
  return client.delete('/accounts/', { data: { item_id } })
}

export const deleteUserAccount = async (user_id: string) => {
  return client.delete('/accounts/', { data: { user_id } })
}

export const updateTransaction = async (data: Partial<TTransaction>) => {
  if (!data.id) return
  let res = await client.post(`/transactions/${data.id}`, { data })
  return res as unknown as TTransaction
}

export interface UpdateManySelector {
  account_id: string
  keywords: string
  merchant_name?: string
  direction: 'credit' | 'debit'
}
export const updateAllTransactions = async (
  where: UpdateManySelector,
  update: any
) => {
  return client.patch('/transactions/all', { where, update })
}

interface PaginatedResponse {
  next?: string
  data: TTransaction[]
}

export const requestNextTransactions = async (
  options: AxiosRequestConfig
): Promise<PaginatedResponse> => {
  return client(options)
}

export const refreshTransactions = async (item_id: string) => {
  return client.post('/transactions/refresh/', {
    item_id,
  })
}

export const updateGoalRequest = async (goal: Partial<TGoal>) => {
  return client.post('/goals/', { goal })
}

export const deleteGoalRequest = async (id: string) => {
  return client.delete('/goals/' + id)
}

export function urlParams() {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
}

export const startCheckout = async (
  price_id: string,
  email: string
): ApiResponse<any> => {
  return client.post('/checkout', { email, price_id })
}

export const getBillingSession = async (customer: string): ApiResponse<any> => {
  return client.post('/billing', { customer })
}

export const getProfile = async (): ApiResponse<TProfile> => {
  return client.get('/user/profile')
}

export async function joinSharedGoal({ goal_id, user_id }: any) {
  return client.post('/goals/share/', {
    goal_id,
    user_id,
  })
}

export async function leaveSharedGoal({ goal_id, user_id }: any) {
  return client.delete(`/goals/share/u`, { data: { goal_id, user_id } })
}

export async function updateGoal(data: TSharedGoalBody): ApiResponse<TGoal> {
  return client.patch('/goals/shared/' + data.goal_id, data)
}

export const updateCustomer = (data: any): ApiResponse<TCustomer> =>
  client.put(`/dwolla/customer/${data.account_id}`, data)
