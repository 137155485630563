import { TProfile } from '../../lib/types'
import { UserAvatar } from './UserAvatar'

export function AvatarStack({ profiles }: { profiles?: { user: TProfile }[] }) {
  let size = profiles?.length ?? 0
  return (
    <div className='flex avatar-stack'>
      {profiles?.map(({ user }) => {
        return (
          <div
            className='avatar-stack_item flex align-center'
            key={user.user_id}
            style={{ zIndex: size-- }}
          >
            <UserAvatar
              photoURL={user!.photo_url}
              displayName={user!.display_name}
            />
          </div>
        )
      })}
    </div>
  )
}
