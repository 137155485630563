import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToggle,
  IonToolbar,
} from '@ionic/react'
import {
  addOutline,
  calendarOutline,
  cardOutline,
  cashOutline,
  chevronBackOutline,
  documentTextOutline,
  flagOutline,
  pieChartOutline,
  repeatSharp,
  shuffleSharp,
} from 'ionicons/icons'
import { map, startCase } from 'lodash'
import { useState } from 'react'
import { NumberInput } from '../../components/AmountInput'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import IconButton from '../../components/IconButton'
import { Currency } from '../../components/Numeral'
import {
  getCategoryIcon,
  getMerchantLogo,
} from '../../components/transactions/constants'
import { CategoryPicker } from '../../components/transactions/TransactionsList'
import { useCategories } from '../../hooks/loaders/useCategories'
import { useGoals } from '../../hooks/loaders/useGoals'
import { useMutateTransaction } from '../../hooks/useFetch'
import { Day } from '../../lib/dates'
import { merchantName } from '../../lib/strings'
import { TAccount, TTransaction } from '../../lib/types'

export const ManualTransactionModal = ({ account }: { account?: TAccount }) => {
  const [isOpenTransaction, setOpenTransaction] = useState(false)
  // const rootElement = store.useState((s) => s.presentingElement)
  const { patchTransaction } = useMutateTransaction({})

  const [tx, setState] = useState<Partial<TTransaction>>({
    id: 'null',
    name: '',
    date: Day().toISOString(),
  })
  const setValue = (update: any) => {
    setState({ account_id: account?.account_id, ...tx, ...update })
  }

  async function handleSubmit() {
    await patchTransaction.mutateAsync(tx as TTransaction, {
      onSuccess: () => setOpenTransaction(false),
    })
  }

  if (!account) return null
  return (
    <>
      <IonFab
        className='ion-margin-top'
        vertical='bottom'
        horizontal='end'
        slot='fixed'
      >
        <IonFabButton onClick={() => setOpenTransaction(true)}>
          <IonIcon size='large' icon={addOutline} />
        </IonFabButton>
      </IonFab>
      <IonModal
        isOpen={isOpenTransaction}
        onDidDismiss={() => setOpenTransaction(false)}
      >
        <IonContent>
          <IonToolbar>
            <IonButtons slot='start'>
              <IconButton
                color='primary'
                icon={chevronBackOutline}
                onClick={() => setOpenTransaction(false)}
              />
            </IonButtons>
          </IonToolbar>
          <ManualTransaction account={tx} onChange={setValue} />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton
              className='ion-margin'
              expand='block'
              onClick={() => {
                handleSubmit()
              }}
            >
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}

export const ManualTransaction = ({
  account: tx,
  presentingElement,
  onChange,
}: {
  account: Partial<TTransaction>
  presentingElement?: HTMLElement
  onChange: (data: any) => any
}) => {
  const { activeGoals } = useGoals()
  const { categories } = useCategories()
  const [showCategories, setShowCategories] = useState(false)

  let displayName = merchantName(tx) || ''
  return (
    <>
      <CategoryPicker
        value={tx.subcategory}
        isOpen={showCategories}
        categories={categories}
        presentingElement={presentingElement}
        onClick={({ category, subcategory }) => {
          onChange({ category, subcategory })
        }}
        onDismiss={() => setShowCategories(false)}
      />

      <div className='detail_header ion-margin-top'>
        <IonAvatar className='detail_avatar'>
          {getMerchantLogo(tx) ?? displayName[0] ?? '?'}
        </IonAvatar>
        <h4>{displayName || 'New Transaction'}</h4>
        <h2>
          <Currency value={tx.amount} abs />
        </h2>
        <div>{tx.pending && <IonBadge color='medium'>Pending</IonBadge>}</div>
      </div>

      <IonToolbar className='toolbar-segment'>
        <IonSegment
          onIonChange={(e) =>
            onChange({
              direction: e.detail.value,
              amount: tx.amount,
              category: 'Transfer',
              subcategory: startCase(e.detail.value),
            })
          }
          value={tx.direction ?? 'credit'}
        >
          <IonSegmentButton value='debit'>
            <IonLabel>Income</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='credit'>
            <IonLabel>Expense</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      <IonList lines='none'>
        <IonItem key='amount'>
          <IonIcon size='small' slot='start' icon={cashOutline} />
          <IonLabel position='fixed'>Amount</IonLabel>
          <NumberInput
            value={tx.amount}
            placeholder='$20'
            onChange={(amount) => onChange({ amount })}
            style={{ fontSize: 20, marginRight: 10 }}
            rightAlign
          />
        </IonItem>

        <IonItem>
          <IonIcon size='small' slot='start' icon={calendarOutline} />
          <DatePicker
            onChange={(date) => onChange({ date })}
            value={tx.date}
            format='MMMM d'
            placeholder='Date'
          />
        </IonItem>

        <IonItem key='label'>
          <IonIcon size='small' slot='start' icon={cardOutline} />
          <IonLabel position='stacked'>Description</IonLabel>
          <IonInput
            value={tx.name}
            placeholder='New Transaction'
            onIonChange={(e) => {
              onChange({ name: e.detail.value })
            }}
          />
        </IonItem>

        {activeGoals && (
          <IonItem>
            <IonIcon size='small' slot='start' icon={flagOutline} />
            <IonLabel position='stacked'>This is for</IonLabel>
            <IonSelect
              interface='alert'
              interfaceOptions={{ header: 'Select goal' }}
              placeholder='Not Assigned'
              onIonChange={(e) => {
                onChange({ goal_id: e.detail.value })
              }}
              value={tx.goal_id}
            >
              <IonSelectOption value={null}>Not Assigned</IonSelectOption>
              {map(activeGoals, (a) => {
                return (
                  <IonSelectOption value={a.id} key={a.id}>
                    {a.name}
                  </IonSelectOption>
                )
              })}
            </IonSelect>
          </IonItem>
        )}
        <IonItem onClick={() => setShowCategories(true)}>
          <IonIcon size='small' slot='start' icon={pieChartOutline} />
          <IonLabel>
            {getCategoryIcon(tx)} {tx.subcategory ?? tx.category ?? 'Category'}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonIcon size='small' slot='start' icon={repeatSharp} />
          <IonLabel>Recurring</IonLabel>
          <IonSelect
            placeholder='Select'
            slot='end'
            interface='alert'
            onIonChange={(e) => {
              let value = e.detail.value
              if (value != null) value = parseInt(value)
              onChange({ isrecurring: value })
            }}
            value={tx.isrecurring}
          >
            <IonSelectOption value={null}>None</IonSelectOption>
            <IonSelectOption value={10}>Weekly</IonSelectOption>
            <IonSelectOption value={20}>Bi Weekly</IonSelectOption>
            <IonSelectOption value={100}>Monthly</IonSelectOption>
            <IonSelectOption value={300}>Quarterly</IonSelectOption>
            <IonSelectOption value={600}>Every 6 months</IonSelectOption>
            <IonSelectOption value={1000}>Yearly</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem>
          <IonIcon size='small' slot='start' icon={shuffleSharp} />
          <IonLabel>Transfer</IonLabel>
          <IonToggle
            color='primary'
            slot='end'
            onIonChange={(e) => {
              let value = e.detail.checked ? 1 : null
              onChange({ istransfer: value })
            }}
            checked={tx.istransfer != null}
          />
        </IonItem>

        {/* <IonItem>
          <IonIcon size='small' slot='start' icon={eyeOffOutline} />
          <IonLabel>Hide</IonLabel>
          <IonToggle
            color='primary'
            slot='end'
            onIonChange={(e) => {
              let value = e.detail.checked ? 1 : null
              onChange({ hidden: value })
            }}
            checked={tx.hidden != null}
          />
        </IonItem> */}
        <IonItem>
          <IonIcon size='small' slot='start' icon={documentTextOutline} />
          <IonLabel position='stacked'>Notes</IonLabel>
          <IonTextarea
            value={tx.notes}
            placeholder='Notes'
            onIonChange={(e) => {
              let value = e.detail.value
              onChange({ notes: value })
            }}
            maxlength={280}
          />
        </IonItem>
      </IonList>
    </>
  )
}
