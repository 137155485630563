import { IonAvatar, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { checkmarkCircleSharp } from 'ionicons/icons'
import { get, map, sum } from 'lodash'
import { abs } from 'mathjs'
import { ComponentProps, memo } from 'react'
import { useGoalTransactions } from '../hooks/loaders/useGoalTransactions'
import { Day } from '../lib/dates'
import { TGoal } from '../lib/types'
import { Currency } from './Numeral'
import { ProgressBar } from './ProgressBar'

export interface GoalItemProps {
  goals: TGoal[]
}

const BigCurrency = ({ value, ...props }: ComponentProps<typeof Currency>) => (
  <Currency
    round
    value={value}
    format={value >= 10000 ? '$0a' : '$0,0'}
    {...props}
  />
)

export const GoalItem = memo(
  ({ goal, current }: { goal: TGoal; current: number }) => {
    const expAmount = parseFloat(goal.amount)
    const progress = current / expAmount
    let remain = expAmount - current
    const isCompleted = progress > 0.9999

    let color = undefined
    if (isCompleted) {
      color = 'success'
    } else if (goal.target_date) {
      if (Day() > new Date(goal.target_date)) {
        color = 'error'
      }
    }

    return (
      <IonList lines='none'>
        <IonItem
          className='goal-item'
          routerLink={`/g/${goal.slug}`}
          detail={false}
        >
          <IonAvatar slot='start'>
            {isCompleted && (
              <IonIcon
                className='goal_icon_done'
                icon={checkmarkCircleSharp}
                color='success'
              />
            )}
            {goal.icon}
          </IonAvatar>
          <div className='content'>
            <div className='flex between'>
              <IonLabel slot='start'>{goal.name}</IonLabel>
              <IonLabel slot='end' color='medium'>
                <BigCurrency value={isCompleted ? current : remain} />
                {!isCompleted && ' left'}
              </IonLabel>
            </div>
            <ProgressBar value={progress} color={color} />
          </div>
        </IonItem>
      </IonList>
    )
  }
)

export const GoalList = ({ goals }: GoalItemProps) => {
  // Use transactions to calculate progress
  const { summaries } = useGoalTransactions({
    goal_ids: goals.map((g) => g.id),
  })

  if (!goals?.length) {
    return (
      <IonItem lines='none'>
        <IonLabel color='medium'>Nothing here.</IonLabel>
      </IonItem>
    )
  }

  return (
    <>
      {map(goals, (goal, key) => {
        const current = get(summaries, goal.id)?.totals ?? 0
        return <GoalItem key={key} goal={goal} current={current} />
      })}
    </>
  )
}

export const GoalSummary = ({
  goal,
  current,
  color,
}: {
  goal?: TGoal
  current?: number
  color?: string
}) => {
  if (!goal) return null
  const { amount, discretionary, transactions } = goal
  let expAmount = parseFloat(amount) + parseFloat(discretionary ?? 0) * 4
  current = current ?? 0
  if (transactions?.length > 0) {
    current = sum(transactions.map((t) => abs(t.amount)))
  }
  const progress = current / expAmount
  const left = expAmount - current
  return (
    <IonList key={goal.id} lines='none'>
      <IonItem>
        <h1>
          <Currency value={current} round />
        </h1>
      </IonItem>
      <ProgressBar
        value={progress}
        color={color}
        size='large'
        style={{
          margin: '0px 16px',
        }}
      />
      <IonItem>
        <IonLabel slot='start'>
          <strong>
            {left >= 0 ? (
              <>
                <Currency round value={left} /> left
              </>
            ) : (
              <>
                <Currency round value={-left} /> over
              </>
            )}
          </strong>
        </IonLabel>
        <IonLabel slot='end' color='medium'>
          of <Currency round value={expAmount} />
        </IonLabel>
      </IonItem>
    </IonList>
  )
}
