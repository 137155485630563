import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { AmountInput, PercentInput } from '../../components/AmountInput'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { Currency } from '../../components/Numeral'
import { urlParams } from '../../lib/api'
import { homeLoanCalc } from '../../lib/utils'
import { Page } from '../Page'
import './goals.scss'

export default function HomeLoanPage({ history }: RouteComponentProps<any>) {
  const initialValues = urlParams()
  const [values, setState] = useState<any>({
    ...initialValues,
  })
  const setValue = (data: any) => setState({ years: 30, ...values, ...data })

  const payments = useMemo(() => {
    let { amount, down, rate, years, taxes, hoa } = values
    return homeLoanCalc(
      amount,
      rate || 0,
      years * 12,
      down || 0,
      0.7 / 100,
      taxes ?? 1.08,
      hoa ?? 0
    )
  }, [values])

  return (
    <Page title={values.name ?? 'Loan Calculator'}>
      <IonList className='sparse ion-margin-top'>
        <IonItem>
          <IonLabel position='stacked'>Goal</IonLabel>
          <IonInput
            type='text'
            value={values.name}
            placeholder='Goal Name'
            autofocus
            inputMode='text'
            maxlength={300}
            minlength={1}
            size={20}
            onIonChange={(e) => setValue({ name: e.detail.value })}
            style={{ fontSize: 20 }}
          />
        </IonItem>

        <AmountInput
          label='Home price'
          slot='end'
          rightAlign
          required
          value={values.amount}
          placeholder='$350,000'
          onChange={(amount) => setValue({ amount })}
          style={{ fontSize: 20 }}
        />

        <AmountInput
          label='Down payment'
          slot='end'
          rightAlign
          required
          value={values.down}
          placeholder='$0'
          min='0'
          max='100'
          onChange={(down) => setValue({ down })}
          style={{ fontSize: 20 }}
        />

        <AmountInput
          label='Years'
          slot='end'
          rightAlign
          required
          value={values.years}
          format='0'
          placeholder='30'
          min='1'
          max='100'
          step='1'
          inputMode='numeric'
          onChange={(years) => {
            setValue({ years })
          }}
          style={{ fontSize: 20 }}
        />

        <PercentInput
          label='Interest Rate (APR)'
          format='0[.]00%'
          slot='end'
          rightAlign
          required
          value={values.rate}
          placeholder='%'
          min='0'
          max='100'
          onChange={(rate) => {
            setValue({ rate })
          }}
          style={{ fontSize: 20 }}
        />

        <PercentInput
          label='Property tax rate'
          slot='end'
          rightAlign
          required
          value={values.taxes}
          placeholder='1.08%'
          min='0'
          max='100'
          onChange={(taxes) => {
            setValue({ taxes })
          }}
          style={{ fontSize: 20 }}
        />

        <AmountInput
          lines='none'
          label='HOA'
          slot='end'
          rightAlign
          required
          value={values.hoa}
          placeholder='$100'
          min='0'
          max='1000000'
          onChange={(hoa) => {
            setValue({ hoa })
          }}
          style={{ fontSize: 20 }}
        />
      </IonList>

      {payments && (
        <IonList lines='none'>
          <IonItem>
            <LabelWithInfo
              label='Total cost'
              header='Total cost'
              message='The total amount paid over the life of the loan including price, down payment, interest, sales tax and fees.'
            />
            <IonLabel slot='end'>
              <Currency value={payments.totalCost} />
            </IonLabel>
          </IonItem>

          <IonItem>
            <LabelWithInfo
              label='Interest paid'
              header='Interest paid'
              message='The total amount of interest paid over the life of the loan.'
            />
            <IonLabel slot='end'>
              <Currency value={payments.totalInterest} />
            </IonLabel>
          </IonItem>

          <IonItem>
            <LabelWithInfo
              label='Monthly'
              header='Monthly'
              message='The amount you will pay every month (not including insurance and other related costs).'
            />
            <IonLabel slot='end'>
              <strong>
                <Currency value={payments.monthly} />
              </strong>
            </IonLabel>
          </IonItem>
        </IonList>
      )}
    </Page>
  )
}
