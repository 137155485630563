import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { ComponentProps, ReactNode } from 'react'
import './card.scss'

export function Card({
  title,
  children,
  ...rest
}: {
  title?: ReactNode
  children: ReactNode
} & Omit<ComponentProps<typeof IonCard>, 'title'>) {
  return (
    <IonCard {...rest}>
      {title && (
        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>
        </IonCardHeader>
      )}
      <IonCardContent>{children}</IonCardContent>
    </IonCard>
  )
}
