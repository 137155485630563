import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { add, endOfDay, formatDistance, startOfMonth, sub } from 'date-fns'
import format from 'date-fns/format'
import {
  arrowDownCircle,
  arrowUpCircle,
  chevronBack,
  chevronBackOutline,
} from 'ionicons/icons'
import { get, isEmpty, map, orderBy, times } from 'lodash'
import { abs, sum } from 'mathjs'
import numeral from 'numeral'
import { memo, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSwipeable } from 'react-swipeable'
import { Card } from '../../components/Card'
import IconButton from '../../components/IconButton'
import { Currency, fmtNum, percent } from '../../components/Numeral'
import {
  getCategoryIcon,
  getMerchantLogo,
} from '../../components/transactions/constants'
import { TransactionListSkeleton } from '../../components/transactions/TransactionsList'
import { TransactionsListModal } from '../../components/transactions/TransactionsModal'
import { useBankAccounts } from '../../hooks/loaders/useAccounts'
import { useHistorical } from '../../hooks/loaders/useHistorical'
import { Day } from '../../lib/dates'
import {
  notifyComingSoon,
  notifyIncome,
  notifySubscriptions,
} from '../../lib/notifications'
import {
  groupByMerchant,
  pullBalanceFwd,
  selectExpenses,
  selectIncome,
  selectTodays,
  groupByCategory,
  sumAmounts,
  toLocaleDate,
  _amount,
} from '../../lib/transactions'
import { TSummary, TTransaction } from '../../lib/types'
import { isMobile } from '../../settings'
import { Page } from '../Page'
import PieChart from '../transactions/PieChart'
import BalancesChart from './BalancesChart'
import './cashflow.scss'

const SPACE = '0.6rem'

interface CashflowPageProps extends RouteComponentProps<{}> {
  presentingElement?: any
}

export function CashflowPage({ presentingElement }: CashflowPageProps) {
  const {
    isLoading,
    summaries: { monthly },
    futures,
  } = useHistorical()

  useEffect(() => {
    // Show notifications after loading
    if (monthly?.length > 0) {
      const [prevMonth, thisMonth] = monthly.slice(-2)
      if (prevMonth && thisMonth) {
        notifySubscriptions(thisMonth, prevMonth)
      }
    }
  }, [monthly])

  // Category group and totals
  const [transactions, viewTransactions] = useState<{
    data: TTransaction[]
    title?: string
  }>({ data: [] })

  const [chartIndex, setChartIndex] = useState(-1)

  const todaysDate = Day()

  const getDate = () => {
    let [current] = monthly.slice(chartIndex)
    if (!current) return todaysDate
    return new Date(current.date)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (chartIndex < -1) setChartIndex(chartIndex + 1)
    },
    onSwipedRight: () => {
      if (chartIndex > -12) setChartIndex(chartIndex - 1)
    },
  })

  return (
    <Page
      id='dashboard'
      isLoading={isLoading}
      loadingMessage='Crunching numbers'
      title={format(getDate(), 'MMMM, yyyy')}
      start={
        <IonButtons slot='start'>
          <IconButton
            icon={chevronBack}
            routerLink='/'
            routerDirection='back'
          />
        </IonButtons>
      }
    >
      <div {...handlers}>
        <CashflowChart
          monthly={monthly}
          chartIndex={chartIndex}
          onClickBar={({ index, data }) => {
            setChartIndex(index)
            viewTransactions({ data, title: 'Spending' })
          }}
        />
      </div>
      <SpendingCards
        monthly={monthly}
        futures={futures}
        date={getDate()}
        detail={transactions}
        chartIndex={chartIndex}
        onDismiss={() => {
          viewTransactions({ data: [] })
          // setChartIndex(-1)
        }}
        viewTransactions={viewTransactions}
        presentingElement={presentingElement}
      />
    </Page>
  )
}

function DirectionIcon({ percentChange }: { percentChange: number }) {
  let sign = percentChange < 0 ? '▼' : '▲'
  return (
    <>
      {sign} {percent(percentChange)}
    </>
  )
}

function CashflowChart({
  monthly,
  chartIndex,
  title,
  onClickBar,
}: {
  monthly: TSummary[]
  chartIndex: number
  title?: string
  onClickBar: ({ data, index }: { data: TTransaction[]; index: number }) => void
}) {
  const take = isMobile() ? 6 : 12
  const total = monthly.length
  const sliceStart = Math.min(total - take, total + chartIndex)
  const sliceEnd = sliceStart + take
  // TODO: pass all data into the chart to apply the correct scaling
  const months = monthly.slice(sliceStart, sliceEnd)
  let data = useMemo(() => {
    const now = Day()
    if (!months.length) {
      return times(take, (i) => {
        let income = 2000 + Math.random() * 1000
        let expenses = 3000 - Math.random() * 1000
        let avg = 3000 + Math.random() * 100 - expenses
        return {
          date: sub(now, { months: take - 1 - i }),
          income,
          expenses,
          overspent: 0,
          avg,
        }
      })
    }
    return map(months, (m) => {
      let { income, expenses, balance, avg_expenses } = m.totals
      let isSaving = income > expenses
      return {
        date: new Date(m.date),
        income: isSaving ? balance : 0,
        expenses: isSaving ? expenses - balance : 0,
        overspent: isSaving ? 0 : -balance,
        avg: !isSaving ? 0 : avg_expenses - expenses,
      }
    })
  }, [months])

  const currentMonth = get(monthly, monthly.length + chartIndex)
  const prevMonth = get(monthly, monthly.length + chartIndex - 1)

  return (
    <>
      <IonList lines='none'>
        <IonListHeader>Total Spending</IonListHeader>
        <IonItem>
          <h1 className='big-num'>
            <Currency value={currentMonth?.totals.expenses} />
          </h1>
        </IonItem>
      </IonList>
      <div className='balances-chart'>
        <div>
          <ParentSize>
            {({ width, height }) => (
              <Card className='card-chart'>
                <BalancesChart
                  data={data}
                  height={230}
                  width={width}
                  title={numeral(currentMonth?.totals.balance).format('$0,0')}
                  avgExpenses={currentMonth?.totals.avg_expenses}
                  onClickBar={(d) => {
                    onClickBar({ index: d - take, data: months[d].expenses })
                  }}
                />
              </Card>
            )}
          </ParentSize>
        </div>
      </div>
    </>
  )
}

function SpendingCards({
  date,
  detail,
  chartIndex,
  monthly,
  futures,
  onDismiss,
  viewTransactions,
  presentingElement,
}: {
  detail: { data: TTransaction[]; title?: string }
  date: Date
  chartIndex: number
  monthly: TSummary[]
  futures: TTransaction[]
  onDismiss: VoidFunction
  viewTransactions: (data: { data: TTransaction[]; title?: string }) => void
  presentingElement?: any
}) {
  const { accountTypes } = useBankAccounts()
  const thisMonth = get(monthly, monthly.length + chartIndex)
  const prevMonth = get(monthly, monthly.length + chartIndex - 1)

  const todaysDate = Day()
  const todaysEndDate = endOfDay(todaysDate)
  const threeDaysFromNow = add(todaysEndDate, { days: 3 })
  const nextMonthDate = add(todaysDate, { months: 1 })
  const nextMonthStartDate = startOfMonth(nextMonthDate)

  const periodEndDate = useMemo(() => {
    const { breakEvenDate } = pullBalanceFwd(
      futures,
      accountTypes['checking']?.amount ?? 0
    )
    return new Date(breakEvenDate ?? nextMonthStartDate)
  }, [futures, accountTypes, nextMonthStartDate])

  const projected = useMemo(() => {
    let income = selectIncome(futures)
    let expenses = selectExpenses(futures)
    let comingSoonTx = expenses.filter(
      (t) => toLocaleDate(t.date) <= periodEndDate
    )
    let payables = sumAmounts(comingSoonTx)
    return { income, expenses, comingSoonTx, payables }
  }, [futures, periodEndDate])

  useEffect(() => {
    // Show notifications
    if (thisMonth?.income.length) {
      let recentIncome = selectTodays(thisMonth.income)
      notifyIncome(recentIncome)
    }
    const billsDueSoon = projected.expenses.filter((x) => {
      let date = toLocaleDate(x.date)
      return todaysEndDate < date && date <= threeDaysFromNow
    })
    if (billsDueSoon.length > 0) {
      notifyComingSoon(billsDueSoon)
    }
  }, [thisMonth, projected, threeDaysFromNow, todaysEndDate])

  const [showAlert] = useIonAlert()

  let netCash = thisMonth?.totals.balance

  let { data } = detail
  return (
    <>
      <IonModal
        isOpen={data.length > 0}
        onDidDismiss={onDismiss}
        canDismiss
        // presentingElement={presentingElement}
      >
        <ViewTransactions {...detail} date={date} onDismiss={onDismiss} />
      </IonModal>

      <div className='grid-2 spending-cards grid-md-3 grid-lg-4'>
        <Card
          className='card_sm'
          onClick={() => {
            viewTransactions({ data: thisMonth?.income, title: 'Income' })
          }}
        >
          <h2>Earned</h2>
          <IonText color='success'>
            <h1>
              <Currency value={thisMonth?.totals.income} round />
            </h1>
          </IonText>
          <h2>
            <ChangePct
              current={thisMonth?.totals.income}
              prev={prevMonth?.totals.income}
            />
          </h2>
        </Card>

        <Card
          className='card_sm'
          onClick={() =>
            showAlert({
              header: 'Balance',
              message: `Your balance is the difference between your Income and your Spending.`,
              buttons: ['Okay'],
            })
          }
        >
          <h2>Balance</h2>
          <IonLabel color={netCash < 0 ? 'danger' : 'success'}>
            <h1>
              <Currency round value={netCash} />
            </h1>
          </IonLabel>
          <h2>
            <ChangePct current={netCash} prev={prevMonth?.totals.balance} />
          </h2>
        </Card>

        {chartIndex === -1 ? (
          <Card
            className='card_sm'
            onClick={() => {
              viewTransactions({
                data: projected.comingSoonTx,
                title: `Upcoming`,
              })
            }}
          >
            <h2>Upcoming</h2>
            <h1>
              <Currency value={projected.payables} round />
            </h1>
            <h2>
              {projected.comingSoonTx.length > 0 &&
                formatDistance(
                  toLocaleDate(projected.comingSoonTx[0]?.date),
                  Day(),
                  {
                    addSuffix: true,
                  }
                )}
            </h2>
          </Card>
        ) : (
          <Card
            className='card_sm'
            onClick={() => {
              viewTransactions({
                data: thisMonth?.expenses,
                title: `Spending`,
              })
            }}
          >
            <h2>Spent</h2>
            <h1>
              <Currency value={thisMonth?.totals.expenses} round />
            </h1>
            <h2>
              <ChangePct
                current={thisMonth?.totals.expenses}
                prev={prevMonth?.totals.expenses}
              />
            </h2>
          </Card>
        )}

        <Card
          className='card_sm'
          onClick={() => {
            viewTransactions({ data: thisMonth?.expenses, title: 'Spending' })
          }}
        >
          <h2>Spending avg</h2>
          <h1>
            <Currency value={thisMonth?.totals.avg_expenses} round />
          </h1>
          <h2>
            <ChangePct
              current={thisMonth?.totals.avg_expenses}
              prev={prevMonth?.totals.avg_expenses}
            />
          </h2>
        </Card>
      </div>

      <div className='ion-padding-vertical'>
        <TransactionGroup
          groups={map(thisMonth?.categories, (list, key) => {
            const value = sumAmounts(list)
            return {
              title: key,
              subtitle: `${list.length} items`,
              list: orderBy(list, 'date', 'desc'),
              value,
            }
          })}
          header='Categories'
          sort='value'
          desc
        />
      </div>
    </>
  )
}

const ChangePct = memo(
  ({
    current,
    prev,
    color = 'medium',
    style,
    asText = false,
  }: {
    current: number
    prev: number
    color?: string
    style?: any
    asText?: boolean
  }) => {
    const pct = (current - prev) / prev
    const isDown = current < prev
    let icon = (
      <IonIcon icon={isDown ? arrowDownCircle : arrowUpCircle} size='small' />
    ) as any
    if (asText) icon = <span className='text-small'>{isDown ? '▼' : '▲'}</span>
    return (
      <span
        className='flex align-center ion-color-medium'
        style={{
          lineHeight: 1,
          ...style,
        }}
      >
        {icon}{' '}
        <span style={{ marginLeft: 5 }}>{fmtNum(Math.abs(pct), '0%')}</span>
      </span>
    )
  }
)

function ViewTransactions({
  data,
  onDismiss,
  date,
  title,
}: {
  title?: string
  data: TTransaction[]
  date: Date
  onDismiss: VoidFunction
}) {
  return (
    <>
      <IonToolbar>
        <IonButtons slot='start'>
          <IconButton icon={chevronBackOutline} onClick={onDismiss} />
        </IonButtons>
        <IonTitle>{format(date, 'MMMM, yyyy')}</IonTitle>
        {/* <IonButtons slot="end">
          <IconButton icon={closeOutline} onClick={onDismiss} />
        </IonButtons> */}
      </IonToolbar>
      <IonContent>
        <SpendingCategories
          current={sumAmounts(data)}
          transactions={data}
          title={title}
        />
      </IonContent>
    </>
  )
}

export const getCategories = (
  fn = groupByCategory,
  sub = (value: number, key: string, list: TTransaction[]) =>
    `${list.length} items`,
  arr: TTransaction[] = []
) => {
  // group by some f(x)
  const groups = fn(arr)
  // calculate group totals and message
  return map(groups, (list, key) => {
    const value = abs(sum(_amount(list)))
    return {
      type: 'categories',
      value,
      title: key,
      subtitle: sub(value, key, list),
      list: orderBy(list, 'date', 'desc'),
    }
  })
}

export function SpendingCategories({
  current,
  transactions,
  title,
}: {
  title?: string
  current: number
  transactions: TTransaction[]
}) {
  const [category, setCategory] = useState<string | undefined>()
  const [activeTab, setTab] = useState('categories')

  const groupPercents = (value: number) =>
    `${percent(abs(value / current))} of total`

  const chartData = useMemo(() => {
    return getCategories(
      activeTab === 'categories' ? groupByCategory : groupByMerchant,
      groupPercents,
      transactions
    )
  }, [activeTab, transactions])

  return (
    <>
      <Card className='card_sm no-padding' color='tertiary'>
        <div>
          <ParentSize>
            {({ width, height }) => (
              <PieChart
                title={title}
                data={chartData}
                defaultValue={category}
                onChartClick={(d) => setCategory(d?.title)}
                height={300}
                width={width}
              />
            )}
          </ParentSize>
        </div>
      </Card>

      {/* Switch categories and merchants */}
      <IonToolbar className='toolbar-segment'>
        <IonSegment
          onIonChange={(e) => setTab(e.detail.value!)}
          value={activeTab}
        >
          <IonSegmentButton value='categories'>
            <IonLabel>Categories</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='merchants'>
            <IonLabel>Merchants</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      {/* TODO: onClicks */}
      <TransactionGroup groups={chartData} sort='value' desc />
    </>
  )
}

type TTransactionGrouping = {
  value: number
  title: string
  subtitle?: string
  list?: TTransaction[]
}

const GroupLogo = memo(
  ({ list, title }: { list?: TTransaction[]; title: string }) => {
    if (list?.length) {
      return <>{getMerchantLogo(list[0]) ?? getCategoryIcon(list[0])}</>
    }
    return <>{title[0]}</>
  }
)

export const TransactionGroup = ({
  groups,
  header,
  sort,
  desc,
  onClick,
  isLoading,
}: {
  groups: TTransactionGrouping[]
  header?: string
  lines?: 'none' | 'inset' | 'full'
  onClick?: any
  sort?: string
  desc?: boolean
  isLoading?: boolean
}) => {
  const list = useMemo(() => {
    return orderBy(groups, sort, desc ? 'desc' : 'asc')
  }, [groups, sort, desc])

  // Show transaction list in modal
  const [showMore, setShowMore] = useState<TTransactionGrouping | null>(null)
  const modal = useMemo(() => {
    return (
      <TransactionsListModal
        isOpen={showMore != null}
        transactions={showMore?.list!}
        label={showMore?.title}
        onDismiss={() => setShowMore(null)}
      />
    )
  }, [showMore])

  if (isLoading) return <TransactionListSkeleton />

  if (isEmpty(list)) return null

  return (
    <>
      {modal}
      <IonList className='ion-margin-bottom'>
        {header && (
          <IonListHeader>
            <IonLabel>{header}</IonLabel>
          </IonListHeader>
        )}
        {map(list, (g) => {
          return (
            <IonItem
              className='transaction-item'
              onClick={(e) => {
                setShowMore(g)
                if (onClick) onClick(e)
              }}
              detail={false}
              lines='none'
            >
              <IonAvatar
                className='transaction_avatar'
                slot='start'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'gainsboro',
                  color: 'white',
                  fontWeight: 900,
                }}
              >
                <GroupLogo title={g.title} list={g.list} />
              </IonAvatar>
              <IonLabel>
                <h2>{g.title}</h2>
                {g.subtitle && <p>{g.subtitle}</p>}
              </IonLabel>
              <IonLabel slot='end'>
                <Currency value={g.value} />
              </IonLabel>
            </IonItem>
          )
        })}
      </IonList>
    </>
  )
}
