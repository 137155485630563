import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { checkmarkCircle, radioButtonOff, radioButtonOn } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { startCheckout } from '../lib/api'
import { store } from '../lib/store'
import './paywall.scss'

// live prices
const proPlan = 'price_1LC413BaNDdVqrFh1PT1Pt9e'
const litePlan = 'price_1LB8lUBaNDdVqrFhVQo022vN'

export function Paywall({ isOpen }: { isOpen?: boolean }) {
  const user = store.useState((s) => s.user)
  const [show, setShow] = useState(!!isOpen)

  const [selected, setSelected] = useState(proPlan)

  useEffect(() => {
    if (window.location.hash === '#paywall') {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [window.location.hash])

  const icon = (val: string) =>
    selected === val ? radioButtonOn : radioButtonOff

  return (
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
      <IonContent className='paywall-content'>
        <IonToolbar>
          <IonTitle>Upgrade to PRO</IonTitle>
          <IonButtons slot='start'>
            {/* <IconButton
              color='primary'
              icon={chevronBackOutline}
              onClick={() => setShow(false)}
            /> */}
          </IonButtons>
        </IonToolbar>

        <IonList className='ion-margin-top' lines='none'>
          <IonItem>
            <p>Subscribe today and get the full experience!</p>
          </IonItem>
        </IonList>

        <IonList className='features-list' lines='none'>
          {/* <IonItem>
            <IonIcon slot="start" icon={checkmarkCircle} color="primary" />
            <IonLabel>View all of your accounts in one place.</IonLabel>
          </IonItem> */}
          <IonItem>
            <IonIcon slot='start' icon={checkmarkCircle} color='primary' />
            <IonLabel className='ion-text-wrap'>
              Connect all of your bank accounts
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon slot='start' icon={checkmarkCircle} color='primary' />
            <IonLabel className='ion-text-wrap'>
              Understand your spending habits
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon slot='start' icon={checkmarkCircle} color='primary' />
            <IonLabel className='ion-text-wrap'>
              Create unlimited savings goals
            </IonLabel>
          </IonItem>

          <IonItem>
            <IonIcon
              slot='start'
              icon={checkmarkCircle}
              color={selected === proPlan ? 'primary' : 'medium'}
            />
            <IonLabel className='ion-text-wrap'>Automate your savings</IonLabel>
          </IonItem>
        </IonList>

        <IonList className='line-items' lines='none'>
          <IonItem
            onClick={() => setSelected(proPlan)}
            data-selected={selected === proPlan}
          >
            <IonIcon
              slot='start'
              icon={icon(proPlan)}
              color={selected === proPlan ? 'primary' : 'default'}
            />
            <IonLabel>
              <div>
                <b>$9/month</b>
              </div>
              <p>PRO plan</p>
            </IonLabel>
          </IonItem>

          <IonItem
            onClick={() => setSelected(litePlan)}
            data-selected={selected === litePlan}
          >
            <IonIcon
              slot='start'
              icon={icon(litePlan)}
              color={selected === litePlan ? 'primary' : 'default'}
            />
            <IonLabel>
              <div>
                <b>$5/month</b>
              </div>
              <p>Lite plan</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter className='cta-content'>
        <IonButton
          className='btn-buy'
          expand='block'
          size='large'
          onClick={async () => {
            let data = await startCheckout(selected, user!.email!)
            window.location.assign(data.location)
          }}
        >
          Subscribe
        </IonButton>
        <p>Powered by Stripe. Cancel anytime.</p>
      </IonFooter>
    </IonModal>
  )
}
