import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { chevronBackOutline, shareSocial } from 'ionicons/icons'
import { useState } from 'react'
import IconButton from '../../components/IconButton'
import { QRCodeImg } from '../../components/QRCode'
import { store } from '../../lib/store'
import { TAccount } from '../../lib/types'
import { copyToClipboard, shareDialog } from '../../lib/utils'
import { URL_HOST } from '../../settings'

export function ShareAccountButton({ account }: { account: TAccount }) {
  const rootElement = store.useState((s) => s.presentingElement)
  const [showToast] = useIonToast()
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)

  if (!account) return null

  const shareAccountUrl = `${URL_HOST}/a/${account.id}`

  const copyLink = async () => {
    await copyToClipboard(shareAccountUrl)
    showToast('Copied to clipboard!', 3000)
  }

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onClose}
        canDismiss
        // presentingElement={rootElement}
      >
        <IonToolbar>
          <IonButtons slot='start'>
            <IconButton
              color='primary'
              icon={chevronBackOutline}
              onClick={onClose}
            />
          </IonButtons>
        </IonToolbar>
        <IonContent fullscreen>
          <IonList lines='none' className='flex column center align-center'>
            <IonItem>
              <h1 className='text-black'>Share Account</h1>
            </IonItem>
            <IonItem>
              <p className='ion-margin text-lg center'>
                Share your account link with friends and family members to give
                them access to balances and transactions in real time.
              </p>
            </IonItem>
          </IonList>

          <IonList lines='none' className='ion-margin-top'>
            <IonItem>
              <div className='w100 flex column align-center'>
                <p>Scan to Share</p>
                <QRCodeImg url={shareAccountUrl} />
              </div>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <div className='flex column center ion-padding'>
            <IonButton
              expand='block'
              onClick={(e) => {
                shareDialog(
                  {
                    title: account.name ?? account.official_name,
                    text: `Join my account today on ClearMoney!`,
                    url: shareAccountUrl,
                  },
                  copyLink
                )
              }}
            >
              Share
            </IonButton>
            <IonButton
              className='ion-margin-bottom'
              fill='clear'
              onClick={onClose}
            >
              Close
            </IonButton>
          </div>
        </IonFooter>
      </IonModal>

      <IonFab className='ion-margin-top' horizontal='end' vertical='top'>
        <IonFabButton size='small' onClick={() => setIsOpen(true)}>
          <IonIcon icon={shareSocial} />
        </IonFabButton>
      </IonFab>
    </>
  )
}
