import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { sub } from 'date-fns'
import { checkmarkCircleSharp, informationCircleSharp } from 'ionicons/icons'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { NumberInput } from '../../components/AmountInput'
import { LabelWithInfo } from '../../components/LabelWithInfo'
import { cash, Currency, percent } from '../../components/Numeral'
import { useGoalMutation } from '../../hooks/mutations/useGoalMutation'
import { useSummaries } from '../../hooks/loaders/useSummaries'
import { urlParams } from '../../lib/api'
import { Day } from '../../lib/dates'
import { Page } from '../Page'
import { max } from 'lodash'

/** Apply the 50/30/20 rule given income and fixed bills */
function estWeeklyAllowance(income: number, bills: number) {
  if (!income) return 0
  if (!bills) return income * 0.07 // 7% per week is ~30% per month
  // check if expenses are more than 50% and adjust
  let ratio = Math.abs(0.5 - bills / income)
  let maxAmount = income * 0.07
  let minAmount = (Math.min(ratio + 0.3, 0.3) * income) / 4
  return Math.min(maxAmount, minAmount)
}

export default function NewBudgetPage({ history }: RouteComponentProps<any>) {
  const initialValues = urlParams()
  const [values, setState] = useState<any>(initialValues)
  const [disabled, setDisabled] = useState(false)
  const setValue = (data: any) => setState({ ...values, ...data })
  const startDate = sub(Day(), { months: 3 })
  const { currentMonth } = useSummaries({ startDate })

  const { mutateGoal } = useGoalMutation()

  const billsEstimate = max([
    (values.start_amount ?? currentMonth?.totals.avg_income ?? 0) / 2,
    currentMonth?.totals.recurring,
  ])

  const discretionaryEstimate =
    ((values.amount ?? currentMonth?.totals.avg_income ?? 0) * 0.3) / 4

  const remaining = useMemo(
    () => values.start_amount - values.amount - (values.discretionary ?? 0) * 4,
    [values]
  )
  const isEnoughSaved = remaining / values.start_amount >= 0.1999

  useEffect(() => {
    // Reset the stored state when going back
    setState(initialValues)
  }, [history])

  const [toast] = useIonToast()

  const handleSubmit = async () => {
    let success
    try {
      setDisabled(true)
      await mutateGoal.mutateAsync(
        {
          ...values,
          display_unit: 'month',
          category: '$budget',
        },
        {
          onError: async () =>
            await toast('Something went wrong, please try again', 5000),
        }
      )
      success = true
    } finally {
      setDisabled(false)
      if (success) history.goBack()
    }
  }

  return (
    <Page
      start={
        <IonButtons slot='start'>
          <IonBackButton />
        </IonButtons>
      }
      footer={
        <IonFooter>
          <IonToolbar>
            <IonButton
              disabled={disabled || !values.amount}
              expand='block'
              onClick={handleSubmit}
            >
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      }
    >
      <IonList className='ion-margin-top sparse'>
        <IonItem>
          <IonLabel position='floating'>Name</IonLabel>
          <IonInput
            required
            type='text'
            value={values.name}
            placeholder='Name'
            inputMode='text'
            maxlength={180}
            minlength={1}
            onIonChange={(e) => {
              setValue({ name: e.detail.value })
            }}
            style={{ textAlign: 'left', fontSize: 20 }}
          />
        </IonItem>

        <IonItem>
          <LabelWithInfo
            label='Monthly income'
            header='Monthly income'
            message={`We estimated your regular monthly income to be ${cash(
              currentMonth?.totals.avg_income
            )} per month.`}
          />
          <NumberInput
            slot='end'
            value={values.start_amount}
            placeholder={cash(currentMonth?.totals.avg_income)}
            onChange={(start_amount) => setValue({ start_amount })}
            rightAlign
          />
        </IonItem>

        <IonItem>
          <LabelWithInfo
            label='Monthly bills'
            header='Monthly bills'
            message={`We estimated your recurring monthly bills to be ${cash(
              currentMonth?.totals.recurring
            )} per month based on the past 6 months.`}
          />
          <NumberInput
            slot='end'
            value={values.amount}
            placeholder={cash(billsEstimate)}
            onChange={(amount) => setValue({ amount })}
            rightAlign
          />
        </IonItem>

        <IonItem>
          <LabelWithInfo
            label='Weekly allowance'
            header='Weekly allowance'
            message={`This is your flexible spending like eating out, shopping, or paying for other goods & services. Consider spending less than 7% of your income (per week)`}
          />
          <NumberInput
            slot='end'
            value={values.discretionary}
            placeholder={cash(discretionaryEstimate)}
            onChange={(discretionary) => setValue({ discretionary })}
            rightAlign
          />
        </IonItem>

        <IonItem lines='none'>
          <IonLabel>Est savings</IonLabel>
          <IonLabel slot='end'>
            {values.start_amount && values.amount && (
              <Currency round value={remaining} />
            )}
          </IonLabel>
        </IonItem>

        {isEnoughSaved && (
          <IonItem lines='none'>
            <IonIcon slot='start' color='success' icon={checkmarkCircleSharp} />
            <IonLabel color='medium' className='ion-text-wrap'>
              <p>
                Great! You're saving {percent(remaining / values.start_amount)}{' '}
                of your income.
              </p>
            </IonLabel>
          </IonItem>
        )}

        <IonItem lines='none'>
          <IonIcon slot='start' icon={informationCircleSharp} color='medium' />
          <IonLabel color='medium' className='ion-text-wrap'>
            <p>
              Aim to save at least 20% or more of your income for savings every
              month.
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    </Page>
  )
}
