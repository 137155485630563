import { IonIcon, useIonAlert } from '@ionic/react'
import {
  helpOutline,
  informationCircle,
  helpCircleOutline,
} from 'ionicons/icons'
import { memo, ReactNode } from 'react'

export const LabelWithInfo = memo(
  ({
    label,
    icon = helpCircleOutline,
    iconColor = 'medium',
    header,
    message,
  }: {
    label: ReactNode
    icon?: string | null
    iconColor?: string
    message?: string
    header?: string
  }) => {
    const [showAlert] = useIonAlert()
    let detail = { header, message, buttons: ['Okay'] }
    return (
      <div className='flex align-center'>
        <span
          onClick={(e) => {
            e.preventDefault()
            if (message) showAlert(detail)
          }}
        >
          {label}
        </span>
        {icon != null && (
          <IonIcon
            style={{
              marginLeft: 5,
            }}
            color={iconColor}
            icon={icon}
            size='small'
            onClick={(e) => {
              e.preventDefault()
              if (message) showAlert(detail)
            }}
          />
        )}
      </div>
    )
  }
)
